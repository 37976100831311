(function() {
    'use strict';

    angular
    .module('app')
    .controller('LeaseApplicationListController', leaseApplicationListController);

    function leaseApplicationListController($scope,$http,$state,$rootScope,$timeout, helperService, propertyService, langService) {
        $scope.t = langService.t('lease_app');

		angular.element($('body')).scope().state = $state;
        angular.element($('body')).scope().myscope = $scope;


        $scope.isCordova = window.Capacitor.isNativePlatform() ? true : false;
        $scope.rent_amount_editable = 1;
        $scope.rent_amount_editable_type = 'both';
        $scope.prospect_multi_initialized = 0;
        $scope.original_rent_amount = 0;

        $scope.prospects = [];
        $scope.ref = {
            prospect:{},
            agent:{},
            rentable_items:[]
        };

        $scope.agents = [];
        $scope.rentable_items = [];

        $scope.invite = {
            prospect_id: 0,
            type: 'email',
            email: '',
            registration_code: '',
            first_name: '',
            last_name: '',
            property: 0,
            property_id: 0,
            unit: '',
            rent_amount: '',
            mark_paid: 0,
            move_in_date: moment().format('MM/DD/YYYY'),
            start_date: moment().add(1, 'month').startOf('month').format('MM/DD/YYYY'),
            end_date: moment().add(1, 'year').endOf('month').format('MM/DD/YYYY'),
            agent_first_name: '',
            agent_last_name: '',
            rentable_items:[],
            no_email: 0,
            show_override: 0
        };
        $scope.propertiesFilterString = {value: ''};

        if (moment().format('DD') == '01') {
            $scope.invite.start_date = moment().format('MM/DD/YYYY');
            $scope.invite.end_date = moment().add(1, 'year').subtract(1, 'day').format('MM/DD/YYYY');
        }

        $scope.properties = [];
        $scope.property_units = [];
        $scope.modalSelectedProperty = null;

        $scope.getPropertyUnits = function() {
            $scope.getPropertyLeaseVars();
            $scope.showUnitList = false;
            $scope.getAgents();
            $scope.getRentableItems();
            $scope.getAvailableUnitList();

            $scope.modalSelectedProperty = $scope.properties.filter(property => property.id === $scope.invite.property_id)[0];

            $http.post('/api/v1/properties/get-property-units-for-manager-payments/' + $scope.invite.property_id).success(function(data){
                if(!data.success){
                    return;
                }

                $scope.property_units = [];
                angular.forEach(data.data, function(unit, key){
                    if (!$scope.property_units.includes(unit.unit)) {
                        $scope.property_units.push(unit.unit);
                    }
                });
            }).error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        //for filter 
        $scope.managerMobile = {
            filter: {
                text: '',
                timeline: {
                    today: false,
                    '1dayago': false,
                    '1weekago': false,
                    '2weeksago': false,
                    '1monthago': false,
                    '2monthago': false,
                    '4monthago': false,
                    '6monthago': false
                },
                status: {
                    open: false,
                    review: false,
                    approved: false,
                    rejected: false,
                    accepted: false,
                    signed: false,
                    complete: false,
                    converted: false,
                },
            },
            loading: {
                tickets: false,
                properties: false
            }
        };
        // end for filter 

        $scope.getProperties = function(){
            $scope.managerMobile.loading.properties = true;
            $http.post('/api/v1/lease-application/get-properties-for-invite').success(function(data) {
                if (!data.success) {
                    toastr.error('Error getting properties for invite');
                    return;
                }

                $scope.properties = data.data.properties;
                $scope.propertiesFiltered = $scope.properties; //this is for mobile filter  
                $scope.managerMobile.loading.properties = false;
                $scope.getAvailableUnitList();
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };
        $scope.getProperties();

        $scope.availablePropertyUnits = [];



        $scope.setInviteType = function(type) {
            $scope.invite.type = type;
        };

        $scope.availablePropertyUnits = [];
        $scope.selectedPropertyExtentionType = '';
        $scope.selectedPropertyLeaseExtentionEnabled = 0;
        $scope.hide_no_email = 1;
        $scope.getAvailableUnitList = function () {
            if (!$scope.properties) {
                return;
            }
            let chosenProperty = $scope.properties.filter(i => i.id === $scope.invite.property_id)

            chosenProperty = chosenProperty ? chosenProperty[0] : null;
            if(chosenProperty != null){
                $scope.selectedPropertyExtentionType = chosenProperty.company.extension_type;
                $scope.selectedPropertyLeaseExtentionEnabled = chosenProperty.lease_applications_extension_enabled;
                $scope.hide_no_email = chosenProperty.hide_lease_no_email;
            }

            $http.get(`/api/v1/prospect/get-unit-rent-amount-list/${$scope.invite.property_id}?start_date=${$scope.invite.move_in_date}`).success(function (data) {
                if (!data.success) {
                    return;
                }
                $scope.availablePropertyUnits = data.data.available_units;
            });
        }

        $scope.getProspects = function(){
            $http.post('/api/v1/prospect/get-list').success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.prospects = data.data.prospects;
                $scope.prospects.unshift({
                    is_placeholder:  true,
                    id:              0,
                    first_name:      'New Prospect',
                    last_name:       '',
                    dropdown_string: $scope.t('New Prospect'),
                });
                $scope.ref.prospect = $scope.prospects[0];

                $timeout(function () {
                    $('#prospects-select').multipleSelect({
                        placeholder: 'Prospects',
                        single: true,
                        filter: true,
                        width: 400
                    });
                }, 500);
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        $scope.moveInAtChanged = function(){
            if (moment($scope.invite.move_in_date).format('DD') == '01') {
                $scope.invite.start_date = moment($scope.invite.move_in_date).format('MM/DD/YYYY');
                $scope.invite.end_date = moment($scope.invite.move_in_date).add(1, 'year').subtract(1, 'day').format('MM/DD/YYYY');
            } else {
                $scope.invite.start_date = moment($scope.invite.move_in_date).add(1, 'month').startOf('month').format('MM/DD/YYYY');
                $scope.invite.end_date = moment($scope.invite.move_in_date).add(1, 'year').endOf('month').format('MM/DD/YYYY');
            }
            $scope.getAvailableUnitList();
            $scope.checkForRentAmount
        };

        $scope.checkForRentAmount = function(){
            $http.post('/api/v1/prospect/get-unit-rent-amount/' + $scope.invite.property_id + '/' + $scope.invite.unit, {start_date: $scope.invite.move_in_date}).success(function(data) {
                if (!data.success) {
                    $scope.rent_amount_editable = 1;
                    return;
                }

                if (data.data.available == 0 && data.data.rent_amount != 0) {
                    toastr.error('This unit is not available');
                    return;
                }

                if (data.data.rent_amount == 0) {
                    $scope.rent_amount_editable = 1;
                    return;
                }

                $scope.invite.rent_amount = data.data.rent_amount;
                $scope.original_rent_amount = data.data.rent_amount;
                $scope.rent_amount_editable_type = 'both';
                if ($rootScope.authUser.permissions == 'payquad_admin' || $rootScope.authUser.lease_rent_editable == 1) {
                    $scope.rent_amount_editable = 1;
                } else {
                    $scope.rent_amount_editable = 0;
                }

                if ($scope.rent_amount_editable == 1 && $rootScope.authUser.permissions != 'payquad_admin') {
                    $scope.rent_amount_editable_type = $rootScope.authUser.lease_rent_editable_type;
                }
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        $scope.$watch('invite.unit', function(newValue, oldValue)
        {
            if(+$scope.invite.property_id  !== 0){
                $scope.checkForRentAmount();
            }
        });

        $scope.getAgents = function(){
            $http.post('/api/v1/prospect/get-agents/' + $scope.invite.property_id).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.agents = data.data.agents;

                if ($scope.agents.length > 0) {
                    $scope.agents.unshift({
                        first_name: $scope.t('Select Agent'),
                        last_name: ''
                    });
                }
                $scope.ref.agent = $scope.agents[0];
                const prospect   = $scope.ref.prospect;
                if (prospect.agent_first_name && prospect.agent_last_name) {
                    angular.forEach($scope.agents, function(agent, key){
                        if (prospect.agent_first_name == agent.first_name
                         && prospect.agent_last_name  == agent.last_name
                        ) {
                            $scope.ref.agent = $scope.agents[key];
                        }
                    });
                }
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        $scope.getRentableItems = function(){
            $http.post('/api/v1/prospect/get-rentable-items/' + $scope.invite.property_id).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.rentable_items = data.data.rentable_items;
                $scope.invite.rentable_items = [];
                $scope.ref.rentable_items = [];

                $timeout(function(){
                    $('select#invite-rentable-items').multipleSelect({
                        filter: true,
                        position: 'top',
                    });
                });
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        $scope.populateInvite = function() {
            const prospect = $scope.ref.prospect;
            if (prospect.is_placeholder) {
                return;
            }
            $scope.invite.email       = prospect.email;
            $scope.invite.property_id = prospect.property_id;
            if (prospect.property_units_unit !== null) {
                $scope.invite.unit = parseInt(prospect.property_units_unit);
            }

            if (! prospect.move_in_at || prospect.move_in_at == 'Invalid date') {
                $scope.invite.move_in_date = moment().format('MM/DD/YYYY');
            } else {
                $scope.invite.move_in_date = moment(prospect.move_in_at).format('MM/DD/YYYY');
            }

            if (moment($scope.invite.move_in_date).format('DD') == '01') {
                $scope.invite.start_date = moment($scope.invite.move_in_date).format('MM/DD/YYYY');
                $scope.invite.end_date = moment($scope.invite.move_in_date).add(1, 'year').subtract(1, 'day').format('MM/DD/YYYY');
            } else {
                $scope.invite.start_date = moment($scope.invite.move_in_date).add(1, 'month').startOf('month').format('MM/DD/YYYY');
                $scope.invite.end_date = moment($scope.invite.move_in_date).add(1, 'year').endOf('month').format('MM/DD/YYYY');
            }

            $scope.checkForRentAmount();

            if (prospect.id == 0) {
                $scope.invite.first_name = '';
                $scope.invite.last_name  = '';
            } else {
                $scope.invite.first_name  = prospect.first_name;
                $scope.invite.last_name   = prospect.last_name;
                $scope.invite.prospect_id = prospect.id;
            }

            $scope.getPropertyUnits('invite')
        };

        $scope.openInviteWindow = function(){
            $scope.getProspects();
            $('#invite-move-in-date').datepicker();
            $('#invite-start-date').datepicker();
            $('#invite-end-date').datepicker();

            console.log('openInviteWindow');
            $('#invite-modal').appendTo('body').modal('show');

            $http.get('/api/v1/prospect/get-unit-rent-amount-list/280?start_date=' + $scope.invite.move_in_date).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.availablePropertyUnits = data.data.available_units;

            });

            $http.post('/api/v1/prospect/get-unique-registration-code').success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.invite.registration_code = data.data.registration_code;
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        $scope.sendInvite = function(){
            if ($scope.invite.email && $scope.invite.no_email == 0) {
                // pre check if the email is already attached to an applicant, if so, confirm if they want to do this
                $http.post('/api/v1/prospect/check-invite-email', {email: $scope.invite.email}).success(function(data){
                    if (!data.success) {
                        return;
                    }

                    if (data.data.exists) {
                        if (confirm('This email is already attached to an applicant. Are you sure you want to continue?')) {
                            $scope.doSendInvite();
                        }
                    } else {
                        $scope.doSendInvite();
                    }
                }).error(function(data){
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
            } else {
                $scope.doSendInvite();
            }
        }

        $scope.doSendInvite = function(){
            if ($scope.agents.length > 0) {
                if ($scope.ref.agent.first_name == 'Select Agent' || $scope.ref.agent.first_name == 'Sélectionner agent' ||$scope.ref.agent.first_name == $scope.t('Select Agent')) {
                    toastr.error($scope.t('Please select an agent'));
                    return;
                }

                $scope.invite.agent_first_name = $scope.ref.agent.first_name;
                $scope.invite.agent_last_name = $scope.ref.agent.last_name;
            }
            $scope.invite.rentable_items = $scope.ref.rentable_items;

            if ($scope.rent_amount_editable == 1 && $scope.rent_amount_editable_type == 'up' && parseFloat($scope.original_rent_amount) > parseFloat($scope.invite.rent_amount)) {
                toastr.error('You do not have permission to reduce the rent amount');
                $scope.openInviteWindow();
                return;
            }
            if ($scope.rent_amount_editable == 1 && $scope.rent_amount_editable_type == 'down' && parseFloat($scope.original_rent_amount) < parseFloat($scope.invite.rent_amount)) {
                toastr.error('You do not have permission to increase the rent amount');
                $scope.openInviteWindow();
                return;
            }

            if ($scope.invite.prospect_id != 0) {
                $scope.invite.no_email = 0;
            }

            if ($scope.invite.no_email == 1) {
                $scope.invite.email = $scope.invite.registration_code + '@payquad.com';
            }

            if(!$scope.invite.email){
                toastr.error($scope.t('email_empty'));
                console.log('email empty');
                $scope.openInviteWindow();
                return;
            }

            $rootScope.showLoader = true;
            $http.post('/api/v1/lease-application/send-invite', $scope.invite).success(function(data) {
                $rootScope.showLoader = false;
                if (!data.success) {
                    var message = 'Error converting prospect';
                    if (typeof data.message != 'undefined') {
                        message = data.message;
                    }
                    toastr.error(message);
                    console.log('error converting prospect');
                    // $scope.openInviteWindow();
                    return;
                }

                $scope.invite.email = '';
                $scope.invite.property = 0;
                if ($scope.invite.type === 'email') {
                    toastr.success('Invite Sent');
                    $state.go('main.leaseApplicationList', {}, {reload: true});
                } else {
                    var application_id = data.data.application.id;
                    toastr.success('Application created');
                    $state.go('main.leaseApplicationContinue', {id: application_id});
                }
            }).error(function(data){
                $rootScope.showLoader = false;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.resendInvite = function(application_id){
            if (!confirm('Are you sure you want to resend this Lease Application invite?')) {
                return;
            }

            $http.post('/api/v1/lease-application/resend-invite/' + application_id).success(function(data) {
                if (!data.success) {
                    toastr.success('Unable to resend invite');
                    return;
                }

                toastr.success('Invite Resent');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };




        // filter


        $scope.badge_counts = {
            open: 0,
            in_progress: 0,
            completed: 0,
            scheduled: 0,
            approvals: 0,
            rejected: 0,
            cancelled: 0
        };

        $scope.isMobile = helperService.isMobileOrTablet() || Boolean(window.Capacitor.isNativePlatform());
        $scope.allRequests = [];

            $scope.requests = [];


        angular.element($('body')).scope().state = $state;

        $scope.managerMobileFilterBy = () => {
                const t = $scope.managerMobile.filter.text.toLowerCase();
                const filteredResults = $scope.allRequests.filter(item => {
                    const filterByTextResult = ~item.propertyName.toLowerCase().indexOf(t)
                        || (item.unit && ~item.unit.indexOf(t))
                        || ~item.status.toLowerCase().indexOf(t)
                        || ~item.id.toString().indexOf(t)
                        || ~item.firstName.toLowerCase().indexOf(t)
                        || ~item.lastName.toLowerCase().indexOf(t);

                const filterByDate = ($scope.managerMobile.filter.timeline.today ? moment(item.createdAt).startOf('day').isSame(moment().startOf('day')): false)
                    || ($scope.managerMobile.filter.timeline["1dayago"] ? moment(item.createdAt).isAfter(moment().startOf('day').add(-1, 'days')) : false)
                    || ($scope.managerMobile.filter.timeline["1weekago"] ? moment(item.createdAt).isAfter(moment().startOf('day').add(-1, 'weeks')) : false)
                    || ($scope.managerMobile.filter.timeline["2weeksago"] ? moment(item.createdAt).isAfter(moment().startOf('day').add(-2, 'weeks')) : false)
                    || ($scope.managerMobile.filter.timeline["1monthago"] ? moment(item.createdAt).isAfter(moment().startOf('day').add(-1, 'month')) : false)
                    || ($scope.managerMobile.filter.timeline["2monthago"] ? moment(item.createdAt).isAfter(moment().startOf('day').add(-2, 'month')) : false)
                    || ($scope.managerMobile.filter.timeline["4monthago"] ? moment(item.createdAt).isAfter(moment().startOf('day').add(-4, 'month')) : false)
                    || ($scope.managerMobile.filter.timeline["6monthago"] ? moment(item.createdAt).isAfter(moment().startOf('day').add(-6, 'month')) : false);
                const filterByStatus = ($scope.managerMobile.filter.status.open ? item.status === 'open' : false)
                    || ($scope.managerMobile.filter.status.review ? item.status === 'review' : false)
                    || ($scope.managerMobile.filter.status.approved ? item.status === 'approved' : false)
                    || ($scope.managerMobile.filter.status.rejected ? item.status === 'rejected' : false)
                    || ($scope.managerMobile.filter.status.accepted ? item.status === 'accepted' : false)
                    || ($scope.managerMobile.filter.status.signed ? item.status === 'signed' : false)
                    || ($scope.managerMobile.filter.status.complete ? item.status === 'complete' : false)
                    || ($scope.managerMobile.filter.status.converted ? item.status === 'converted' : false);
                const filterByProperty = $scope.properties.some(e => {
                    return e.selected && e.name.toLowerCase() === item.propertyName.toLowerCase();
                });
                const filterByUnit = $scope.units.some(e => {
                    return e.selected && e.unit == item.unit;
                });

                return (Object.values($scope.managerMobile.filter.status).some(e => e) ? filterByStatus : true )
                    && (Object.values($scope.units).some(e => e.selected) ? filterByUnit : true )
                    && (Object.values($scope.managerMobile.filter.timeline).some(e => e) ? filterByDate : true )
                    && (Object.values($scope.properties).some(e => e.selected) ? filterByProperty : true )
                    && (filterByTextResult !== -1 ? filterByTextResult : true);
            });

                $scope.filteredRequests = filteredResults;
        }

        $scope.showFilterModal = (type) => {
            if (type === 'timeline') {
                $('#mobileManagerFilterTimeline').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'status') {
                $('#mobileManagerFilterStatus').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'unit') {
                $('#mobileManagerFilterUnit').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'properties') {
                // $scope.getProperties();
                $('#mobileManagerFilterProperties').appendTo('body').modal({backdrop: 'static', keyboard: false});
                $scope.filterProperties(null);
            }
            $timeout(() => {
                $('.modal-backdrop').hide();
            }, 0);
        }

        $scope.propertiesFiltered = [];

        $scope.filterProperties = e => {
            const search = $scope.propertiesFilterString.value.toLowerCase();
            $scope.propertiesFiltered = $scope.properties.filter(property => ~property.name.toLowerCase().indexOf(search));
        }

        $scope.units = [];

        $scope.getUnits = propertyIds => {
            propertyService.getUnitsForProperties(propertyIds).then(data => {
                $scope.units = data;
            });
        };

        $scope.propertyChanged = () => {
            let propertyIds = [];
            $scope.properties.map(property => {
                if (property.selected) {
                    propertyIds.push(property.id);
                }
            });

            $scope.getUnits(propertyIds);
        }

        $scope.getManagerRequests = function () {
            $scope.managerMobile.loading.tickets = true;
            $http.get('/api/v1/lease-application/manager-list-mobile').success(function (response) {
                $scope.allRequests = response.data.data
                $scope.filteredRequests = response.data.data

                $scope.viewbyCan = 5;
                $scope.totalItemsCan = $scope.filteredRequests.length;
                $scope.currentPageCan = 1;
                $scope.itemsPerPageCan = $scope.viewbyCan;
                $scope.maxSizeCan = 5; //Number of pager buttons to show

                $scope.setPageCan = function (pageNo) {
                    $scope.currentPageCan = pageNo;
                };

                $scope.setItemsPerPageCan = function (num) {
                    $scope.itemsPerPageCan = num;
                    $scope.currentPageCan = 1; //reset to first page
                };
                $scope.managerMobile.loading.tickets = false;
            });
        };

        $scope.getManagerRequestsForReviewStatus = () => {
            $rootScope.isFromCardBoxReview = false;
            $scope.managerMobile.loading.tickets = true;
            $http.get('/api/v1/lease-application/manager-list-mobile').success(function (response) {
                $scope.allRequests = response.data.data;
                $scope.managerMobile.filter.status.review = true;

                $scope.managerMobileFilterBy();

                $scope.viewbyCan = 5;
                $scope.totalItemsCan = $scope.filteredRequests.length;
                $scope.currentPageCan = 1;
                $scope.itemsPerPageCan = $scope.viewbyCan;
                $scope.maxSizeCan = 5; //Number of pager buttons to show

                $scope.setPageCan = function (pageNo) {
                    $scope.currentPageCan = pageNo;
                };

                $scope.setItemsPerPageCan = function (num) {
                    $scope.itemsPerPageCan = num;
                    $scope.currentPageCan = 1; //reset to first page
                };
                $scope.managerMobile.loading.tickets = false;
            });
        };

        $scope.getManagerRequestsForAcceptedStatus = () => {
            $rootScope.isFromCardBoxAccepted = false;
            $scope.managerMobile.loading.tickets = true;
            $http.get('/api/v1/lease-application/manager-list-mobile').success(function (response) {
                $scope.allRequests = response.data.data;
                $scope.managerMobile.filter.status.accepted = true;
                $scope.managerMobile.filter.status.signed = true;
                $scope.managerMobileFilterBy();

                $scope.viewbyCan = 5;
                $scope.totalItemsCan = $scope.filteredRequests.length;
                $scope.currentPageCan = 1;
                $scope.itemsPerPageCan = $scope.viewbyCan;
                $scope.maxSizeCan = 5; //Number of pager buttons to show

                $scope.setPageCan = function (pageNo) {
                    $scope.currentPageCan = pageNo;
                };

                $scope.setItemsPerPageCan = function (num) {
                    $scope.itemsPerPageCan = num;
                    $scope.currentPageCan = 1; //reset to first page
                };
                $scope.managerMobile.loading.tickets = false;
            });
        };

        if ($rootScope.isFromCardBoxReview) {
            $scope.getManagerRequestsForReviewStatus();
        } else if ($rootScope.isFromCardBoxAccepted) {
            $scope.getManagerRequestsForAcceptedStatus();
        } else {
            $scope.getManagerRequests();
        }

        $scope.getUnitBtnClass = () => {
            return {"active": Object.values($scope.units).some(e => e.selected)};
        }

        $scope.getStatusBtnClass = () => {
            return {"active": Object.values($scope.managerMobile.filter.status).some(e => e)};
        }

        $scope.getTimelineBtnClass = () => {
            return {"active": Object.values($scope.managerMobile.filter.timeline).some(e => e)};
        }

        $scope.getPropertyBtnClass = () => {
            return {"active": Object.values($scope.properties).some(e => e.selected)};
        }

        $scope.showUnitList = false;

        $scope.unitClicked = () => {
            if ($scope.selectedPropertyExtentionType === 'yardi' && $scope.selectedPropertyLeaseExtentionEnabled == 1) {
                $scope.showUnitList = true;
            }
        }

        $scope.switchShowOverride = function() {
            if ($scope.invite.show_override == 1) {
                $scope.invite.show_override = 0;
            } else {
                $scope.invite.show_override = 1;
            }
        }

        $timeout(function(){
            $('select#payment-methods').multipleSelect({filter: true});
            $('#term-time-end-date').datepicker();
        }, 1500);

        $scope.invite.vars = {};
        $scope.getPropertyLeaseVars = function() {
            $http.post('/api/v1/properties/get-lease-vars/' + $scope.invite.property_id).success(function(data){
                if(!data.success){
                    return;
                }

                $scope.invite.vars = data.data.vars;
            }).error(function(data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        }
    }
}());
