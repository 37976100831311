 (function () {
    'use strict';

    angular
        .module('app')
        .controller('LeaseApplicationContinueController', leaseApplicationContinueController);

    function leaseApplicationContinueController($scope, $http, $state, $rootScope, $timeout, $stateParams, brandingService, $parse, propertyService, langService, helperService) {
        $scope.t = langService.t('lease_app');

        $scope.isMobile = helperService.isMobileOrTablet();
        $scope.isNative = false;

        $timeout(function(){
            $scope.isNative = window.Capacitor.isNativePlatform()
        }, 500);
        $scope.rent_amount_editable = 0;
        if ($rootScope.authUser.permissions == 'payquad_admin' || $rootScope.authUser.lease_rent_editable == 1) {
            $scope.rent_amount_editable = 1;
        }

        $scope.popup_error_message = '';

        $scope.coapplicant_update_message = $scope.t('coapplicant_update_message');

        $scope.current_year = moment().format('YYYY');

        $scope.next_step_if_confirmation = null;

        $scope.step = {slug: 'unit'};
        $scope.userType = 'applicant';
        $scope.validated_steps = [];
        $scope.loading_spinner = 1;
        $scope.activeApplicant = 0;
        $scope.formerrors = {};
        $scope.relationships = {
            'Parent': $scope.t('parent'),
            'Sibling': $scope.t('sibling'),
            'Dependent': $scope.t('dependant'),
            'Friend': $scope.t('friend'),
            'Spouse':$scope.t('spouse'),
            'Leaseholder':$scope.t('leaseholder'),
            'Roommate':$scope.t('roommate'),
            'Guarantor':$scope.t('guarantor'),
        };

        $scope.reference_relationships = {
            'Parent': $scope.t('parent'),
            'Sibling': $scope.t('sibling'),
            'Dependent': $scope.t('dependant'),
            'Friend': $scope.t('friend'),
            'Spouse':$scope.t('spouse'),
            'Leaseholder':$scope.t('leaseholder'),
            'Roommate':$scope.t('roommate'),
            'Guarantor':$scope.t('guarantor'),
            'Manager':$scope.t('Manager'),
            'Collegue':$scope.t('Collegue'),
        };


        $scope.yardi_relationships = {
            'Roommate':$scope.t('roommate'),
            'Spouse':$scope.t('spouse'),
            'Guarantor':$scope.t('guarantor'),
            'Other': $scope.t('other')
        };

        $scope.guarantor_relationships = {
            'Parent': $scope.t('parent'),
            'Sibling': $scope.t('sibling'),
            'Dependent': $scope.t('dependant'),
            'Friend': $scope.t('friend'),
            'Spouse':$scope.t('spouse'),
            'Leaseholder':$scope.t('leaseholder'),
            'Roommate':$scope.t('roommate'),
            'Guarantor':$scope.t('guarantor'),
        }

        $scope.pet_types = {
            'Dog':$scope.t('dog'),
            'Cat':$scope.t('cat'),
            'Other':$scope.t('other')
        };
        $scope.parking_options_strings = {
            'No Parking': $scope.t('No Parking'),
            'Outside': $scope.t('Outside'),
            'Underground': $scope.t('Underground'),
            'Covered': $scope.t('Covered'),
            'Garage': $scope.t('Garage'),
        }

        $scope.rentable_item_items = {};
        $scope.parking_options = {};
        $scope.legal_terms = [];
        $scope.provided_documents = [];
        $scope.applicant_required_documents = [];
        $scope.co_applicant_required_documents = [];
        $scope.guarantor_required_documents = [];
        $scope.applicant_optional_documents = [];
        $scope.co_applicant_optional_documents = [];
        $scope.guarantor_optional_documents = [];
        $scope.payment_message = '';
        $scope.recurring_payment_message = '';
        $scope.blankEmployment = {
            employer_name: '',
            employment_position: '',
            employment_months: '',
            employment_supervisor_name: '',
            employer_phone: '',
            employer_email: '',
            employment_salary: '',
            employment_work_address: '',
        };
        $scope.blankLandlord = {
            company_name: '',
            manager_name: '',
            manager_phone: '',
            manager_email: '',
            rent_amount: '',
            duration: ''
        };
        $scope.blankEmergencyContact = {
            first_name: '',
            last_name: '',
            phone: '',
            relationship: ''
        };
        $scope.blankReference = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            relationship: ''
        };

        $scope.application = {
            id: $stateParams.id
        };
        $scope.past_addresses = [];
        $scope.blankAddress = {
            address: '',
            suite: '',
            country: 'CA',
            province: '',
            city: '',
            postal: '',
            dropdown_states: {},
            duration: '',
        };
        $scope.blankApplication = {
            id: $stateParams.id,
            user_id: 0,
            status: 'open',
            applicants_tabs: [],
            property_id: 0,
            transaction_id: 0,
            paid: 0,
            create_schedule_on_conversion: 0,
            agree_to_deposit_payment: 0,
            payment_method_name: '',
            payment_method_id: 0,
            recurring_payment_method_id: 0,
            phone: '',
            email: '',
            work_phone: '',
            mobile_phone: '',
            sin: '',
            applicants: [],
            pets: [],
            kelson_pet_pickup_name: '',
            kelson_pet_pickup_address: '',
            kelson_pet_pickup_phone: '',
            agree_to_pet_policy: null,
            vehicles: [],
            occupants: [],
            guarantors: [],
            references: [],
            rentable_items: [],
            concessions: [],
            rentable_items_set_by_manager: 0,
            born_at: '',
            address: '',
            months_at_address: '',
            suite: '',
            country: 'CA',
            province: '',
            city: '',
            postal: '',
            rent_or_own: '',
            past_addresses: [],
            employment_status: '',
            agree_terms: 0,
            term_agreements: {},
            last_step: '',
            has_pets_deposit: false,
            email_confirmed: 1,
            landlords: [angular.copy($scope.blankLandlord)],
        };
        $scope.property = {};
        $scope.show_property = 0;
        $scope.total_amount = 0;
        $scope.discount_amount = 0;
        $scope.prorate_amount = 0;
        $scope.rent_deposit = 0;
        $scope.states = {};
        $scope.countries = {'CA': 'Canada'};
        $scope.steps = [];
        $scope.show_dropzone = 0;
        $scope.property_units = [];
        $scope.questions = [];

        $scope.buttonDisabled = false;

        $scope.toastVisible = false;

        const convertApplicantToTab = (applicant, type) => {
            const emergencyContacts = [];

            for (let i = 0; i < $scope.property.emergency_contact_required_count; i++) {
                emergencyContacts.push(angular.copy($scope.blankEmergencyContact));
            }

            return {
                id: applicant.id,
                user_id: applicant.user_id,
                user: applicant.user,
                first_name: applicant.first_name,
                last_name: applicant.last_name,
                phone: applicant.user ? applicant.user.personal.phone_number : '',
                email: applicant.email,
                relationship: applicant.relationship,
                work_phone: applicant.user ? applicant.user.personal.work_phone : '',
                mobile_phone: applicant.user ? applicant.user.personal.mobile_phone : '',
                sin: applicant.user ? applicant.user.personal.sin : '',
                drivers_licence: applicant.user ? applicant.user.personal.drivers_licence : '',
                born_at: applicant.user && applicant.user.personal.born_at != null ? moment(applicant.user.personal.born_at).format('MM/DD/YYYY') : null,
                    address: applicant.user ? applicant.user.personal.address : '',
                months_at_address: applicant.user ? applicant.user.personal.months_at_address : '',
                suite: applicant.user ? applicant.user.personal.suite : '',
                country: applicant.user ? applicant.user.personal.country : 'CA',
                province: applicant.user ? applicant.user.personal.province : '',
                city: applicant.user ? applicant.user.personal.city : '',
                postal: applicant.user ? applicant.user.personal.postal : '',
                rent_or_own: applicant.user ? applicant.user.personal.rent_or_own : '',
                past_addresses: applicant.user ? applicant.user.past_addresses : [],
                employment_status: applicant.user ? applicant.user.personal.employment_status : '',
                landlords: applicant.user && applicant.user.landlords.length > 0 ? applicant.user.landlords : [angular.copy($scope.blankLandlord)],
                pets: applicant.user ? applicant.user.pets : [],
                agree_to_pet_policy: applicant.user ? applicant.user.personal.agree_to_pet_policy : '',
                vehicles: applicant.user ? applicant.user.vehicles : [],
                employments: applicant.user ? applicant.user.employments : '',
                emergency_contacts: applicant.user && applicant.user.emergency_contacts.length > 0 ? applicant.user.emergency_contacts : emergencyContacts,
                documents: applicant.user && applicant.user.applicationdocuments ? applicant.user.applicationdocuments : [],
                type,
                qa: applicant.user && $scope.questions.map(q => {
                    let answ = applicant.user.lease_application_question_answers.filter(ans => ans.lease_application_question_id === q.id)
                    let item = {...q};
                    item.answers = answ;
                    return item;
                })
            };
        };

        $scope.setActiveApplicant = index => {
            $scope.activeApplicant = index;
            $scope.getStates();
            $timeout(function () {
                $scope.initUserDropzone();
            }, 500);
        }
        $scope.addEmployment = index => $scope.application.applicants_tabs[index].employments.push(angular.copy($scope.blankEmployment));
        $scope.removeEmployment = (applicantIndex, index) => $scope.application.applicants_tabs[applicantIndex].employments.splice(index, 1);
        $scope.addLandlord = applicant => $scope.application.applicants_tabs[applicant].landlords.push(angular.copy($scope.blankLandlord));
        $scope.removeLandlord = (applicant, index) => $scope.application.applicants_tabs[applicant].landlords.splice(index, 1);
        $scope.employmentChanged = () => {
            const employmentStatus = $scope.application.applicants_tabs[$scope.activeApplicant].employment_status;
            $scope.application.applicants_tabs[$scope.activeApplicant].employments = [];
            if (0 <= ['employed','self_employed','other','social_services','retired'].indexOf(employmentStatus)) {
            //if (employmentStatus === 'employed' || employmentStatus === 'self_employed') {
                $scope.addEmployment($scope.activeApplicant);
            //} else {
                //$scope.application.applicants_tabs[$scope.activeApplicant].employments = [];
            }
        };

        $scope.merchantAccount = {};
        $scope.getProperty = function () {
            var url = '/api/v1/lease-application/get-property/' + $scope.application.property_id + '/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-get-property/' + $scope.application.property_id + '/' + $scope.application.id;
            }
            $http.post(url).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('obtaining_property_info_error'));
                    $state.go('main.leaseApplicationCreateCompanies');
                    return;
                }

                $scope.property = data.data.property;
                $scope.show_property = 1;

                $scope.getFloorplans();
                $scope.getUnitTypes();

                $scope.property.property_parking_options.forEach(option => {
                    if (option.is_enabled) {
                        $scope.parking_options[option.name] = $scope.t(option.name) ?? option.name;
                    }
                });

                if ($rootScope.authUser.permissions == 'applicant' && $rootScope.authUser.id != $scope.application.user_id && $scope.property.lease_hide_terms_for_non_logged_in == 1) {
                    $scope.application.applicants_tabs.forEach((applicant, key) => {
                        if (applicant.user_id == $rootScope.authUser.user_id) {
                            $scope.activeApplicant = key;
                        }
                    });
                }
                $scope.calculateTotalAmount();
                $scope.getApplicationPaymentMethods();
                $scope.getApplicationRecurringPaymentMethods();
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
                $state.go('main.leaseApplicationCreateCompanies');
            });
        };

        $scope.populateTermsAgreement = function () {
            $scope.legal_terms.forEach(term => {
               $scope.application.applicants_tabs.forEach(applicant => {
                    if (
                        term.is_applicant && (
                               applicant.type === 'applicant'
                            || applicant.type === 'co-applicant')
                     || term.is_guarantor && applicant.type === 'guarantor'
                    ) {
                        $scope.application.term_agreements['term_' + term.id+ '_user_' + applicant.user_id] = 0;
                    }
               });
            });

            angular.forEach($scope.term_agreements, function (agreement) {
                $scope.application.term_agreements['term_' + agreement.term_id + '_user_' + agreement.user_id] = 1;
            });
        };

        $scope.roundToTwo = function(num) {
            return Math.round((num + Number.EPSILON) * 100) / 100
        }

        $scope.calculateTotalAmount = function () {
            $scope.total_amount = parseFloat($scope.application.rent_amount);
            $scope.rent_deposit = parseFloat($scope.application.rent_amount);
            $scope.discount_amount = $scope.roundToTwo(parseFloat($scope.application.rent_amount) * .98);
            $scope.parking_deposit = 0;
            $scope.storage_deposit = 0;
            $scope.ac_deposit = 0;

            angular.forEach($scope.application.rentable_items, function (item, key) {
                if (item.checked === true) {

                    $scope.total_amount = $scope.total_amount + parseFloat(item.amount);
                    $scope.discount_amount = $scope.discount_amount + $scope.roundToTwo(parseFloat(item.amount) * .98);

                    if(!$scope.property.lease_application_exclude_rentable_items_from_deposit){
                        if (item.description.match(/parking/i) && $scope.property.vars.post_parking_deposit_separately == "yes"){
                            $scope.parking_deposit += parseFloat(item.amount);
                        }else if((item.description.match(/storage/i) || item.description.match(/locker/i)) && $scope.property.vars.post_storage_deposit_separately == "yes"){
                            $scope.storage_deposit += parseFloat(item.amount);
                        }else if((item.description.match(/air conditioning/i) || item.description.match(/\bAC\b/)) && $scope.property.vars.post_ac_deposit_separately == "yes"){
                            $scope.ac_deposit += parseFloat(item.amount);
                        }

                        $scope.rent_deposit = $scope.total_amount - $scope.parking_deposit - $scope.storage_deposit - $scope.ac_deposit;
                    }
                }
            });

            if ($scope.property.hide_parking_rentable_items == 1) {
                angular.forEach($scope.application.hidden_parking_rentable_items, function (item, key) {
                    $scope.total_amount = $scope.total_amount + parseFloat(item.price);
                    $scope.discount_amount = $scope.discount_amount + $scope.roundToTwo(parseFloat(item.price) * .98);
                    if($scope.property.vars.post_parking_deposit_separately == "yes"){
                        $scope.parking_deposit += parseFloat(item.price);
                    };
                });
            }

            // if applicable, do prorate
            if ($scope.application.lease_start_at_raw != null) {
                var paystart = moment($scope.application.move_in_at);
                var payend = moment($scope.application.lease_start_at);
                var prorate_days = payend.diff(paystart, 'days');

                var start = moment($scope.application.lease_start_at);

                if ($scope.property.lease_application_prorate_calculate_monthly == 1) {
                    var daily_rent = $scope.total_amount / paystart.daysInMonth();
                } else {
                    var daysInYear = start.isLeapYear() ? 366 : 365;
                    var daily_rent = $scope.total_amount * 12 / daysInYear;
                }

                if (prorate_days > 0) {
                    $scope.prorate_amount = (daily_rent * prorate_days);
                } else {
                    $scope.prorate_amount = 0;
                }
            }

            var payment_amount = 0;
            if ($scope.property.lease_application_template == 'abtenancyagreement') {
                payment_amount+=  parseFloat($scope.application.security_deposit_amount);
                if ($scope.application.has_pets_deposit) {
                    payment_amount+=  250;
                }
            }else if($scope.property.lease_application_exclude_rentable_items_from_deposit) {
                if ($scope.property.lease_application_deposit_is_static == 1) {
                    payment_amount = parseFloat($scope.application.static_deposit_amount);
                } else {
                    payment_amount += parseFloat($scope.application.rent_amount); //total_amount includes rentable items, so start with base rent
                }
            } else {
                if ($scope.property.lease_application_deposit_is_static == 1) {
                    payment_amount = parseFloat($scope.application.static_deposit_amount);
                } else {
                    if ($scope.property.lease_application_deposit_same_as_rent == 1) {
                        payment_amount+=  parseFloat($scope.total_amount);
                    }
                    if ($scope.property.lease_application_damage_deposit_same_as_rent == 1) {
                        if ($scope.property.lease_application_deposit_half_base_rent == 1) {
                            payment_amount+= parseFloat($scope.application.rent_amount) / 2;
                        } else {
                            payment_amount+=  parseFloat($scope.total_amount) / 2;
                        }
                    }
                }
                if ($scope.property.lease_application_pet_deposit_same_as_rent == 1 && $scope.application.has_pets_deposit) {
                    if ($scope.property.lease_application_deposit_half_base_rent == 1) {
                        payment_amount+= parseFloat($scope.application.rent_amount) / 2;
                    } else {
                        payment_amount+=  parseFloat($scope.total_amount) / 2;
                    }
                }
            }

            if (typeof $scope.property.vars !== 'undefined') {
                if ($scope.property.vars.key_deposit_required == 'yes' && typeof $scope.property.vars.key_deposit_amount !== 'undefined') {
                    if ($scope.property.vars.key_laundry_deposit_addable == 'yes') {
                        payment_amount+= parseFloat($scope.property.vars.key_deposit_amount) * $scope.application.key_deposit_count;
                    } else {
                        if ($scope.property.vars.key_deposit_multiply == 'yes') {
                            payment_amount+= parseFloat($scope.property.vars.key_deposit_amount) * $scope.application.applicants.length;
                        } else {
                            payment_amount+= parseFloat($scope.property.vars.key_deposit_amount);
                        }
                    }
                }
                if ($scope.property.vars.laundry_deposit_required == 'yes' && typeof $scope.property.vars.laundry_deposit_amount !== 'undefined') {
                    if ($scope.property.vars.key_laundry_deposit_addable == 'yes') {
                        payment_amount+= parseFloat($scope.property.vars.laundry_deposit_amount) * $scope.application.laundry_deposit_count;
                    }
                }
            }

            if (payment_amount > 0) {
                vm.tenantData.amount = $scope.roundToTwo(parseFloat(payment_amount));
            }
            if ($scope.total_amount > 0) {
                vm.recurringTenantData.amount = $scope.roundToTwo(parseFloat($scope.total_amount));
            }
        };

        $scope.getBranding = function () {
            brandingService.get($scope.application.id).then(function (data) {
                $rootScope.branding = data.data;
            });
        };

        $scope.getStates = function () {
            if ($scope.application.applicants_tabs[$scope.activeApplicant].country == 'CA') {
                $http.post('/api/v1/get-provinces-translated').success(function (data) {
                    $scope.states = data;
                });
            } else {
                if ($scope.application.applicants_tabs[$scope.activeApplicant].country) {
                    $http.post('/api/v1/countrystate/get-provinces/' + $scope.application.applicants_tabs[$scope.activeApplicant].country).success(function(data) {
                        $scope.states = data.data.provinces;
                    });
                }
            }
        };

        $scope.getStatesForPastAddress = function (address) {
            if (address.country == 'CA') {
                $http.post('/api/v1/get-provinces-translated').success(function (data) {
                    address.available_states = data;
                });
            } else {
                $http.post('/api/v1/countrystate/get-provinces/' + address.country).success(function(data) {
                    address.available_states = data.data.provinces;
                });
            }
        };

        $scope.getCountries = function () {
            $http.post('/api/v1/countrystate/get-countries').success(function(data) {
                $scope.countries = data.data.countries;
            });
        };
        $scope.getCountries();

        $scope.getSteps = function () {

            var url = '/api/v1/lease-application/get-application-steps/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-get-application-steps/' + $scope.application.id;
            }
            $http.post(url).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('error_loading_steps'));
                    $state.go('main.leaseApplicationCreateCompanies');
                    return;
                }

                //$scope.step.slug = data.data.steps[0].slug;
                $scope.steps = data.data.steps;

            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $state.go('main.leaseApplicationCreateCompanies');
                toastr.error(data.error.message);
            });
        };

        $scope.getValidatedSteps = function () {
            var url = '/api/v1/lease-application/get-application-steps-validation-status/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-get-application-steps-validation-status/' + $scope.application.id;
            }
            $http.post(url, {application: $scope.application}).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('error_loading_steps'));
                    $state.go('main.leaseApplicationCreateCompanies');
                    return;
                }

                if (typeof data.data.warning !== 'undefined' && data.data.warning !== false) {
                    toastr.warning(data.data.warning);
                }

                if (typeof data.data.errors != 'undefined' && Array.isArray(data.data.errors) && data.data.errors.length > 0) {
                    $scope.handleValidationErrorsForSuccess(data.data.errors);
                }

                if (data.data.errors.length > 0 || Object.keys(data.data.individual_errors).length > 0) {
                    $scope.buttonDisabled = true;
                }

                $scope.validated_steps = data.data.steps;
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $state.go('main.leaseApplicationCreateCompanies');
                toastr.error(data.error.message);
            });
        };

        $scope.getLegal = function () {
            var url = '/api/v1/lease-application/get-legal/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-get-legal/' + $scope.application.id;
            }
            $http.post(url).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('error_legal_terms'));
                    $state.go('main.leaseApplicationCreateCompanies');
                    return;
                }

                $scope.legal_terms = data.data.legal_terms;
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $state.go('main.leaseApplicationCreateCompanies');
                toastr.error(data.error.message);
            });
        };
        $scope.getLegal();

        $scope.getDocumentTypes = function () {
            var url = '/api/v1/lease-application/get-supporting-document-types-for-application/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-get-supporting-document-types-for-application/' + $scope.application.id;
            }
            $http.post(url).success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.provided_documents = data.data.provided_documents;
                $scope.applicant_required_documents = data.data.applicant_required_types;
                $scope.co_applicant_required_documents = data.data.co_applicant_required_types;
                $scope.guarantor_required_documents = data.data.guarantor_required_types;
                $scope.applicant_optional_documents = data.data.applicant_optional_types;
                $scope.co_applicant_optional_documents = data.data.co_applicant_optional_types;
                $scope.guarantor_optional_documents = data.data.guarantor_optional_types;
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $state.go('main.leaseApplicationCreateCompanies');
                toastr.error(data.error.message);
            });
        };
        $scope.getDocumentTypes();

        $scope.openProvidedDocument = async function (doc) {
            var url = '/lease-application-provided-document-download/' + doc.id;
            if (window.Capacitor.isNativePlatform()) {
                try {
                    toastr.info('Downloading file...');
                    const { Filesystem, CapacitorHttp, FileOpener } = window.Capacitor.Plugins;

                    try {
                        const response = await CapacitorHttp.get({
                            url: pqDomain + url + '?cordova=1' + '&token=' + pqCachedToken,
                            responseType: 'blob'
                        });

                        console.log(response.headers);
                        const contentType = response.headers['content-type'] || response.headers['Content-Type'] || '';
                        console.log(contentType);

                        let fileExtension = '';
                        switch (contentType) {
                            case 'application/pdf':
                                fileExtension = 'pdf';
                                break;
                            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                fileExtension = 'docx';
                                break;
                            default:
                                fileExtension = contentType.split('/').pop(); // Fallback
                        }

                        const fileName = `Payquad/lease_application/document${doc.id}.${fileExtension}`;
                        const file = await Filesystem.writeFile({
                            path: fileName,
                            data: response.data,
                            directory: 'DOCUMENTS',
                            recursive: true
                        });

                        await FileOpener.open({
                            filePath: file.uri,
                            contentType: contentType
                        });
                    } catch (error) {
                        console.error('Unable to download or preview file:', error);
                        toastr.error('Unable to download or preview file');
                    }
                } catch (e) {
                    alert(e)
                }
            } else {
                window.open(url, '_blank');
            }
        };

        $scope.getPaymentMessage = function () {
            var url = '/api/v1/lease-application/get-payment-message/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-get-payment-message/' + $scope.application.id;
            }
            $http.post(url).success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.payment_message = data.data.payment_message;
                $scope.recurring_payment_message = data.data.recurring_payment_message;
            });
        };
        $scope.getPaymentMessage();

        $scope.getSteps();

        $scope.addApplicant = function () {
            const emergencyContacts = [];

            for (let i = 0; i < $scope.property.emergency_contact_required_count; i++) {
                emergencyContacts.push(angular.copy($scope.blankEmergencyContact));
            }

            $http.post('/api/v1/prospect/get-unique-registration-code').success(function(data) {
                if (!data.success) {
                    return;
                }

                const registration_code = data.data.registration_code;
                $scope.application.applicants.push({
                    id: 0,
                    user_id: 0,
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    relationship: '',
                    emergency_contacts: emergencyContacts,
                    no_email: 0,
                    registration_code: registration_code,
                });
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        $scope.removeApplicant = function (key) {
            $scope.application.applicants.splice(key, 1);
        };

        $scope.addPastAddress = function (applicant) {
            $scope.application.applicants_tabs[applicant].past_addresses.push(angular.copy($scope.blankAddress));
            $scope.getStatesForPastAddress($scope.application.applicants_tabs[applicant].past_addresses[$scope.application.applicants_tabs[applicant].past_addresses.length - 1]);
        };

        $scope.removePastAddress = function (applicant, key) {
            $scope.application.applicants_tabs[applicant].past_addresses.splice(key, 1);
        };

        $scope.addPet = function (applicant) {
            $scope.application.applicants_tabs[applicant].pets.push({
                name: '',
                weight: '',
                type: '',
                colour: ''
            });
        };

        $scope.removePet = function (applicant, key) {
            $scope.application.applicants_tabs[applicant].pets.splice(key, 1);
        };

        $scope.addVehicle = function (applicant) {
            $scope.application.applicants_tabs[applicant].vehicles.push({
                plate: '',
                colour: '',
                state: '',
                make: '',
                model: '',
                year: ''
            });
        };

        $scope.removeVehicle = function (applicant, key) {
            $scope.application.applicants_tabs[applicant].vehicles.splice(key, 1);
        };

        $scope.addOccupant = function () {
            $scope.application.occupants.push({
                first_name: '',
                last_name: '',
                age: '',
                relationship: ''
            });
        };

        $scope.removeOccupant = function (key) {
            $scope.application.occupants.splice(key, 1);
        };

        $scope.addGuarantor = function () {
            $scope.application.guarantors.push({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                relationship: ''
            });
        };

        $scope.removeGuarantor = function (key) {
            $scope.application.guarantors.splice(key, 1);
        };

        $scope.addEmergencyContact = index => {
            if(!$scope.application.applicants_tabs[index]){
                $scope.activeApplicant = 0;
                index = 0;
            }

            $scope.application.applicants_tabs[index].emergency_contacts.push(angular.copy($scope.blankEmergencyContact));
        };

        $scope.removeEmergencyContact = (applicantIndex, index) => $scope.application.applicants_tabs[applicantIndex].emergency_contacts.splice(index, 1);

        $scope.addReference = function () {
            $scope.application.references.push(angular.copy($scope.blankReference));
        };

        $scope.removeReference = function (key) {
            $scope.application.references.splice(key, 1);
        };

        $scope.getApplication = function () {
            var url = '/api/v1/lease-application/get/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-get/' + $scope.application.id;
            }
            $http.post(url).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('error_loading_application'));
                    return;
                }

                if (data.data.application.status != 'open' && $rootScope.authUser.permissions == 'applicant') {
                    $state.go('main.leaseApplicationDashboard');
                    return;
                }

                if ($rootScope.authUser.permissions == 'applicant') {
                    $scope.getBranding();
                }

                if (typeof data.data.next_step == 'undefined') {
                    toastr.error(data.errors, $scope.t('error_loading_application'));
                    return;
                }

                $scope.property = data.data.property;
                $scope.merchantAccount = data.data.merchant;
                $scope.additional_rentables = data.data.additional_rentables;

                if ($rootScope.authUser.permissions != 'applicant') {
                    propertyService.getQuestions(data.data.application.property_id).success((response) => {
                        $scope.questions = response.data;
                        $scope.populateApplication(data.data.application);
                    });
                } else {
                    propertyService.getQuestionsApplicant(data.data.application.property_id).success((response) => {
                        $scope.questions = response.data;
                        $scope.populateApplication(data.data.application);
                    });
                }

                if ($rootScope.authUser.permissions == 'applicant') {
                    //$scope.goToStep(data.data.next_step);
                }


                if (data.data.application.editable == false) {
                    alert($scope.t('view_only'));
                } else if ($rootScope.authUser.permissions == 'applicant' && $rootScope.authUser.user_id != data.data.application.user_id) {
                    alert($scope.coapplicant_update_message);
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };
        $scope.getApplication();

        $scope.populateApplication = function (application, skip_co_applicants) {
            if (typeof skip_co_applicants != 'undefined') {
                var keep_applicants = $scope.application.applicants;
            }

            $scope.application = angular.copy($scope.blankApplication);
            $scope.term_agreements = application.term_agreements;
            $scope.application.static_deposit_amount = application.static_deposit_amount;
            $scope.application.key_deposit_count = application.key_deposit_count;
            $scope.application.laundry_deposit_count = application.laundry_deposit_count;
            $scope.application.hidden_parking_rentable_items = application.hidden_parking_rentable_items;
            $scope.application.status = application.status;
            $scope.application.user_id = application.user_id;
            $scope.application.user = application.user;
            $scope.application.lease_start_at_raw = application.lease_start_at;
            $scope.application.editable = application.editable;
            $scope.application.bedrooms = application.bedrooms;
            $scope.application.floorplan = application.floorplan;
            $scope.application.unit_type_id = application.unit_type_id;
            $scope.application.kelson_pet_pickup_name = application.kelson_pet_pickup_name;
            $scope.application.kelson_pet_pickup_phone = application.kelson_pet_pickup_phone;
            $scope.application.kelson_pet_pickup_address = application.kelson_pet_pickup_address;
            $scope.application.unit = application.unit_number;
            $scope.application.move_in_at = moment(application.move_in_at).format('MM/DD/YYYY');
            $scope.application.lease_start_at = application.lease_start_at != null ? moment(application.lease_start_at).format('MM/DD/YYYY') : '';
            $scope.application.lease_end_at = application.lease_end_at != null ? moment(application.lease_end_at).format('MM/DD/YYYY') : '';
            //$scope.application.rent_amount = application.external_lease_amount > 0 ? application.external_lease_amount : application.rent_amount;
            $scope.application.rent_amount = application.rent_amount;
            $scope.application.security_deposit_amount = application.vars.damage_deposit_amount_keepalive == null ? 0 : application.vars.damage_deposit_amount_keepalive;
            $scope.application.email_confirmed = application.email_confirmed;
            $scope.application.property_id = application.property_id;
            $scope.application.transaction_id = application.transaction_id;
            $scope.application.paid = application.paid;
            $scope.application.create_schedule_on_conversion = application.create_schedule_on_conversion;
            $scope.application.agree_to_deposit_payment = application.agree_to_deposit_payment;
            $scope.application.agree_terms = application.agree_terms;
            $scope.application.last_step = application.last_step;
            $scope.application.has_pets_deposit = application.has_pets_deposit;

            // if vars.custom_address is set, use it
            $scope.application.custom_address = application.vars.custom_address ? application.vars.custom_address : '';

            $scope.application.concessions = Object.values(application.concessions);
            $scope.application.rentable_items = application.rentable_items;
            $scope.application.rentable_items_set_by_manager = application.rentable_items_set_by_manager;
            var counts = [];
            angular.forEach($scope.application.rentable_items, function (ritem, rkey) {
                if (typeof counts[ritem.code] === 'undefined') {
                    counts[ritem.code] = 1;
                    ritem.count = counts[ritem.code];
                } else {
                    counts[ritem.code]++;
                    ritem.count = counts[ritem.code];
                }
            });

            $scope.application.rentable_items = application.rentable_items;
            $scope.application.rentable_items_set_by_manager = application.rentable_items_set_by_manager;
            var counts = [];
            angular.forEach($scope.application.rentable_items, function (ritem, rkey) {
                if (typeof counts[ritem.code] === 'undefined') {
                    counts[ritem.code] = 1;
                    ritem.count = counts[ritem.code];
                } else {
                    counts[ritem.code]++;
                    ritem.count = counts[ritem.code];
                }
            });

            const emergencyContacts = [];

            for (let i = 0; i < $scope.property.emergency_contact_required_count; i++) {
                emergencyContacts.push(angular.copy($scope.blankEmergencyContact));
            }

            const applicants = [{
                id: application.user.applicant.id,
                user_id: application.user_id,
                user: application.user,
                first_name: application.user.applicant.first_name,
                last_name: application.user.applicant.last_name,
                phone: application.user.personal.phone_number,
                email: application.user.email,
                work_phone: application.user.personal.work_phone,
                mobile_phone: application.user.personal.mobile_phone,
                sin: application.user.personal.sin,
                drivers_licence: application.user.personal.drivers_licence,
                born_at: application.user.personal.born_at != null ? moment(application.user.personal.born_at).format('MM/DD/YYYY') : null,
                address: application.user.personal.address,
                months_at_address: application.user.personal.months_at_address,
                suite: application.user.personal.suite,
                country: application.user.personal.country,
                province: application.user.personal.province,
                city: application.user.personal.city,
                postal: application.user.personal.postal,
                rent_or_own: application.user.personal.rent_or_own,
                past_addresses: application.user.past_addresses,
                landlords: application.user.landlords.length > 0 ? application.user.landlords : [angular.copy($scope.blankLandlord)],
                pets: application.user.pets,
                agree_to_pet_policy: application.user.personal.agree_to_pet_policy,
                vehicles: application.user.vehicles,
                employments: application.user.employments,
                emergency_contacts: application.user.emergency_contacts && application.user.emergency_contacts.length > 0 ? application.user.emergency_contacts : emergencyContacts,
                employment_status: application.user.personal.employment_status,
                documents: application.user.applicationdocuments,
                type: 'applicant',
                qa: $scope.questions.map(q => {
                    let answ = application.user.lease_application_question_answers.filter(ans => ans.lease_application_question_id === q.id)
                    let item = {...q};
                    item.answers = answ;
                    return item;
                })
            }];

            const coApplicants = [];
            application.applicants.forEach(applicant => {
                if (applicant.user !== null && +applicant.user.id === +$rootScope.authUser.user_id) {
                    $scope.userType = 'co-applicant';
                }
                coApplicants.push(convertApplicantToTab(applicant, 'co-applicant'));
            });

            const guarantors = [];
            application.guarantors.forEach(applicant => {
                if (applicant.user !== null && +applicant.user.id === +$rootScope.authUser.user_id) {
                    $scope.userType = 'guarantor';
                }
                guarantors.push(convertApplicantToTab(applicant, 'guarantor'));
            });
            $scope.application.applicants_tabs = [...applicants, ...coApplicants, ...guarantors];

            // ensure $scope.activeApplicant still exists
            if ($scope.activeApplicant >= $scope.application.applicants_tabs.length) {
                $scope.activeApplicant = 0;
            }

            if (typeof skip_co_applicants == 'undefined') {
                $scope.application.applicants = [...applicants, ...coApplicants];
            } else {
                $scope.application.applicants = keep_applicants;
            }
            $scope.application.guarantors = guarantors;

            // add no email and registration code for co-applicants
            $scope.application.applicants.forEach(applicant => {
                applicant.no_email = 0;
                if (applicant.email.includes('@payquad.com')) {
                    applicant.registration_code = applicant.email.substring(0, applicant.email.indexOf('@'));
                } else {
                    $http.post('/api/v1/prospect/get-unique-registration-code').success(function(data) {
                        if (!data.success) {
                            return;
                        }

                        applicant.registration_code = data.data.registration_code;
                    }).error(function(data){
                        if (typeof data.stopaction != 'undefined') {
                            return;
                        }
                    });
                }
            });

            // loop applicants_tabs and set available addresses
            angular.forEach($scope.application.applicants_tabs, function (applicant, key) {
                // loop over past_addresses
                angular.forEach(applicant.past_addresses, function (address, key) {
                    $scope.getStatesForPastAddress(address);
                });
            });

            if (application.payment_method_id != null) {
                $scope.application.payment_method_id = application.payment_method_id;
            }
            if (application.recurring_payment_method_id != null) {
                $scope.application.recurring_payment_method_id = application.recurring_payment_method_id;
            }
            $scope.application.payment_method_name = application.payment_method_name;
            if (application.employment_status != null) {
                $scope.application.employment_status = application.employment_status;
            }
            if (application.guarantors.length) {
                $scope.application.guarantors = application.guarantors;
            }

            angular.forEach(application.occupants, function (occupant, key) {
                $scope.application.occupants.push({
                    first_name: occupant.first_name,
                    last_name: occupant.last_name,
                    age: occupant.age,
                    relationship: occupant.relationship
                });
            });
            if (application.references.length > 0) {
                angular.forEach(application.references, function (reference, key) {
                    $scope.application.references.push({
                        first_name: reference.first_name,
                        last_name: reference.last_name,
                        email: reference.email,
                        phone: reference.phone,
                        relationship: reference.relationship
                    });
                });
            } else {
                $scope.application.references.push(angular.copy($scope.blankReference));
            }

            $scope.auth_user_type = 'manager';
            if (
               $rootScope.authUser.permissions != 'junior_manager'
            && $rootScope.authUser.permissions != 'admin_manager'
            && $rootScope.authUser.permissions != 'payquad_admin'
            ) {
                $scope.application.applicants_tabs.forEach(applicant => {
                    if ($rootScope.authUser.user_id == applicant.user_id) {
                        if (applicant.type === 'applicant'
                         || applicant.type === 'co-applicant'
                        ) {
                            $scope.auth_user_type = 'applicant';
                        } else if (applicant.type === 'guarantor'){
                            $scope.auth_user_type = 'guarantor';
                        }
                    }
                });
            }

            $scope.getProperty();
            $scope.getPropertyUnits();
            $scope.populateTermsAgreement();
            $scope.getStates();
            $scope.getRentableItemItems();
            $scope.loading_spinner = 0;
        };

        $scope.handleValidationErrors = function (errors) {
            $scope.formerrors = {};

            angular.forEach(errors, function (field_errors, field) {
                angular.forEach(field_errors, function (error, key) {
                    var f = field.replace('application.', '');
                    var num_key = f.split('.');
                    num_key = num_key[1];
                    if (typeof $scope.formerrors[f] == 'undefined') {
                        $scope.formerrors[f] = [];
                    }
                    $scope.formerrors[f].push(error);
                    $scope.formerrors[field + '.' + num_key] = 1;
                });
            });

            $scope.popup_error_message = $scope.t("info_missing_error");
        };

        $scope.handleValidationErrorsForSuccess = function (errors) {
            $scope.formerrors = {};

            angular.forEach(errors, function (field_errors, field) {
                angular.forEach(field_errors, function (error, key) {
                    var f = key.replace('application.', '');
                    var num_key = f.split('.');
                    num_key = num_key[1];
                    if (typeof $scope.formerrors[f] == 'undefined') {
                        $scope.formerrors[f] = [];
                    }
                    $scope.formerrors[f].push(error);
                    $scope.formerrors[field + '.' + num_key] = 1;
                });
            });

            $scope.popup_error_message = $scope.t("info_missing_error");
        };

        $scope.saveAndGoNext = function () {
            // update email for co-applicants if no email option is checked
            $scope.application.applicants.forEach(applicant => {
                if (applicant.no_email === 1) {
                    applicant.email = applicant.registration_code + '@payquad.com';
                }
            });

            $scope.formerrors = {};
            $scope.loading_spinner = 1;
            var url = '/api/v1/lease-application/save/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-save/' + $scope.application.id;
            }
            $http.post(url, {step_slug: $scope.step.slug, application: $scope.application}).success(function (data) {
                if (!data.success) {
                    // for step unit, check for rentable items issue
                    if ($scope.step.slug == 'unit' && typeof data.errors.unavailable_rentable_items != 'undefined') {
                        $scope.populateApplication(data.errors.application, 1);
                        toastr.error(data.errors.unavailable_rentable_items);
                        $scope.loading_spinner = 0;
                        return;
                    }

                    $scope.submitting = false;
                    if (typeof data.errors.errors != 'undefined') {
                        $scope.handleValidationErrors(data.errors.errors);
                    } else {
                        toastr.error(data.message);
                    }
                    if (typeof data.errors.goto_step != 'undefined') {
                        $scope.goToStep(data.errors.goto_step);
                    }
                    $scope.loading_spinner = 0;
                    return;
                }

                if ($scope.step.slug == 'unit') {
                    $scope.calculateTotalAmount();
                }

                if (typeof data.data.errors != 'undefined') {
                    if (!Array.isArray(data.data.errors)) {
                        $scope.handleValidationErrorsForSuccess(data.data.errors);
                    } else {
                        angular.forEach(data.data.errors, function (error, key) {
                            //toastr.error(error, '', {timeOut:0, extendedTimeOut:0});
                            $scope.popup_error_message = error;
                        });
                        if (typeof data.data.doc_errors != 'undefined') {
                            $scope.formerrors = data.data.doc_errors;
                        }
                    }
                }

                if (typeof data.data['warning'] !== 'undefined' && data.data['warning'] !== false) {
                    toastr.warning(data.data['warning']);
                }

                if ($scope.step.slug == 'tenants' || $scope.step.slug == 'guarantors' || $scope.step.slug == 'unit' || ($scope.step.slug == 'personal' && typeof data.data.errors == 'undefined')) {
                    $scope.populateApplication(data.data['application']);
                }

                if (typeof data.data['new_email'] != 'undefined' && data.data['new_email'] == 1) {
                    toastr.success($scope.t('You have been sent an email to verify your new email address'));
                    $scope.application.email_confirmed = 0;
                }

                if (typeof data.data['next_step'] != 'undefined' && data.data['next_step'] == 'complete') {
                    if (data.data['unavailable_rentable_items'] !== false) {
                        toastr.info(data.data['unavailable_rentable_items']);
                    }

                    $scope.loading_spinner = 0;
                    $scope.doCompleteRedirect();
                    return;
                }

                var message = $scope.t('Application Saved');
                if ($scope.step.slug == 'payment' && $scope.application.payment_method_id != 0) {
                    if ($scope.property.lease_application_delay_payment == 1) {
                        message = $scope.t('payment_method_saved')+' '+$scope.t('payment_will_process_upon_approval');
                    } else {
                        message = $scope.t('payment_method_saved')+' '+$scope.t('payment_will_process_upon_final_submission');
                    }
                }
                $scope.submitting = false;
                if ($scope.application.editable) {
                    toastr.success(message);
                }
                $scope.loading_spinner = 0;

                if ($scope.userType !== 'applicant' && typeof data.data.errors == 'undefined') {
                    const index = $scope.steps.findIndex(item => item.slug === $scope.step.slug);
                    if (index + 1 < $scope.steps.length) {
                        //$scope.step.slug = $scope.steps[index + 1].slug;
                        $scope.goToStep($scope.steps[index + 1].slug);
                    } else {
                        $scope.goToStep('review');
                    }
                } else if (typeof data.data['next_step'] != 'undefined' && typeof data.data.errors == 'undefined') {
                    if (data.data['next_step'] == false) {
                        $scope.goToStep('review');
                    } else {
                        $scope.goToStep(data.data['next_step']);
                    }
                } else if (typeof data.data['next_step'] != 'undefined' && typeof data.data.errors != 'undefined') {
                    $('#confirmation-modal').appendTo('body').modal('show');
                    if (data.data['next_step'] == false) {
                        $scope.next_step_if_confirmation = 'review';
                    } else {
                        $scope.next_step_if_confirmation = data.data['next_step'];
                    }
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };

        $scope.confirmedGoToNext = function () {
            $('#confirmation-modal').modal('hide');
            if ($scope.next_step_if_confirmation != null) {
                $scope.goToStep($scope.next_step_if_confirmation);
            }
            $scope.next_step_if_confirmation = null;
            toastr.clear();
        };

        $scope.saveStep = function () {
            $scope.formerrors = {};
            $scope.loading_spinner = 1;

            let hasUnCreditCheckedApplicants = $scope.application.applicants.some(i => i.user && i.user.personal.certn_application_id === null);
            let hasUnCreditCheckedGuarantors = $scope.application.guarantors.some(i => i.user && i.user.personal.certn_application_id === null);

            if (
                $scope.property.credit_check_enabled == 1
                && (hasUnCreditCheckedApplicants || hasUnCreditCheckedGuarantors || !$scope.application.user.personal.certn_application_id)
                && $scope.property.lease_application_credit_check_mandatory_enabled
                && $scope.step.slug == 'review'
                && $scope.application.status == 'review'
                && $scope.application.editable
            ) {
                toastr.error($scope.t('Please run a credit check before approving this application'));
                $scope.loading_spinner = 0;
                return;
            }

            // update email for co-applicants if no email option is checked
            $scope.application.applicants.forEach(applicant => {
                if (applicant.no_email === 1) {
                    applicant.email = applicant.registration_code + '@payquad.com';
                }
            });

            var url = '/api/v1/lease-application/save/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-save/' + $scope.application.id;
            }
            $http.post(url, {step_slug: $scope.step.slug, application: $scope.application}).success(function (data) {
                if (!data.success) {
                    $scope.submitting = false;

                    // for step unit, check for rentable items issue
                    if ($scope.step.slug == 'unit' && typeof data.errors.unavailable_rentable_items != 'undefined') {
                        $scope.populateApplication(data.errors.application, 1);
                        toastr.error(data.errors.unavailable_rentable_items);
                        $scope.loading_spinner = 0;
                        return;
                    }
                    if (typeof data.errors.errors != 'undefined') {
                        $scope.handleValidationErrorsForSuccess(data.errors.errors);
                    } else {
                        toastr.error(data.message);
                    }
                    if (typeof data.errors.goto_step != 'undefined') {
                        $scope.goToStep(data.errors.goto_step);
                    }
                    $scope.loading_spinner = 0;
                    return;
                }

                if (typeof data.data.errors != 'undefined') {
                    if (!Array.isArray(data.data.errors)) {
                        $scope.handleValidationErrorsForSuccess(data.data.errors);
                    } else {
                        angular.forEach(data.data.errors, function (error, key) {
                            toastr.error(error);
                        });
                    }
                }

                if (typeof data.data['warning'] !== 'undefined' && data.data['warning'] !== false) {
                    toastr.warning(data.data['warning']);
                }

                if ($scope.step.slug == 'review') {
                    if (data.data['unavailable_rentable_items'] !== false) {
                        toastr.info(data.data['unavailable_rentable_items']);
                    }
                    $scope.doCompleteRedirect();
                    $scope.loading_spinner = 0;
                    return;
                }

                if ($scope.step.slug == 'unit') {
                    $scope.calculateTotalAmount();
                }

                if ($scope.step.slug == 'tenants' || $scope.step.slug == 'guarantors' || $scope.step.slug == 'unit' || ($scope.step.slug == 'personal' && typeof data.data.errors == 'undefined')) {
                    $scope.populateApplication(data.data['application']);
                }

                if (typeof data.data['new_email'] != 'undefined' && data.data['new_email'] == 1) {
                    toastr.success($scope.t('verification_email_sent'));
                    $scope.application.email_confirmed = 0;
                }

                var message = $scope.t('Application Saved');
                if ($scope.step.slug == 'payment' && $scope.application.payment_method_id != 0) {
                    message = $scope.t('payment_method_saved')+' '+$scope.t('payment_will_process_upon_final_submission');
                    if ($scope.property.lease_application_delay_payment == 1) {
                        message = $scope.t('payment_method_saved')+' '+$scope.t('payment_will_process_upon_approval');
                    }
                }
                $scope.submitting = false;
                if ($scope.application.editable) {
                    toastr.success(message);
                }
                $scope.loading_spinner = 0;
            }).error(function (data) {
                if (typeof data.data.stopaction != 'undefined') {
                    return;
                }

                $scope.submitting = false;
                toastr.error(data.error.message);
            });
        };


        $scope.goToStep = function (step) {
            if (step != $scope.step.slug) {
                $scope.step.slug = step;

                if ($rootScope.authUser.permissions == 'applicant') {
                    // update current step in db
                    $http.post('/api/v1/lease-application/save-current-step/' + $scope.application.id, {current_step: $scope.step.slug});
                }

                if ($scope.step.slug == 'supporting-docs') {
                    $timeout(function () {
                        $scope.initDropzone();
                        $scope.show_dropzone = 1;
                    }, 500);
                } else {
                    $scope.show_dropzone = 0;
                }

                if ($scope.step.slug == 'review') {
                    $scope.buttonDisabled = false;
                    $scope.getValidatedSteps();
                }

                if (step === 'emergency-contact') {
                    // if Guarantor was selected need to reset to applicant
                    if ($scope.application.applicants_tabs[$scope.activeApplicant].type === 'guarantor') {
                        $scope.activeApplicant = 0;
                    }
                }

                if ($rootScope.authUser.permissions == 'applicant' && $rootScope.authUser.user_id != $scope.application.user_id) {
                    if ($scope.step.slug == 'unit' || $scope.step.slug == 'guarantors' || $scope.step.slug == 'tenants') {
                        alert($scope.coapplicant_update_message);
                    }
                }
            }
        };

        $scope.initDropzone = function () {
            if ($scope.property.lease_application_documents_old_format == 1) {
                $scope.doDropzone('support', 0, 'all');
            } else {
                if ($scope.application.applicants_tabs[$scope.activeApplicant].type === 'applicant') {
                    angular.forEach($scope.applicant_required_documents, function (req_doc, key) {
                        $scope.doDropzone('support', req_doc.id, 'applicant');
                    });
                    angular.forEach($scope.applicant_optional_documents, function (opt_doc, key) {
                        $scope.doDropzone('support', opt_doc.id, 'applicant');
                    });
                } else if ($scope.application.applicants_tabs[$scope.activeApplicant].type === 'co-applicant') {
                    angular.forEach($scope.co_applicant_required_documents, function (req_doc, key) {
                        $scope.doDropzone('support', req_doc.id, 'co-applicant');
                    });
                    angular.forEach($scope.co_applicant_optional_documents, function (opt_doc, key) {
                        $scope.doDropzone('support', opt_doc.id, 'co-applicant');
                    });
                } else if ($scope.application.applicants_tabs[$scope.activeApplicant].type === 'guarantor') {
                    angular.forEach($scope.guarantor_required_documents, function (req_doc, key) {
                        $scope.doDropzone('support', req_doc.id, 'guarantor');
                    });
                    angular.forEach($scope.guarantor_optional_documents, function (opt_doc, key) {
                        $scope.doDropzone('support', opt_doc.id, 'guarantor');
                    });
                }

                angular.forEach($scope.provided_documents, function (provided_doc, key) {
                    $scope.doDropzone('provided', provided_doc.id, 'all');
                });
            }
        };

        $scope.initUserDropzone = function () {
            if ($scope.application.applicants_tabs[$scope.activeApplicant].type === 'applicant') {
                angular.forEach($scope.applicant_required_documents, function (req_doc, key) {
                    $scope.doDropzone('support', req_doc.id, 'applicant');
                });
                angular.forEach($scope.applicant_optional_documents, function (opt_doc, key) {
                    $scope.doDropzone('support', opt_doc.id, 'applicant');
                });
            } else if ($scope.application.applicants_tabs[$scope.activeApplicant].type === 'co-applicant') {
                angular.forEach($scope.co_applicant_required_documents, function (req_doc, key) {
                    $scope.doDropzone('support', req_doc.id, 'co-applicant');
                });
                angular.forEach($scope.co_applicant_optional_documents, function (opt_doc, key) {
                    $scope.doDropzone('support', opt_doc.id, 'co-applicant');
                });
            } else if ($scope.application.applicants_tabs[$scope.activeApplicant].type === 'guarantor') {
                angular.forEach($scope.guarantor_required_documents, function (req_doc, key) {
                    $scope.doDropzone('support', req_doc.id, 'guarantor');
                });
                angular.forEach($scope.guarantor_optional_documents, function (opt_doc, key) {
                    $scope.doDropzone('support', opt_doc.id, 'guarantor');
                });
            }
        }

        $scope.doDropzone = function (doc_type, doc_id, user_type) {
            var headers = {};
            if(!!window.Capacitor.isNativePlatform()) {
                headers = {
                    'Cache-Control': null,
                    'X-Requested-With': null,
                }
            }

            var dropzone_key = '#real-dropzone-' + user_type + '-' + doc_id;
            if (doc_type == 'provided') {
                dropzone_key = '#real-dropzone-provided-' + user_type + '-' + doc_id;
            }
            $(dropzone_key).dropzone({
                uploadMultiple: false,
                parallelUploads: 1,
                maxFilesize: 20,
                addRemoveLinks: false,
                dictRemoveFile: 'Remove',
                dictFileTooBig: 'File is bigger than 20MB',
                acceptedFiles: [
                    '.doc',
                    '.docx',
                    '.pdf',
                    '.jpg',
                    '.jpeg',
                    '.png',
                ].join(','),
                dictInvalidFileType: 'Only .pdf, .docx, .jpeg, .png files accepted for upload',
                url: files => {
                    var url = $rootScope.pqDomain + '/api/v1/lease-application/supporting-document-upload/' + $scope.application.id;
                    if ($rootScope.authUser.permissions != 'applicant') {
                        url = $rootScope.pqDomain + '/api/v1/lease-application/manager-supporting-document-upload/' + $scope.application.id;
                    }
                    url += '/' + $scope.application.applicants_tabs[$scope.activeApplicant].user_id;
                    url += '/application/' + doc_id + '/' + doc_type;
                    url += '?token=' + pqCachedToken;
                    return url;
                },

                // The setting up of the dropzone
                error: function (file, response) {
                    var message = '';
                    if ($.type(response) === 'string') {
                        message = response; //dropzone sends it's own error messages in string
                    } else {
                        message = response.message;
                    }

                    toastr.error(message);

                    var _ref;
                    return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                },
                success: function (file, response, done) {

                    if (!response.success) {
                        toastr.error(response.errors.message);
                        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                    }
                    $scope.$apply(function () {
                        $scope.application.applicants_tabs[$scope.activeApplicant].documents = response.data.documents;
                    });
                    toastr.success($scope.t('doc_uploaded'));
                    var _ref;
                    return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                },
                uploadprogress: function (file, progress, bytesSent) {
                    $('#instruction').html($scope.t('uploading') + ' '+file.name);
                }
            });
        }

        $scope.deleteDocument = function (document_id) {
            if (!confirm($scope.t('delete_doc_confirm'))) {
                return;
            }

            var url = '/api/v1/lease-application/delete-document/' + $scope.application.id + '/' + document_id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-delete-document/' + $scope.application.id + '/' + document_id;
            }
            $http.post(url).success(function (data) {
                if (!data.success) {
                    toastr.error($scope.t('delete_doc_error'));
                    return;
                }
                $scope.application.applicants_tabs[$scope.activeApplicant].documents = data.data.documents;
                toastr.success($scope.t('doc_deleted'));
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;x
                }

                toastr.error(data.error.message);
            });
        }

        var vm = this;
        vm.tenantInfo = false;
        vm.recurringTenantInfo = false;
        vm.billing_same_as_mailing = true;
        vm.tenantData = {paymentType: 0};
        vm.recurringTenantData = {paymentType: 0};
        $scope.ref = {
            payment_method: {},
            tenant: {}
        };
        $scope.paymentMethods = {};
        $scope.paymentMethodCount = 0;

        $scope.getApplicationPaymentMethods = function () {
            vm.tenantInfo = false;
            var url = '/api/v1/lease-application/payment-methods/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-payment-methods/' + $scope.application.id;
            }
            $http.post(url).success(function (data) {
                if (data.success) {
                    //vm.tenantData.tenant_id = data.data.tenant.id;
                    if (!vm.tenantData.amount || vm.tenantData.amount <= 0) {
                        if ($scope.property.lease_application_deposit_is_static == 1) {
                            vm.tenantData.amount = $scope.roundToTwo(parseFloat($scope.application.static_deposit_amount));
                        } else {
                            vm.tenantData.amount = data.data.payment_amount * 1; //TODO
                        }
                    }
                    vm.tenantInfo = data.data;
                    vm.tenantInfo.hasCredit = false;
                    vm.tenantInfo.hasDebitVisa = false;
                    vm.tenantInfo.hasDirectDebit = false;

                    vm.tenantInfo.CreditFeeString = $scope.t('service_fees')+':<br />';
                    vm.tenantInfo.DebitVisaFeeString = $scope.t('service_fee')+': ';
                    vm.tenantInfo.DirectDebitFeeString = $scope.t('service_fee')+': ';

                    for (var i in vm.tenantInfo.methods) {
                        if (vm.tenantInfo.methods[i].value == 'credit') {
                            vm.tenantInfo.hasCredit = true;
                        } else if (vm.tenantInfo.methods[i].value == 'debit_visa') {
                            vm.tenantInfo.hasDebitVisa = true;
                            vm.tenantInfo.DebitVisaFeeString += '' + vm.tenantInfo.methods[i].tenant_fee + $scope.t('per_transaction');
                        } else if (vm.tenantInfo.methods[i].value == 'direct_debit') {
                            vm.tenantInfo.hasDirectDebit = true;
                            vm.tenantInfo.DirectDebitFeeString += '' + vm.tenantInfo.methods[i].tenant_fee + $scope.t('per_transaction');
                        } else {
                            vm.tenantInfo.CreditFeeString += vm.tenantInfo.methods[i].name + ': ' + vm.tenantInfo.methods[i].tenant_fee + $scope.t('per_transaction') +'<br />';
                        }
                    }

                    $scope.clearTenantData();
                } else {
                    toastr.error(data.data.error);
                }
            });
        }

        $scope.getApplicationRecurringPaymentMethods = function () {
            vm.recurringTenantInfo = false;
            var url = '/api/v1/lease-application/recurring-payment-methods/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-recurring-payment-methods/' + $scope.application.id;
            }
            $http.post(url).success(function (data) {
                if (data.success) {
                    vm.recurringTenantInfo = data.data;
                    vm.recurringTenantInfo.hasCredit = false;
                    vm.recurringTenantInfo.hasDebitVisa = false;
                    vm.recurringTenantInfo.hasDirectDebit = false;

                    vm.recurringTenantInfo.CreditFeeString = $scope.t('service_fees')+':<br />';
                    vm.recurringTenantInfo.DebitVisaFeeString = $scope.t('service_fee')+': ';
                    vm.recurringTenantInfo.DirectDebitFeeString = $scope.t('service_fee')+': ';

                    for (var i in vm.recurringTenantInfo.methods) {
                        if (vm.recurringTenantInfo.methods[i].value == 'credit') {
                            vm.recurringTenantInfo.hasCredit = true;
                        } else if (vm.recurringTenantInfo.methods[i].value == 'debit_visa') {
                            vm.recurringTenantInfo.hasDebitVisa = true;
                            vm.recurringTenantInfo.DebitVisaFeeString += '' + vm.recurringTenantInfo.methods[i].tenant_fee + $scope.t('per_transaction');
                        } else if (vm.recurringTenantInfo.methods[i].value == 'direct_debit') {
                            vm.recurringTenantInfo.hasDirectDebit = true;
                            vm.recurringTenantInfo.DirectDebitFeeString += '' + vm.recurringTenantInfo.methods[i].tenant_fee + $scope.t('per_transaction');
                        } else {
                            vm.recurringTenantInfo.CreditFeeString += vm.recurringTenantInfo.methods[i].name + ': ' + vm.recurringTenantInfo.methods[i].tenant_fee + $scope.t('per_transaction') +'<br />';
                        }
                    }

                    $scope.clearRecurringTenantData();
                } else {
                    toastr.error(data.data.error);
                }
            });
        }

        $scope.clearTenantData = function () {
            vm.tenantData.institution_number = '';
            vm.tenantData.transit_number = '';
            vm.tenantData.account_number = '';
            vm.tenantData.day_of_month = '';
            vm.tenantData.description = '';
            vm.tenantData.editing_schedule_id = 0;
            vm.tenantData.paymentType = 0;
            vm.tenantData.payment_recurring = 'one_time';
            vm.tenantData.billingunit = '';
            vm.tenantData.address = '';
            vm.tenantData.city = '';
            vm.tenantData.province = '';
            vm.tenantData.country = '';
            vm.tenantData.postal_code = '';
            vm.billing_same_as_mailing = true;
            vm.tenantData.card_holder_name = '';
            vm.tenantData.card_number = '';
            vm.tenantData.expiration_year = '';
            vm.tenantData.expiration_month = '';
            vm.tenantData.cvv = '';
        };

        $scope.clearRecurringTenantData = function () {
            vm.recurringTenantData.institution_number = '';
            vm.recurringTenantData.transit_number = '';
            vm.recurringTenantData.account_number = '';
            vm.recurringTenantData.day_of_month = '';
            vm.recurringTenantData.description = '';
            vm.recurringTenantData.editing_schedule_id = 0;
            vm.recurringTenantData.paymentType = 0;
            vm.recurringTenantData.payment_recurring = 'one_time';
            vm.recurringTenantData.billingunit = '';
            vm.recurringTenantData.address = '';
            vm.recurringTenantData.city = '';
            vm.recurringTenantData.province = '';
            vm.recurringTenantData.country = '';
            vm.recurringTenantData.postal_code = '';
            vm.recurring_billing_same_as_mailing = true;
            vm.recurringTenantData.card_holder_name = '';
            vm.recurringTenantData.card_number = '';
            vm.recurringTenantData.expiration_year = '';
            vm.recurringTenantData.expiration_month = '';
            vm.recurringTenantData.cvv = '';
        };

        $scope.submitPayment = function (gonext) {
            if ($scope.property.country === 'United States') {
                if (vm.tenantData.paymentType === 'pad') {
                    vm.tenantData.provider = 'payrix';
                    $scope.$apply();
                    $scope.processPayment(gonext)
                } else {
                    PayFields.submit()
                    PayFields.onSuccess = (r) => {
                        console.log('onSuccess', r)
                        vm.tenantData.card_holder_name = `${r.data[0].customer.first} ${r.data[0].customer.last}`;
                        vm.tenantData.card_number = r.data[0].payment.number;
                        vm.tenantData.expiration_year = 20 + r.data[0].expiration.slice(-2);
                        vm.tenantData.expiration_month = r.data[0].expiration.substring(0, 2);
                        vm.tenantData.payrix = r;
                        vm.tenantData.provider = 'payrix';
                        $scope.$apply();
                        $scope.processPayment(gonext)
                    };
                }
            } else {
                $scope.processPayment(gonext)
            }
        }

        $scope.submitRecurringPayment = function (gonext) {
            if ($scope.property.country === 'United States') {
                if (vm.recurringTenantData.paymentType === 'pad') {
                    vm.recurringTenantData.provider = 'payrix';
                    $scope.$apply();
                    $scope.processRecurringPayment(gonext)
                } else {
                    PayFields.submit()
                    PayFields.onSuccess = (r) => {
                        console.log('onSuccess', r)
                        vm.recurringTenantData.card_holder_name = `${r.data[0].customer.first} ${r.data[0].customer.last}`;
                        vm.recurringTenantData.card_number = r.data[0].payment.number;
                        vm.recurringTenantData.expiration_year = 20 + r.data[0].expiration.slice(-2);
                        vm.recurringTenantData.expiration_month = r.data[0].expiration.substring(0, 2);
                        vm.recurringTenantData.payrix = r;
                        vm.recurringTenantData.provider = 'payrix';
                        $scope.$apply();
                        $scope.processRecurringPayment(gonext)
                    };
                }
            } else {
                $scope.processRecurringPayment(gonext)
            }
        }

        $scope.payrixValidationErrors = {};
        $scope.processPayment = function (gonext) {
            if (!gonext) {
                gonext = 0;
            }
            vm.tenantData.property_id = $scope.application.property_id;
            if ($scope.property.country === 'Canada') {
                vm.tenantData.credit_card_type = jQuery('#card_type').text().replace('American Express', 'amex').toLowerCase();
            }
            var url = '/api/v1/lease-application/process-payment-method/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-process-payment-method/' + $scope.application.id;
            }

            $http.post(url, vm.tenantData).success(function (result) {
                $('.toast').remove();
                if (result.code == 200 && result.success) {
                    if (typeof result.data.skipped != 'undefined') {
                        if (gonext == 0) {
                            $scope.saveStep();
                        } else {
                            $scope.saveAndGoNext();
                        }
                        return;
                    }

                    $scope.application.payment_method_id = result.data.application.payment_method_id;
                    $scope.application.payment_method_name = result.data.application.payment_method_name;
                    $scope.clearTenantData();
                    if (gonext == 0) {
                        $scope.saveStep();
                    } else {
                        $scope.saveAndGoNext();
                    }
                } else {
                    $('.toast').remove();
                    if (result.errors) {
                        result.errors.forEach(msg => toastr.error(msg));
                    } else if (typeof result.message != 'undefined') {
                        toastr.error(result.message);
                    } else {
                        toastr.error($scope.t('payment_processing_error'));
                    }
                    if (result.extra && result.extra.validation) {
                        console.log(result.extra.validation)
                        $scope.payrixValidationErrors = result.extra.validation;
                    }
                }
            })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $('.toast').remove();
                    toastr.error($scope.t('payment_create_error'));
                });
        };

        $scope.processRecurringPayment = function (gonext) {
            if (!gonext) {
                gonext = 0;
            }
            vm.recurringTenantData.property_id = $scope.application.property_id;
            if ($scope.property.country === 'Canada') {
                vm.recurringTenantData.credit_card_type = jQuery('#card_type').text().replace('American Express', 'amex').toLowerCase();
            }
            var url = '/api/v1/lease-application/process-recurring-payment-method/' + $scope.application.id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-process-recurring-payment-method/' + $scope.application.id;
            }

            $http.post(url, vm.recurringTenantData).success(function (result) {
                $('.toast').remove();
                if (result.code == 200 && result.success) {
                    if (typeof result.data.skipped != 'undefined') {
                        if (gonext == 0) {
                            $scope.saveStep();
                        } else {
                            $scope.saveAndGoNext();
                        }
                        return;
                    }

                    $scope.application.recurring_payment_method_id = result.data.application.recurring_payment_method_id;
                    $scope.application.recurring_payment_method_name = result.data.application.recurring_payment_method_name;
                    $scope.clearRecurringTenantData();
                    if (gonext == 0) {
                        $scope.saveStep();
                    } else {
                        $scope.saveAndGoNext();
                    }
                } else {
                    $('.toast').remove();
                    if (result.errors) {
                        result.errors.forEach(msg => toastr.error(msg));
                    } else if (typeof result.message != 'undefined') {
                        toastr.error(result.message);
                    } else {
                        toastr.error($scope.t('payment_processing_error'));
                    }
                    if (result.extra && result.extra.validation) {
                        console.log(result.extra.validation)
                        $scope.payrixValidationErrors = result.extra.validation;
                    }
                }
            })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $('.toast').remove();
                    toastr.error($scope.t('payment_create_error'));
                });
        };

        $scope.completeApplication = function () {
            if ($scope.application.editable) {
                $scope.saveStep();
            }
        };

        $scope.openDocumentMobile = async function (pdfUrl, id) {
            toastr.info('Downloading file...');
            const {Filesystem, CapacitorHttp, FileOpener} = window.Capacitor.Plugins;

            try {
                const response = await CapacitorHttp.get({
                    url: pdfUrl,
                    responseType: 'blob'
                });

                console.log(response.headers)
                const contentType = response.headers['content-type'] || response.headers['Content-Type'] || ''
                console.log(contentType)
                console.log(contentType.split('/'))

                const fileExtension = contentType.split('/').pop(); // Determine file extension from content type

                const file = await Filesystem.writeFile({
                    path: `Payquad/lease_application/document${id}.${fileExtension}`,
                    data: response.data,
                    directory: 'DOCUMENTS',
                    recursive: true
                });

                await FileOpener.open({
                    filePath: file.uri,
                    contentType: contentType
                });

            } catch (error) {
                console.error('Unable to download or preview PDF:', error);
                toastr.error('Unable to download or preview file ');
            }
        }

        $scope.openDocument = function (doc) {
            var url = '/lease-application-document-download/' + doc.id;
            if(window.Capacitor.isNativePlatform()) {
                try {
                    $scope.openDocumentMobile(pqDomain + url + '?cordova=1&token=' + pqCachedToken, doc.id);
                } catch (e) {
                    alert(e)
                }
            } else {
                window.open(url, '_blank');
            }
        };

        $scope.doCompleteRedirect = function () {
            if ($scope.application.status == 'review') {
                toastr.success($scope.t('app_approved'));
            } else if ($scope.application.payment_method_id == 0) {
                toastr.success($scope.t('app_submitted'));
            } else {
                if ($scope.property.lease_application_delay_payment == 1) {
                    toastr.success($scope.t('app_submitted_payment_will_be_processed'));
                } else {
                    if (vm.tenantData.paymentType == 'credit') {
                        toastr.success($scope.t('app_submitted_payment_processed'));
                    } else {
                        toastr.success($scope.t('app_submitted_payment_submitted'));
                    }
                }
            }
            if ($rootScope.authUser.permissions == 'applicant') {
                $state.go('main.leaseApplicationDashboard');
            } else {
                $state.go('main.leaseApplicationManagerView', {id: $scope.application.id});
            }
        };

        $scope.resendConfirmation = function () {
            $http.post('/api/v1/lease-application/resend-confirmation/' + $scope.application.id).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('resending_email_error'));
                    return;
                }

                if (data.data.confirmed == 1) {
                    toastr.success($scope.t('account_confirmed'));
                    $scope.application.email_confirmed = 1;
                    return;
                }

                toastr.success($scope.t('new_confirmation_email_sent'));
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.goBack = function () {
            var last_step = '';
            var redirected = 0;
            angular.forEach($scope.steps, function (step, key) {
                if (step.slug == $scope.step.slug) {
                    if (last_step != '') {
                        $scope.goToStep(last_step);
                        redirected = 1;
                    } else {
                        $scope.goToStep('unit');
                        redirected = 1;
                    }
                    return;
                }

                last_step = step.slug;
            });

            // must be on review step, go to the final step before review
            if (redirected == 0) {
                $scope.goToStep(last_step);
            }
        };

        $scope.changePaymentMethod = function () {
            //console.log('changePaymentMethod', $scope.application)
            $scope.application.payment_method_id = 0;
        };

        $scope.changeRecurringPaymentMethod = function () {
            //console.log('changeRecurringPaymentMethod', $scope.application)
            $scope.application.recurring_payment_method_id = 0;
        };

        $scope.errorText = function (field) {
            if (typeof $scope.formerrors[field] == 'undefined') {
                return '';
            }
            return $scope.formerrors[field][0];
        };

        $scope.hasError = function (field) {
            //console.log($scope.formerrors);
            return typeof $scope.formerrors[field] !== 'undefined';
        }

        $scope.getPropertyUnits = function () {
            var data = {
                show_floorplans: $scope.show_floorplans,
                floorplan: $scope.application.floorplan
            };
            $http.post('/api/v1/properties/get-property-units-for-manager-payments/' + $scope.application.property_id, data).success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.property_units = [];
                angular.forEach(data.data, function (unit, key) {
                    if (!$scope.property_units.includes(unit.unit)) {
                        $scope.property_units.push(unit.unit);
                    }
                });
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        $scope.checkUnitAvailability = function () {
            $http.post('/api/v1/prospect/get-unit-rent-amount/' + $scope.application.property_id + '/' + $scope.application.unit, {start_date: $scope.application.lease_start_at}).success(function (data) {
                //if (data.data.available == 0 && data.data.rent_amount != 0) {

                if (!data.success) {
                    return;
                }

                if (data.data.available == 0) {
                    toastr.error($scope.t('unit_unavailable'));
                    return;
                }

                if (data.data.rent_amount > 0) {
                    $scope.application.rent_amount = data.data.rent_amount;
                }
                $scope.calculateTotalAmount();
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        var timeoutPromise;
        var delayInMs = 1500;
        $scope.$watch('application.unit', function (newValue, oldValue) {
            $timeout.cancel(timeoutPromise);  //does nothing, if timeout alrdy done
            toastr.clear();
            timeoutPromise = $timeout(function(){   //Set timeout
                if (oldValue !== newValue && typeof oldValue != 'undefined') {
                    $scope.checkUnitAvailability();
                }
            },delayInMs);
        });

        function validatePhoneNumberIterable (watchKey, key) {
            $scope.$watch(watchKey, (newValue, oldValue) => {
                newValue && newValue.map((val, index) => {
                    const libPN = new libphonenumber.AsYouType('CA');
                    val[key] && (val[key] = libPN.input(val[key]));
                });
            }, true);
        }

        function validatePhoneNumber(watchKey, key, f) {
            $scope.$watch(watchKey, (newValue, oldValue) => {
                const libPN = new libphonenumber.AsYouType('CA');
                const scopeKey = `application.${f}[${$scope.activeApplicant}].${key}`;
                const model = $parse(scopeKey);
                if (!newValue) {
                    newValue = '';
                }
                model.assign($scope, libPN.input(newValue));
            }, true);
        }

        $scope.$watch('activeApplicant', (newValue, oldValue) => {
            validatePhoneNumber('application.applicants_tabs[' + newValue + '].work_phone', 'work_phone','applicants_tabs');
            validatePhoneNumber('application.applicants_tabs[' + newValue + '].mobile_phone', 'mobile_phone','applicants_tabs');
            validatePhoneNumberIterable('application.applicants_tabs[' + newValue + '].landlords', 'manager_phone','applicants_tabs');
            validatePhoneNumberIterable('application.applicants_tabs[' + newValue + '].employments', 'employer_phone');
            validatePhoneNumberIterable('application.applicants[' + newValue + '].emergency_contacts', 'phone');
        }, true);
        validatePhoneNumberIterable('application.applicants', 'phone');
        validatePhoneNumberIterable('application.guarantors', 'phone');
        validatePhoneNumberIterable('application.references', 'phone');

        $scope.addRentableItem = function (key, item) {
            var new_item = {
                amount: item.amount,
                charge_code: item.charge_code,
                checked: item.checked,
                code: item.code,
                description: item.description
            };
            var counts = [];
            var counts_max_key = [];

            angular.forEach($scope.application.rentable_items, function (ritem, rkey) {
                if (typeof counts[ritem.code] === 'undefined') {
                    counts[ritem.code] = 1;
                    ritem.count = counts[ritem.code];
                } else {
                    counts[ritem.code]++;
                    ritem.count = counts[ritem.code];
                }
                counts_max_key[ritem.code] = rkey;
            });

            new_item.count = counts[new_item.code] + 1;

            if (typeof $scope.additional_rentables[new_item.charge_code] != 'undefined') {
                var add_rentable = $scope.additional_rentables[new_item.charge_code];
                new_item.additional_code = add_rentable.code;
                new_item.description = add_rentable.description;
                new_item.amount = add_rentable.amount;
            }

            var splice_key = counts_max_key[new_item.code]+1;
            $scope.application.rentable_items.splice(splice_key, 0, new_item);

            $scope.calculateTotalAmount();
        };

        $scope.removeRentableItem = function(key) {
            $scope.application.rentable_items.splice(key,1);
            $scope.calculateTotalAmount();
        }

        $scope.approveApplication = function () {
            $http.post('/api/v1/lease-application/approve/' + $scope.application.id).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error approving application'));
                    return;
                } else {
                    toastr.success('Application was successfully approved');
                    $state.go('main.leaseApplicationManagerView', {id: $scope.application.id});
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.unit_types = [];
        $scope.getUnitTypes = function () {
            if ($scope.property.company.extension_type == 'yardi' && $scope.property.lease_applications_extension_enabled == 1 && $scope.property.lease_application_yardi_unit_types == 1) {
                return;
            }
            $http.post('/api/v1/lease-application/get-unit-types/' + $scope.application.property_id).success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.unit_types = data.data.unit_types;
                // add default unit type if not set
                if ($scope.application.unit_type_id == 0) {
                    $scope.application.unit_type_id = $scope.unit_types[0]?.id;
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }

        $scope.show_floorplans = 0;
        $scope.floorplans = [];
        $scope.getFloorplans = function(){
            if ($scope.property.company.extension_type != 'yardi' || $scope.property.lease_applications_extension_enabled != 1 || $scope.property.lease_application_yardi_unit_types ==0) {
                return;
            }
            $http.post('/api/v1/lease-application/get-floorplans/' + $scope.property.id).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.floorplans = data.data.floorplans;
                $scope.show_floorplans = 1;
                $scope.getAvailableUnits();
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.available_units = [];
        $scope.getAvailableUnits = function() {
            if ($scope.show_floorplans == 0) {
                return;
            }
            $http.post('/api/v1/lease-application/get-floorplan-units/' + $scope.application.property_id+'/'+$scope.application.floorplan, $scope.application).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.available_units = data.data.units;

                if ($scope.application.status != 'open' && $scope.application.status != 'review') {
                    return;
                }

                if ($rootScope.authUser.permissions == 'applicant' && $scope.property.lease_application_allow_update_unit == 0) {
                    return;
                }

                var found = false;
                var is_unit_selected = $scope.application.unit != '';
                angular.forEach($scope.available_units, function(value, key) {
                    if (value == $scope.application.unit) {
                        found = true;
                    }
                });
                if (!found) {
                    //$scope.application.unit = Object.keys($scope.available_units)[0];
                    // no unit
                    $scope.application.unit = '';
                    if (is_unit_selected) {
                        toastr.error($scope.t('unit_unavailable'));
                    }
                }
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        //questions
        vm.keyDepositChecked = true;
        $scope.keyDepositChecked = function() {
            if(vm.keyDepositChecked) {
                $scope.application.key_deposit_count = 1;
            }else{
                $scope.application.key_deposit_count = 0;
            }

            $scope.calculateTotalAmount();
        }

        $scope.addKeyDeposit = function() {
            vm.keyDepositChecked = true;
            if($scope.application.key_deposit_count === 0){
                $scope.application.key_deposit_count = 1;
            }
            
            $scope.application.key_deposit_count++;
            $scope.calculateTotalAmount();
        };

        $scope.removeKeyDeposit = function() {
            $scope.application.key_deposit_count--;
            $scope.calculateTotalAmount();
        };

        $scope.addLaundryDeposit = function() {
            $scope.application.laundry_deposit_count++;
            $scope.calculateTotalAmount();
        };

        $scope.removeLaundryDeposit = function() {
            $scope.application.laundry_deposit_count--;
            $scope.calculateTotalAmount();
        };

        $scope.chequeTooltipClick = function(){
            toastr.info('Please enter the exact digits that you see on the bottom of your cheque as shown in the image or as shown on your online banking account. If you require help to link your account, please send us a <a target="_blank" href="https://payquad.com/support-centre/">support ticket</a> and someone will be happy to help.');
        };

        $scope.getRentableItemItems = function () {
            if ($scope.property.lease_application_select_rentable_item_items == 0) {
                return;
            }
            var url = '/api/v1/lease-application/get-rentable-item-items/' + $scope.application.property_id;
            $http.post(url, {application_id: $scope.application.id}).success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.rentable_item_items = data.data.items;
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.showMessages = function (type) {
            if (type === 'applicant') {
                toastr.error('Please login using the applicant account to complete their lease application form');
            } else if (type === 'co-applicant') {
                toastr.error('Please login using the co-applicant account to complete their lease application form')
            } else if (type === 'guarantor') {
                toastr.error('Please login using the guarantor account to complete their lease application form');
            }
        }


        $scope.chooseFileMobile = async (doc_type, doc_id) => {
            try {
                $scope.mobileFile = await window.Capacitor.Plugins.FilePicker.pickFiles({
                    limit: 1,
                    readData: true,
                });
                $scope.$apply(function () {
                    $scope.selectedMobileFile = $scope.mobileFile.files[0].data;
                });

                var data = new FormData();
                data.append('file', $scope.selectedMobileFile);
                data.append('file_name',  $scope.mobileFile.files[0].name);

                var url = $rootScope.pqDomain + '/api/v1/lease-application/supporting-document-upload/' + $scope.application.id;
                if ($rootScope.authUser.permissions != 'applicant') {
                    url = $rootScope.pqDomain + '/api/v1/lease-application/manager-supporting-document-upload/' + $scope.application.id;
                }
                url += '/' + $scope.application.applicants_tabs[$scope.activeApplicant].user_id;
                url += '/application/' + doc_id + '/' + doc_type;
                url += '?token=' + pqCachedToken;

                const headers = window.Capacitor.isNativePlatform()
                    ? {
                        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA',
                    }
                    : {
                        'Content-Type': undefined,
                    };

                $http.post(url, data, {transformRequest: angular.identity, headers: headers}).success(function (response) {
                    if (!response.success) {
                        toastr.error(response.errors.message);
                    }
                    $scope.application.applicants_tabs[$scope.activeApplicant].documents = response.data.documents;
                    toastr.success($scope.t('doc_uploaded'));
                }).error(function (data) {
                    var message = '';
                    if ($.type(response) === 'string') {
                        message = response;
                    } else {
                        message = response.message;
                    }

                    toastr.error(message);
                });
            } catch (err) {
                console.error('Error while selecting or reading file:', err);
            }
        };

        $scope.emailChanged = function() {
            if (!$scope.toastVisible) {
                $scope.toastVisible = true;
                toastr.warning($scope.t('email_update_warning'), null, {
                    onHidden: function () {
                        $scope.toastVisible = false;
                    }
                });
            }
        }
    }
}());
