(function () {

    'use strict';

    angular
        .module('app')
        .controller('NavbarController', NavbarController)
        .factory('debounce', ['$timeout','$q', function($timeout, $q) {
            // The service is actually this function, which we call with the func
            // that should be debounced and how long to wait in between calls
            return function debounce(func, wait, immediate) {
              var timeout;
              // Create a deferred object that will be resolved when we need to
              // actually call the func
              var deferred = $q.defer();
              return function() {
                var context = this, args = arguments;
                var later = function() {
                  timeout = null;
                  if(!immediate) {
                    deferred.resolve(func.apply(context, args));
                    deferred = $q.defer();
                  }
                };
                var callNow = immediate && !timeout;
                if ( timeout ) {
                  $timeout.cancel(timeout);
                }
                timeout = $timeout(later, wait);
                if (callNow) {
                  deferred.resolve(func.apply(context,args));
                  deferred = $q.defer();
                }
                return deferred.promise;
              };
            };
          }]);

    function NavbarController($rootScope, $scope, $state, authenticationService, $location, $window, navbarService, messageService, $http, langService, jwtService, helperService, debounce) {
        $scope.isShowingPage = false;
        const domain = window.location.hash;
        const pattern = /^#\/showings\/booking\/\d+$/;
        if (pattern.test(domain)) {
            $scope.isShowingPage = true;
        }

        $scope.t = langService.t('navbar');
        $scope.tsidebar = langService.t('sidebar');
        $scope.newMessages = 0;
        $scope.language = $rootScope.localeButtonString;

          $rootScope.$broadcast('check-new-messages');

        $scope.defaultlogo = 'assets/svg/footer-payquad-logo.svg';
        $scope.defaultcompName = 'Some Company Name';

        $scope.isMobile = helperService.isMobileOrTablet();
        $scope.hideProfilePopup = () => {
            $rootScope.newNavProfilePopupOpened = false;
        }
        $scope.showProfilePopup = () => {
            $rootScope.newNavProfilePopupOpened = true;
        }
        $scope.hideLangPopup = () => {
            $rootScope.langProfilePopupOpened = false;
        }
        $scope.showLangPopup = () => {
            $rootScope.langProfilePopupOpened = true;
        }

        /**
         * Check count of new messages
         */
        $scope.timeoutId = 0;
        $scope.fail_count = 0;

        $rootScope.checkNewMessagesCount = false;

        $scope.showModule = function () {
            if (Boolean(window.Capacitor.isNativePlatform()) && !angular.isUndefined($rootScope.authUser)) {
                return true;
            } else {
                return true;
            }
        }

        $scope.goTo = alias => {
            $state.go('main.ng2', {}, {notify: false}).then(() => {
                $rootScope.isAngular2 = true;
                window.routerSubject.next('companies');
            });
            return false;
        };

        $scope.getClass = function (path) {
            if ($location.path().substr(0, path.length) == path) {
                return "active"
            } else {
                return "";
            }
        };

        $scope.hideNav = function (navBarId) {
            $(navBarId).collapse('hide');
        };

        $scope.pagename = function () {
            if (($location.path() == '/login') || ($location.path() == '/dashboard') || ($location.path() == '/register')) {
                return true;
            } else {
                return false;
            }
        };

        $rootScope.changeAccount = function (id) {

            if ($rootScope.authUser.user_id != id) {
                authenticationService.changeAccount(id).success(function (data) {
                    if (data.success === true) {
                        jwtService.setToken(data.data.access_token, data.data.token_type, data.data.expired_in);
                        $rootScope.getTranslations(data.data.locale, 1);
                        $rootScope.authUser = data.data;
                        $rootScope.$broadcast('check-new-messages');
                        $http.get(pqDomain + '/api/v1/branding').success(function (data) {
                            $rootScope.branding = data.data;
                        });
                        $state.go('main.dashboard');
                    }
                })
            }
        };

        $rootScope.logout = () => {
            console.log('$rootScope.logout', $rootScope.authUser);
            if ($rootScope.authUser && $rootScope.authUser.login_as) {
                window.location.href = '/login-as/leave';
                return;
            }

            if(
                $rootScope.authUser['permissions'] === 'payquad_admin'
                || $rootScope.authUser['permissions'] === 'junior_manager'
                || $rootScope.authUser['permissions'] === 'admin_manager'
            ) {
                window['fireAngularEvent']('pq.sign-out'); //logout logic in the new angular store
                return;
            }

            authenticationService.logout().success(data => {
                if (data.success === true) {
                    mixpanel.track('Log out')
                    mixpanel.reset();

                    $rootScope.authUser = undefined;

                    jwtService.removeToken();
                    //sign out in angular
                    window['fireAngularEvent']('pq.sign-out');

                    // $state.go('main.ng2Login');
                    $rootScope.goToAngular2('login')
                    // window.location.href = '/#/n/login';
                } else if (data.success === false) {
                    alert('logout failed');
                }
            });
            
        };

        $scope.checkNewMessages = function () {
            if ($rootScope.checkNewMessagesCount) {
                return;
            }

            window.Capacitor.Plugins.Network.getStatus().then(status => {
                if (status.connected) {
                    $rootScope.checkNewMessagesCount = true;

                    if (!angular.isUndefined($rootScope.authUser)) {
                        messageService.checkNewMessage().success(function (data) {
                            if (typeof data.data === 'undefined') {
                                if ($scope.fail_count == 0) {
                                    $scope.timeoutId = setTimeout(function () {
                                        $scope.checkNewMessages();
                                    }, 10000);
                                }
                                $scope.fail_count = 1;
                                return;
                            }
                            $scope.fail_count = 0;


                            if (data.data.new_messages != null) {
                                // $rootScope.newMessages = data.data.new_messages;
                                $rootScope.newMessagesEvent(data.data.new_messages);
                                $scope.newMessages = data.data.new_messages;
                                if (data.data.new_messages > 0) {
                                    $("#qmessage").css({"visibility": "visible"});
                                    $("#qmessage").html(data.data.new_messages);
                                } else {
                                    $("#qmessage").css({"visibility": "hidden"});
                                }
                            }

                            if (typeof data.data.logged_out !== 'undefined' || data.code === 403) {
                                $rootScope.authUser = undefined;
                                window.location.reload();
                            }

                            if (typeof data.data.log_out_warning !== 'undefined') {
                                toastr.warning($scope.t('You will soon be logged out due to inactivity'));

                                document.addEventListener('mousemove', $scope.resetTimeout);
                                document.addEventListener('click', $scope.resetTimeout);
                                document.addEventListener('keydown', $scope.resetTimeout);
                            }

                            if (typeof data.data.access_token !== 'undefined') {
                                jwtService.setToken(data.data.access_token, data.data.token_type, data.data.expired_in);
                            }

                            $rootScope.checkNewMessagesCount = false;

                            //call recursive function
                            $scope.timeoutId = setTimeout(function () {
                                $scope.checkNewMessages();
                            }, 10000);
                        });
                    }
                }
            });
        };

        $scope.$on('check-new-messages', function () {
            clearTimeout($scope.timeoutId);
            $scope.checkNewMessages();
        });

        $scope.isMenuItemActiveInMobile = function (number) {

            if (!$scope.isMobile) {
                return false;
            }

            return !$rootScope.activeInMobile.includes(number);
        };

        $scope.resetTimeout = function () {
            console.log('resetTimeout');
            $http.get(pqDomain + '/api/v1/heartbeat').success(function (data) {});
            document.removeEventListener('mousemove', $scope.resetTimeout);
            document.removeEventListener('click', $scope.resetTimeout);
            document.removeEventListener('keydown', $scope.resetTimeout);
        }

    }

}());
