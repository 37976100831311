(function () {

    angular.module('app')
        .factory('myHttpResponseInterceptor', ['$q', '$location', '$rootScope', 'jwtService', '$injector', function ($q, $location, $rootScope, jwtService, $injector) {
            return {
                request: function (config) {
                    const isCordova = window.Capacitor.isNativePlatform() ? true : false;
                    if (isCordova) {
                        const token = jwtService.getToken();
                        // console.log('interceptor token: ' + token, config);
                         if(token){
                            config.headers['Authorization'] = 'Bearer ' + token;
                        }
                    }
                    return config;
                },

                response: function (response) {
                    return response;
                },

                responseError: function (rejection) {
                    if (rejection.status === 400 && rejection.data.message === 'OPT_INSTALLATION_REQUIRED') {
                        // $location.url('/account');
                        $rootScope.goToAngular2('2fa/setup');

                    }
                    if (rejection.status === 400 && rejection.data.message === 'OPT_REQUIRED') {
                        $rootScope.goToAngular2('2fa');
                    }
                    if (rejection.data && rejection.data.message === 'Token Expired Exception'){
                        console.log(rejection, 'token expired erro');
                        toastr.error('Please Click Here To Log In Again', 'Your session has expired', {
                            timeOut: 0,
                            extendedTimeOut: 0,
                            preventDuplicates: true,
                            tapToDismiss: false,
                            closeButton: true,
                            onclick: () => {
                                console.log('Your session has expired clicked');
                                let stateService = $injector.get('$state');
                                $rootScope.authUser = undefined;
                                // $location.path('/login');
                                // stateService.go('main.login');
                                $rootScope.goToAngular2Login();

                            }
                        });
                        let stateService = $injector.get('$state');
                        $rootScope.authUser = undefined;
                        window['fireAngularEvent']('pq.session-expired');
                        // stateService.go('main.login');
                        $rootScope.goToAngular2Login();

                        return $q.reject(rejection);
                    }
                    if (rejection.status == 475) {
                        if (rejection.data.redirect) {
                            $location.path(rejection.data.redirect);
                            //$location.path('/dashboard');
                            //$state.go(rejection.data.redirect, { redirect : true });
                        }

                        if (rejection.data.message) {
                            toastr.error(rejection.data.message);
                        }

                        rejection.data.stopaction = 1;
                        return $q.reject(rejection);
                    } else if (rejection.status === 493) {
                        toastr.error('You may not modify this information when logged in as a tenant.');
                        $rootScope.showLoader = false;
                    } else if (rejection.status === 401) {
                        console.log(rejection, '401 error');
                        if (typeof $rootScope.session_expired_count !== 'undefined' && $rootScope.session_expired_count === 1) {
                            toastr.error('Your session has expired. Please log in again.', '', {
                                preventDuplicates: true,
                                onclick: () => {
                                    console.log('Your session has expired clicked');
                                    let stateService = $injector.get('$state');
                                    $rootScope.authUser = undefined;
                                    // $location.path('/login');
                                    // stateService.go('main.login');
                                    $rootScope.goToAngular2Login();

                                }
                            });
                            $rootScope.authUser = undefined;
                            // $location.path('/login');
                            $rootScope.goToAngular2Login();

                            return $q.reject(rejection);
                        }
                        $rootScope.session_expired_count = 1;
                        return $q.reject(rejection);
                    }
                    $rootScope.session_expired_count = 0

                    return $q.reject(rejection);
                }
            };
        }])
        //Http Intercpetor to check auth failures for xhr requests
        .config(['$httpProvider', function ($httpProvider) {
            $httpProvider.interceptors.push('myHttpResponseInterceptor');
        }]);


    angular.module('app').factory('preventTemplateCache', ['$q', '$location', '$rootScope', function ($q, $location, $rootScope) {
        return {

            request: function (config) {
                if (config.url.indexOf('html') !== -1 && config.url.indexOf('scripts') !== -1) {
                    if (typeof $rootScope.html_version != 'undefined') {
                        config.url = config.url + '?t=' + $rootScope.html_version;
                    }
                }

                config.headers['Accept'] = 'application/json;odata=verbose';

                return config;
            }
        };
    }])
    //Http Intercpetor to check auth failures for xhr requests
        .config(['$httpProvider', function ($httpProvider) {
            $httpProvider.interceptors.push('preventTemplateCache');
        }]);

}());
