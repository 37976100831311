(function () {

    'use strict';

    angular
        .module('app')
        .controller('TenantEditController', TenantEditController);

    function TenantEditController($scope, $state, resolvedTenantData, tenantService, propertyService, $http, $rootScope, $timeout, resolvedTenants) {

        var vm = this;
        $scope.isCordova = Boolean(window.Capacitor.isNativePlatform());
        vm.tenant = resolvedTenantData.tenant.data;
        vm.property = resolvedTenantData.tenant.data.property;
        vm.units = [];
        if (vm.tenant.deleted_at !== null) {
            vm.tenant.deleted_at = 'disabled';
        } else {
            vm.tenant.deleted_at = 'enabled';
        }
        vm.tenant.user.originalEmail = vm.tenant.user.email;
        vm.scheduleId = 0;
        vm.amenity_bookings = [];
        if (!vm.tenant.lease_information) {
            vm.tenant.lease_information = {
                start_date: '',
                end_date: '',
                auto_renew: 0,
                due_date: '',
                charges: [],
                documents: []
            };
        }
        $scope.newDocument = {description: '', name: ''};
        $scope.lease_application = null;
        if (vm.tenant.user.lease_applications.length) {
            $scope.lease_application = vm.tenant.user.lease_applications[0];
        }
        $scope.dueDays = [];
        for(let i = 1; i <= 31; i++){
            $scope.dueDays.push(i);
        }
        $scope.date = new Date();

        $scope.tenants = resolvedTenants;
        $scope.linkedModel = {
            m: ''
        };
        $scope.linkedModelSelected = item => {
            if(!vm.tenant.linked.find(tenant => +tenant.id === +item.id)){
                vm.tenant.linked.push(item);
            }
            $scope.linkedModel.m = '';
        };
        $scope.toDelete = [];
        $scope.removeLinkedAccount = index => {
            $scope.toDelete.push(vm.tenant.linked[index].id);
            vm.tenant.linked.splice(index, 1)
        };

        $scope.filterTenants = search => {
            return $scope.tenants.filter(item => {
                const name = `${item.first_name} ${item.last_name}`.toLowerCase();
                return ~name.indexOf(search.toLowerCase());
            })
        };

        $timeout(() => {
            $('#lease_start_date, #lease_end_date').datepicker();
        }, 500);

        $scope.addCharge = (editableLease) => {
            editableLease.charges.push({type: '', amount: ''});
        };

        $scope.deleteCharge = (index, editableLease) => {
            editableLease.charges.splice(index, 1);
        };

        $scope.deleteDocument = index => {
            vm.tenant.lease_information.documents.splice(index, 1);
        };

        vm.getAmenityBookings = function () {
            $http.post('/api/v1/amenity/booking/list/' + vm.tenant.id).success(function (data) {
                if (!data.success) {
                    return;
                }
                vm.amenity_bookings = data.data;
            });
        };
        vm.getAmenityBookings();

        $scope.cancelBooking = function(index){
            if(!confirm('Are you sure you want to cancel this Amenity Booking?')) {
                return;
            }

            $http.post('/api/v1/amenity/booking/cancel/' + vm.amenity_bookings[index].id).success(function(data) {
                if (!data.success) {
                    toastr.error('Error cancelling booking');
                }

                vm.amenity_bookings.splice(index, 1);
            });
        }

        vm.deletePad = function (scheduleId) {
            if (!confirm('Are you sure you want to stop this monthly schedule?'))
                return false;
            vm.scheduleId = scheduleId;
            $http.delete('/api/v1/payments/payment-schedules/' + scheduleId).success(function (data) {
                if (!data.success)
                    toastr.error(data.errors);
                else {
                    var schedules = [];
                    for (var i = 0; i < vm.tenant.payment_schedules.length; i++)
                        if (vm.tenant.payment_schedules[i].id != vm.scheduleId)
                            schedules.push(vm.tenant.payment_schedules[i]);
                    vm.tenant.payment_schedules = schedules;
                    toastr.success('Schedule Deleted');
                }
            });
        };

        $scope.confirmDisable = () => {
            if(vm.tenant.deleted_at == 'enabled'){
                return;
            }
            if(!confirm("Are you sure you want to disable this tenant? This will disable all payment schedules associated with this tenant. \nClick the update button to save all changes.")){
                vm.tenant.deleted_at = 'enabled';
                return;
            }
        }

        var f2, r2, data;
        vm.updateTenant = function (tenantId) {

            f2 = document.getElementById('file').files[0];
            const leaseDocument = document.getElementById('document').files[0];

            let isChargeInvalid = false;
            vm.tenant.lease_information.charges.forEach(item => {
                if (!item.type.length || !item.amount.length) {
                    isChargeInvalid = true;
                }
            });
            if (isChargeInvalid) {
                toastr.error('Some of the lease charges are incorrect.');
                return;
            }

            data = {
                first_name: vm.tenant.first_name,
                last_name: vm.tenant.last_name,
                phone_number: vm.tenant.phone_number,
                work_number: vm.tenant.work_number,
                cell_number: vm.tenant.cell_number,
                fax_number: vm.tenant.fax_number,
                status: vm.tenant.deleted_at,
                unit: vm.tenant.property_unit.unit,
                insurance: vm.tenant.insurance,
                suspended: vm.tenant.suspended,
                // lease_information: vm.tenant.lease_information,
                lease_informations: vm.tenant.lease_informations,
                linked: vm.tenant.linked,
                do_not_disable: vm.tenant.do_not_disable,
                is_late_payment_notification: vm.tenant.is_late_payment_notification,
                monthly_payment_notification: vm.tenant.monthly_payment_notification,
                vehicles: vm.tenant.user.vehicles,
                linked_accounts_to_delete: $scope.toDelete,
            };
            if (vm.tenant.extension_id) {
                data.extension_id = vm.tenant.extension_id;
            }

            if ($rootScope.authUser.permissions === 'payquad_admin' || vm.tenant.user.originalEmail.indexOf('@payquad.com') > 0 || $rootScope.authUser.can_edit_tenant_email == 1) {
                data.email = vm.tenant.user.email;
            }

            if (f2 || (data.insurance != null && (data.insurance.provider || data.insurance.policy || data.insurance.start_date || data.insurance.expiry_date || data.insurance.coverage_amount))) {
                //if they upload form, validate that they are filling in all insurance fields as well
                if (data.insurance == null) {
                    toastr.error('When uploading an insurance document, please fill out all of the insurance information fields');
                    return;
                }

                if (data.insurance.provider == '' || data.insurance.policy == '' || data.insurance.start_date == '' || data.insurance.expiry_date == '' || data.insurance.start_date == '' || data.insurance.expiry_date == '0000-00-00' || data.insurance.start_date == '0000-00-00' || !data.insurance.expiry_date || !data.insurance.start_date || data.insurance.expiry_date == '' || data.insurance.coverage_amount == '') {
                    toastr.error('When uploading an insurance document, please fill out all of the insurance information fields');
                    return;
                }
            }

            if (leaseDocument) {
                if (!$scope.newDocument.description.length || !$scope.newDocument.name.length) {
                    toastr.error('Fill out a description and name of uploaded lease document.');
                    return;
                }
            }

            const insurancePromise = new Promise((resolve, reject) => {
                if (f2) {
                    data.insurance.filename = f2.name;
                    r2 = new FileReader();
                    r2.readAsDataURL(f2);
                    r2.onloadend = function (e) {
                        data.insurance.file = e.target.result;
                        resolve();
                    };
                } else {
                    resolve();
                }
            });

            const documentPromise = new Promise((resolve, reject) => {
                if (leaseDocument) {
                    $scope.newDocument['filename'] = leaseDocument.name;
                    const fr = new FileReader();
                    fr.readAsDataURL(leaseDocument);
                    fr.onloadend = function (e) {
                        $scope.newDocument['file'] = e.target.result;
                        data['newDocument'] = $scope.newDocument;
                        resolve();
                    };
                } else {
                    resolve();
                }
            });

            Promise.all([insurancePromise, documentPromise]).then(() => {
                $scope.update(tenantId, data);
            });

        };

        $scope.update = (tenantId, data) => {
            $http.put('/api/v1/tenants/' + tenantId, data)
                .success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors);
                    } else {
                        $state.go('main.tenants.manage');
                        toastr.success('Resident Account Updated');
                    }
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                    toastr.error('Error saving changes');
                });
        };

        vm.sendPasswordReset = function () {
            $http.post('/api/v1/remind-by-admin', {email: vm.tenant.user.originalEmail});
            toastr.success('A password reset email was sent to ' + vm.tenant.user.originalEmail);

        };
        vm.sendWelcome = function () {
            $http.post('/api/v1/welcome-by-admin', {email: vm.tenant.user.originalEmail});
            toastr.success('A welcome email was sent to ' + vm.tenant.user.originalEmail);

        };

        $scope.addParking = () => {
            vm.tenant.user.vehicles.push({
                id: null,
                parking_spot_number: '',
                colour: '',
                make: '',
                model: '',
                parking: vm.tenant.property.parking_options.length > 0
                    ? vm.tenant.property.parking_options[0].name
                    : '',
                plate: '',
                state: '',
            })
        }

        $scope.removeVehicle = (i) => {
            vm.tenant.user.vehicles.splice(i, 1)
        }

        $scope.editableLease = {};
        $scope.editLease = (l) => {
            console.log(l)
            $scope.editableLease = {...l};
            $('#lease-edit').appendTo('body').modal('show');
        }
        $scope.saveLease = () => {
            console.log(vm.tenant.lease_informations)
            if (!$scope.editableLease.id) {
                if (!$scope.editableLease.start_date.length || !$scope.editableLease.end_date.length) {
                    toastr.error('Fill out lease start and end dates.');
                    return;
                }
            }
            vm.tenant.lease_informations = vm.tenant.lease_informations.map(lease => {
                console.log(lease, $scope.editableLease)
                if ($scope.editableLease.id === lease.id) {
                    lease = $scope.editableLease;
                }
                return lease;
            });
            // if (leaseDocument) {
            //     if (!$scope.newDocument.description.length || !$scope.newDocument.name.length) {
            //         toastr.error('Fill out a description and name of uploaded lease document.');
            //         return;
            //     }
            // }
            $('#lease-edit').modal('hide');
        }

        $scope.openChangeUnitModal = () => {
            if(!(vm.properties && vm.properties.length))
                $scope.getProperties();
            $('#change-unit-modal').appendTo('body').modal('show');
        }

        $scope.getProperties = () => {
            $http.get(`api/v1/tenants/get-dropdown-properties`).then((data)=>{
                vm.properties = data.data;
            });
        }

        $scope.getUnits = (property_id) => {
            $http.get(`api/v1/tenants/${property_id}/units`).then((data)=>{
                vm.units = data.data;
            });
        }

        $scope.changeUnit = () => {
            if(!confirm('Are you sure you want to move this tenant to a new unit?\n\nThis action will disable this tenant and create a new tenant. Any current payment schedules will be deactivated. You will need to set up new schedules.\nExtension code will be cleared.\nPayment methods will be transferred.')){
                return;
            }
            $http.post(`api/v1/tenants/${vm.tenant.id}/change-unit`, {'property_id':vm.new_property, 'property_unit_id':vm.new_unit}).then((data)=>{
                toastr.success('Unit transfer successful');
                $('#change-unit-modal').modal('hide');
                $state.go('main.tenantEdit', {tenantId: data.data.id}, {reload: true});
            }, (error)=>{
                toastr.error('Error transferring unit');
            });
        }

        $scope.doFocus = function (field_id) {
            $('#' + field_id).focus();
        };

        $scope.loginAsV2Loading = false;
        $scope.loginAsV2 = function () {
            if ($scope.loginAsV2Loading) {
                return;
            }
            $scope.loginAsV2Loading = true;

            $http.post('/api/v1/tenants/' + vm.tenant.id + '/login-as').success(function (data) {
                $scope.loginAsV2Loading = false;

                if (data.success) {
                    //const host = window.location.origin == 'https://dev.payquad.com' || window.location.origin == 'https://stage.payquad.com' ? window.location.origin : 'https://portal.payquad.com'
                    const allowedHosts = ['https://dev.payquad.com', 'https://stage.payquad.com', 'https://payquad.sdhub.ca'];
                    const host = allowedHosts.includes(window.location.origin) ? window.location.origin : 'https://portal.payquad.com';
                    window.open(host + data.data.redirect_path, '_blank');
                }
            }).error(function (data) {
                $scope.loginAsV2Loading = false;
                toastr.error('Error logging in');
            });
        }

        $scope.addLeaseInformation = ($event) => {
            $('#lease-edit').appendTo('body').modal('show');
            const newLease = {
                start_date: '',
                end_date: '',
                auto_renew: 0,
                due_date: 1,
                charges: [],
                documents: []
            };
            $scope.editableLease = newLease;
            vm.tenant.lease_informations.push(newLease);
        }
    }

}());
