(function() {
    'use strict';

    angular
    .module('app')
    .controller('LeaseApplicationManagerSignController', leaseApplicationManagerSignController);

    function leaseApplicationManagerSignController($scope,$http,$state,$rootScope,$timeout,$stateParams,$location, langService) {
        $scope.t = langService.t('lease_app');

        if ($rootScope.authUser.permissions == 'tenant' || $rootScope.authUser.permissions == 'applicant') {
            $state.go('main.leaseApplicationList');
        }

        $scope.isCordova = Boolean(window.Capacitor.isNativePlatform());
        $scope.pdfFile = null;
        $scope.scaleCurrent = 1;

        $scope.page_numbers = [1];
        $scope.signature_box_jump_locations = [];
        $scope.signature_box_jump_current = 0;
        $scope.signature_box_jump_ids = [];
        $scope.signature_box_jump_ids_current = 0;

        $scope.application = {
            id : $stateParams.id,
        };

        $scope.signature_provided = 0;

        $scope.getApplication = function(){
            $http.post('/api/v1/lease-application/manager-get/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,$scope.t("Error loading application"));
                    return;
                }

                if (data.data.application.status != 'accepted' && data.data.application.status != 'signed') {
                    $state.go('main.leaseApplicationList');
                    return;
                }

                $scope.application = data.data.application;
                $scope.getProvidedLeaseDocuments();
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };
        $scope.getApplication();

        $scope.show_dropzone = 0;
        $scope.provided_lease_documents = [];
        $scope.required_provided_lease_documents = 0;
        $scope.supporting_lease_documents = [];
        $scope.getProvidedLeaseDocuments = function(){
            $http.post('/api/v1/lease-application/manager-get-provided-lease-documents-for-application/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    return;
                }

                $scope.provided_lease_documents = data.data.provided_lease_documents;
                $scope.supporting_lease_documents = data.data.supporting_lease_documents;

                angular.forEach($scope.provided_lease_documents, function(doc, key){
                    if (doc.required == 1) {
                        $scope.required_provided_lease_documents++;
                    }
                });

                if ($scope.provided_lease_documents.length > 0) {
                    $timeout(function () {
                        $scope.initDropzone();
                        $scope.show_dropzone = 1;
                    }, 0);
                }
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }

        $scope.openProvidedDocument = async function (doc) {
            var url = '/lease-application-provided-document-download/' + doc.id;
            if (window.Capacitor.isNativePlatform()) {
                    try {
                        toastr.info('Downloading file...');
                        const {Filesystem, CapacitorHttp, FileOpener} = window.Capacitor.Plugins;
                        const pdfUrl = pqDomain + url + '?cordova=1&token=' + pqCachedToken;

                        try {
                            // Download the PDF file as a blob
                            const response = await CapacitorHttp.get({
                                url: pdfUrl,
                                responseType: 'blob'
                            });

                            console.log(response.headers)
                            const contentType = response.headers['content-type'] || response.headers['Content-Type'] || '';
                            console.log(contentType)
                            console.log(contentType.split('/'))

                            const fileExtension = contentType.split('/').pop(); // Determine file extension from content type

                            const file = await Filesystem.writeFile({
                                path: `Payquad/lease-applications/supporting_document_${doc.id}.${fileExtension}`,
                                data: response.data,
                                directory: 'DOCUMENTS',
                                recursive: true
                            });

                            await FileOpener.open({
                                filePath: file.uri,
                                contentType: contentType
                            });

                        } catch (error) {
                            console.error('Unable to download or preview PDF:', error);
                        }
                    } catch (e) {
                        alert(e)
                    }
            } else {
                window.open(url, '_blank');
            }
        };

        $scope.deleteDocument = function (document_id) {
            if (!confirm($scope.t('Are you sure you want to delete this document?'))) {
                return;
            }

            var url = '/api/v1/lease-application/delete-document/' + $scope.application.id + '/' + document_id;
            if ($rootScope.authUser.permissions != 'applicant') {
                url = '/api/v1/lease-application/manager-delete-document/' + $scope.application.id + '/' + document_id;
            }
            $scope.loading_spinner = 1;
            $http.post(url).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error($scope.t('Error deleting document'));
                    return;
                }
                $scope.supporting_lease_documents = data.data.documents;
                toastr.success($scope.t('Document Deleted'));
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }

        $scope.openDocument = async function (doc) {
            var url = '/lease-application-document-download/' + doc.id;
            if (window.Capacitor.isNativePlatform()) {
                try {
                    toastr.info('Downloading file...');
                    const {Filesystem, CapacitorHttp, FileOpener} = window.Capacitor.Plugins;
                    const pdfUrl = pqDomain + url + '?cordova=1&token=' + pqCachedToken;

                    try {
                        // Download the PDF file as a blob
                        const response = await CapacitorHttp.get({
                            url: pdfUrl,
                            responseType: 'blob'
                        });

                        console.log(response.headers)
                        const contentType = response.headers['content-type'] || response.headers['Content-Type'] || '';
                        console.log(contentType)
                        console.log(contentType.split('/'))

                        const fileExtension = contentType.split('/').pop(); // Determine file extension from content type

                        const file = await Filesystem.writeFile({
                            path: `Payquad/lease-applications/supporting_document_${doc.id}.${fileExtension}`,
                            data: response.data,
                            directory: 'DOCUMENTS',
                            recursive: true
                        });

                        await FileOpener.open({
                            filePath: file.uri,
                            contentType: contentType
                        });

                    } catch (error) {
                        console.error('Unable to download or preview PDF:', error);
                    }
                } catch (e) {
                    alert(e)
                }
            } else {
                window.open(url, '_blank');
            }
        };

        $scope.initDropzone = function () {
            if ($scope.application.property.lease_application_documents_old_format == 1) {
                $scope.doDropzone('support', 0);
            } else {
                angular.forEach($scope.provided_lease_documents, function (provided_doc, key) {
                    $scope.doDropzone('provided', provided_doc.id);
                });
            }
        };

        $scope.doDropzone = function (doc_type, doc_id) {
            var headers = {};
            if(!!window.Capacitor.isNativePlatform()) {
                headers = {
                    'Cache-Control': null,
                    'X-Requested-With': null,
                }
            }

            var dropzone_key = '#real-dropzone-' + doc_id;
            if (doc_type == 'provided') {
                dropzone_key = '#real-dropzone-provided-' + doc_id;
            }
            $(dropzone_key).dropzone({
                uploadMultiple: false,
                parallelUploads: 1,
                maxFilesize: 8,
                addRemoveLinks: false,
                dictRemoveFile: 'Remove',
                dictFileTooBig: 'File is bigger than 8MB',
                headers: headers,
                url: files => {
                    var url ='/api/v1/lease-application/supporting-document-upload/' + $scope.application.id;
                    if ($rootScope.authUser.permissions != 'applicant') {
                        url = '/api/v1/lease-application/manager-supporting-document-upload/' + $scope.application.id;
                    }
                    url += '/' + $scope.application.user_id;
                    url += '/lease/' + doc_id + '/' + doc_type;
                    if(!!window.Capacitor.isNativePlatform()) {
                        url = pqDomain + url + '?cordova='+(!!window.Capacitor.isNativePlatform()? '1':'0') + '&token=' + pqCachedToken;
                    }
                    return url;
                },

                // The setting up of the dropzone
                error: function (file, response) {
                    var message = '';
                    if ($.type(response) === 'string') {
                        message = response; //dropzone sends it's own error messages in string
                    } else {
                        message = response.message;
                    }

                    toastr.error(message);

                    var _ref;
                    return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                },
                success: function (file, response, done) {

                    if (!response.success) {
                        toastr.error(response.errors.message);
                        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                    }
                    $scope.$apply(function () {
                        $scope.supporting_lease_documents = response.data.documents;
                    });
                    toastr.success($scope.t('Document has been uploaded'));
                    var _ref;
                    return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                },
                uploadprogress: function (file, progress, bytesSent) {
                    $('#instruction').html('Uploading ' + file.name);
                }
            });
        }

        $scope.goToNextSignature = function(){
            var containerDiv = $('#lease-application-pdf-div');
            var div_id = $scope.signature_box_jump_ids[$scope.signature_box_jump_ids_current];
            var targetDiv = $('#'+div_id);

            containerDiv.animate({
                scrollTop: targetDiv.offset().top - containerDiv.offset().top + containerDiv.scrollTop()
            }, 1000);

            if ($scope.signature_box_jump_ids.length == $scope.signature_box_jump_ids_current+1) {
                $scope.signature_box_jump_ids_current=0;
            } else {
                $scope.signature_box_jump_ids_current++;
            }
        };

        $scope.goToNextSignatureOld = function(){
            var next = $scope.signature_box_jump_locations[$scope.signature_box_jump_current];
            var elmSizing = document.getElementById('lease-application-pdf-div') ? document.getElementById('lease-application-pdf-div').getBoundingClientRect() : {width: 0};

            var toppixels = (document.getElementById('lease-application-pdf-div').scrollHeight/10000) * next;
            var leftpixels = !!window.Capacitor.isNativePlatform()? document.getElementById('lease-application-pdf-div').scrollWidth - (elmSizing.width * 1.5) : 0;
            document.getElementById('lease-application-pdf-div').scrollTo({
                top: toppixels,
                left: leftpixels,
                behavior: 'smooth'
            });

            if ($scope.signature_box_jump_locations.length == $scope.signature_box_jump_current+1) {
                $scope.signature_box_jump_current=0;
            } else {
                $scope.signature_box_jump_current++;
            }
        };

        $scope.submitSignature = function(){
            if ($scope.application.property.lease_application_documents_old_format == 1) {
				if ($scope.required_provided_lease_documents > 0 && $scope.required_provided_lease_documents > $scope.supporting_lease_documents.length) {
                    toastr.error('You must download, fill out and re upload all provided documents before you can submit');
                    return;
                }
            } else {
                var required_docs_uploaded = 1;
                $scope.provided_lease_documents.forEach(function(doc, key){
                    if (doc.required == 1) {
                        var has_doc = 0;
                        $scope.supporting_lease_documents.forEach(function(support_doc, support_key){
                            if (support_doc.supporting_document_type == 'provided' && support_doc.supporting_document_id == doc.id) {
                                has_doc = 1;
                                return;
                            }
                        });
                        if (has_doc == 0) {
                            required_docs_uploaded = 0
                            return;
                        }
                    }
                });

                if (required_docs_uploaded == 0) {
                    toastr.error($scope.t('You must download, fill out and re upload all required provided documents before you can submit'));
                    return;
                }
            }
			if (!confirm($scope.t('review_and_confirm_signed_lease'))) {
                return;
            }

            // Send data to server instead...
            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/submit-manager-signature/' + $scope.application.id).success(function(data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors,$scope.t("Error submitting signature"));
                    return;
                }

                toastr.info($scope.t('Signed Document Submitted'));
                $state.go('main.leaseApplicationList');
                return;
            }).error(function(data){
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.setTab = function(i) {
            $(`.modal-body .active`).removeClass('active');
            $(`#li`+i).addClass('active');
            $(`#content2, #content3`).hide();
            $(`#content`+i).show();
        }

        $scope.saveSignature = (sign, name) => { 
            if (sign == document.getElementById('blankCanvas').toDataURL('image/png')) {
                toastr.error($scope.t("Signature is blank"));
                return false;
            }

            if ($scope.application.property.lease_application_manager_signature_name == 1 && $scope.signing_as.box.type == 'manager') {
                $scope.signing_as.box.signing_manager_name = name;
                if (!$scope.signing_as.box.signing_manager_name) {
                    toastr.error($scope.t('Please enter your name'));
                    return false;
                }
            }

            var postdata = {
                image_data: sign,
                signing_as: $scope.signing_as
            };
            //console.log(postdata);
            // Send data to server instead...
            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/save-manager-signature/' + $scope.application.id, postdata).success(function(data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors,$scope.t("Error saving signature"));
                    return false;
                }
                toastr.success($scope.t('Signature Saved'));
                //$scope.loadPdfPage($scope.signing_as.box.page);
                $scope.loadPdf(true);
                $scope.signature_provided = 1;

                $scope.signature_boxes[$scope.signing_as.box.page][$scope.signing_as.box.hover_key].has_signature = true;

                return true; //for some reason this is not returning
            }).error(function(data){
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return false;
                }

                toastr.error(data.error.message);
                return false;
            });
            return true;
        };

        $scope.loadPdfPage = function(page){
            pdfjsLib.getDocument($scope.url).promise.then(function(pdfDoc_) {
                $scope.pdfDoc = pdfDoc_;
                $scope.renderPage(page);
            });
        };

        $scope.loadPdf = function(reload){
            if($scope.pdfDoc && !reload) {
                $scope.initPdf();
            } else {
                pdfjsLib.getDocument($scope.url).promise.then(function(pdfDoc_) {
                    $scope.pdfDoc = pdfDoc_;
                    $scope.initPdf();
                });
            }

        };

        $scope.initPdf = function() {
            $scope.page_numbers = [];
            var i;
            for (i = 0;i<$scope.pdfDoc.numPages;i++) {
                $scope.page_numbers.push(i+1);
            }

            $timeout(function(){
                for (i = 0;i<$scope.pdfDoc.numPages;i++) {
                    $scope.renderPage(i+1);
                }
            }, 0);

            // Initial/first page rendering
            $timeout(function(){
                $scope.initClickListeners();
            }, 0);
        }

        $scope.renderPage = function(num) {
            $scope.pageRendering = true;

            $scope.pdfDoc.getPage(num).then(function(page) {
                if (!page) {
                    console.error('Page is undefined.');
                    $scope.pageRendering = false;
                    return;
                }

                var targetDiv = document.getElementById("lease-pdf-" + num);

                var viewport = page.getViewport({ scale: 1 });
                $scope.canvas_div = document.getElementById("lease-application-pdf-div");
                $scope.scale = (!window.Capacitor.isNativePlatform()|| $scope.scaleCurrent <= 1) ? ($scope.canvas_div.clientWidth - 40) / viewport.width : $scope.scaleCurrent;
                viewport = page.getViewport({ scale: $scope.scale });

                targetDiv.style.height = viewport.height + 'px';
                targetDiv.style.width = viewport.width + 'px';

                // Convert the PDF page to an image
                var canvas = document.createElement('canvas');
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                var context = canvas.getContext('2d');
                var renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };

                var renderTask = page.render(renderContext);
                renderTask.promise.then(function() {
                    // Convert canvas to data URL
                    var imageDataUrl = canvas.toDataURL('image/png');

                    var existingImg = document.getElementById('lease-pdf-img-' + num);

                    if (existingImg) {
                        existingImg.src = imageDataUrl;
                    } else {

                        // Create an image element
                        var img = document.createElement('img');
                        img.id = 'lease-pdf-img-' + num;
                        img.src = imageDataUrl;

                        // Append the image to the target div
                        targetDiv.appendChild(img);
                    }

                    $scope.pageRendering = false;

                    if ($scope.pageNumPending !== null) {
                        // New page rendering is pending
                        $scope.renderPage($scope.pageNumPending);
                        $scope.pageNumPending = null;
                    }
                }).catch(function(renderError) {
                    console.error('Error rendering page:', renderError);
                    $scope.pageRendering = false;
                });
            }).catch(function(fetchError) {
                console.error('Error fetching page:', fetchError);
                $scope.pageRendering = false;
            });
        };

        $scope.initClickListeners = function(){
            $http.post('/api/v1/lease-application/get-manager-signature-boxes/' + $scope.application.id).success(function(data) {
                if (!data.success) {
                    return;
                }
                $scope.createClickListeners(data.data);
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.signature_boxes = [];
        $scope.createClickListeners = function(signature_boxes) {
            $scope.signature_boxes = [];
            $scope.signature_box_jump_locations = [];
            angular.forEach(signature_boxes, function(page, key){
                $scope.signature_boxes[page.page] = page.signature_boxes;
                angular.forEach(page.signature_boxes, function(box, boxkey){
                    $scope.signature_box_jump_locations.push(box.scroll_to);
                    $scope.signature_box_jump_ids.push('hover-div-' + page.page + '-' + boxkey);
                    $scope.signature_boxes[page.page][boxkey].hover_top = box.top_left_y * $scope.scale;
                    $scope.signature_boxes[page.page][boxkey].hover_left = box.top_left_x * $scope.scale;
                    $scope.signature_boxes[page.page][boxkey].hover_width = (box.bottom_right_x - box.top_left_x) * $scope.scale;
                    $scope.signature_boxes[page.page][boxkey].hover_height = (box.bottom_right_y - box.top_left_y) * $scope.scale;
                });
            })

            angular.forEach(signature_boxes, function(page, key){
                $("#lease-pdf-" + page.page).off("click").on("click", function(event) {
                    const bbox = event.target.getBoundingClientRect();
                    var layerx = (event.clientX - bbox.left) /$scope.scale;
                    var layery = (event.clientY - bbox.top) /$scope.scale;

                    angular.forEach(page.signature_boxes, function(box, boxkey){
                        if (layerx > box.top_left_x && layerx < box.bottom_right_x && layery > box.top_left_y && layery < box.bottom_right_y) {
                            $scope.signDocument(box, boxkey);
                        }
                    });
                })
            });
        };

        $scope.zoom = function(amount) {
            $scope.scaleCurrent += amount;
            $scope.initPdf();
        }

        $timeout(function(){
            $scope.url = pqDomain + "/lease-application-pdf/" + $scope.application.id + '?for_signing=1&cordova='+(!!window.Capacitor.isNativePlatform()? '1':'0') + '&token=' + pqCachedToken;
            $scope.pdfDoc = null;
            $scope.pageNum = 1;
            $scope.pageRendering = false;
            $scope.pageNumPending = null;
            $scope.scale = 1;
            $scope.scaleCurrent = 1;
            $scope.loadPdf();
        }, 0);

        //signature  pad stuff
        $scope.signRef1 = {};
        
        $scope.signing_as = {
            box: {}
        };

        // $scope.resizeCanvas = function (){
        //     console.log('resizeCanvas')
        //     var canvas = document.getElementById("signature-pad");
        //     // When zoomed out to less than 100%, for some very strange reason,
        //     // some browsers report devicePixelRatio as less than 1
        //     // and only part of the canvas is cleared then.
        //     var ratio =  Math.max(window.devicePixelRatio || 1, 1);

        //     // This part causes the canvas to be cleared
        //     canvas.width = canvas.offsetWidth * ratio;
        //     canvas.height = canvas.offsetHeight * ratio;
        //     canvas.getContext("2d").scale(ratio, ratio);

        //     // This library does not listen for canvas changes, so after the canvas is automatically
        //     // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
        //     // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
        //     // that the state of this library is consistent with visual state of the canvas, you
        //     // have to clear it manually.
        //     $scope.signaturePad.clear();
        // } 

        $scope.signDocument = function(box, hover_key){
            box.hover_key = hover_key;
            $scope.signing_as = {
                box: box
            };
            $scope.signRef1.open();
        };

        $scope.getSignatureModalTitle = () => {
            let titles = {
                'manager': $scope.t('Manager'),
                'applicant': $scope.t('Primary Applicant'),
                'coapplicant': `${$scope.t('Co-Applicant')}  ${$scope.signing_as?.box?.coapplicant?.first_name} ${$scope.signing_as?.box?.coapplicant?.last_name}`,
                'guarantor': `${$scope.t('Guarantor')}  ${$scope.signing_as?.box?.coapplicant?.first_name} ${$scope.signing_as?.box?.coapplicant?.last_name}`
              };
            return titles[$scope.signing_as.box.type] + ' - ' + (typeof $scope.signing_as.box.initials != 'undefined' ? $scope.t('Initial Document') : $scope.t('Sign Document'));
        }
    }
}());
