(function () {

    'use strict';

    angular
        .module('app')
        .controller('ParcelEditController', function ($scope, $timeout, $state, resolvedParcel, parcelService) {
            $scope.parcel = resolvedParcel;
            $scope.isLoading = false;
            $scope.parcel.created_at = moment($scope.parcel.created_at).format('MMMM DD YYYY, h:mm a');
            $scope.signStep = 0;
            if ($scope.parcel.picked_up) {
                $scope.parcel.picked_up = moment($scope.parcel.picked_up).format('MMMM DD YYYY, h:mm a');
            }
            $scope.signRef = {};
            $scope.newSign1 = {
                sign: null,
                name: ''
            };
            $scope.newSign2 = {
                sign: null,
                name: ''
            };
            if($scope.parcel.signatures.length){
                $scope.newSign1.name = $scope.parcel.signatures[0].name;
                $scope.newSign1.sign = `/parcel/sign/${$scope.parcel.id}/1`;
                if($scope.parcel.signatures.length === 2){
                    $scope.newSign2.name = $scope.parcel.signatures[1].name;
                    $scope.newSign2.sign = `/parcel/sign/${$scope.parcel.id}/2`;
                }
            }

            $scope.getSignatureImage = function(url) {
                if (url.includes('data:image')) {
                    return url;
                } else {
                    return pqDomain + url + '?cordova='+(!!window.cordova ? '1':'0') + '&token=' + pqCachedToken;
                }
            }

            $scope.signSave = (sign, name) => {
                if(sign && name.length){
                    if(!$scope.newSign1.sign){
                        $scope.newSign1.sign = sign;
                        $scope.newSign1.name = name;
                    }else{
                        $scope.newSign2.sign = sign;
                        $scope.newSign2.name = name;
                    }
                    return true;
                }else{
                    toastr.error('Name and sign are required.');
                    return false;
                }
            };

            $scope.save = () => {
                $scope.isLoading = true;
                $scope.parcel.newSign1 = $scope.newSign1;
                $scope.parcel.newSign2 = $scope.newSign2;
                parcelService.save($scope.parcel).then(() => {
                    $state.go('main.parcels', {}, {reload: true}).then(() => toastr.success('Parcel was saved successfully.'));
                });
            };

            $scope.openSignPopup = () => {
                $scope.signRef.open();
            };

            $scope.getImageURL = (url) => {
                if (window.Capacitor.isNativePlatform()) {
                    return pqDomain + url + '?token=' + pqCachedToken;
                } else {
                    return url;
                }
            }
        });

}());
