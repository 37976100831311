(function () {

    'use strict';

    angular
        .module('app')
        .factory('jwtService', jwtService);

    jwtService.$inject = ['$q'];
    function jwtService($q) {
        const { Preferences } = window.Capacitor.Plugins;
        const isCordova = Boolean(window.Capacitor.isNativePlatform());
        let cachedToken = '';

        const _setJWTForAJAX = token => {
            if (isCordova) {
                $.ajaxSetup({
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                });
            }
        };

        const _setPreferencesToken = async (token) => {
            try {
                await Preferences.set({ key: 'token', value: token });
                console.log('set capacitor token', token);
            } catch (err) {
                console.log("ERROR SETTING TOKEN IN PREFERENCES", err);
            }
        };

        const _initCordovaToken = async () => {
            try {
                const { value } = await Preferences.get({ key: 'token' });
                if (value) {
                    cachedToken = value;
                    pqCachedToken = value;
                    console.log('init capacitor token', value);
                }
            } catch (error) {
                console.error('ERROR GETTING TOKEN FROM PREFERENCES', error);
            }
        };

        const _removeToken = async () => {
            console.log('remove capacitor token');
            cachedToken = '';
            pqCachedToken = '';
            try {
                await Preferences.remove({ key: 'token' });
            } catch (err) {
                console.error("ERROR REMOVING TOKEN FROM PREFERENCES", err);
            }
        };

        const _setToken = async (token = null, type = 'Bearer', expired = 0) => {
            if (!token) {
                await _removeToken();
            } else {
                cachedToken = token;
                pqCachedToken = token;

                const jwt = {
                    token,
                    type,
                    expired: +new Date() + (expired * 1000)
                };

                const jwtStringify = JSON.stringify(jwt);
                await _setPreferencesToken(token);
            }
        };

        const _getToken = (forceTake) => {
            if (forceTake) {
                return Preferences.get({ key: 'token' })
                    .then(({ value }) => {
                        // console.log('got from preferences!', value);
                        if (value) {
                            return value;
                        }
                    })
                    .catch(error => {
                        console.error('ERROR GETTING TOKEN FROM PREFERENCES', error);
                        return null;
                    });
            }
            return cachedToken;
        };


        return {
            initCordovaToken: _initCordovaToken,
            setToken: _setToken,
            getToken: _getToken,
            removeToken: _removeToken
        };
    }

})();
