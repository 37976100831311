(function() {

	'use strict';

	angular
		.module('app')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/lease-application/';

        $stateProvider
            .state('main.leaseApplicationDashboard', {
                url: '/lease-application/dashboard',
                views: {
                    "body@": {
                        templateUrl: url + 'dashboard/dashboard.html',
                        controller: 'LeaseApplicationDashboardController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationCreateCompanies', {
                url: '/lease-application/companies/create',
                views: {
                    "body@": {
                        templateUrl: url + 'create-companies/create-companies.html',
                        controller: 'LeaseApplicationCreateCompaniesController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationCreatePortfolios', {
                url: '/lease-application/portfolios/create/:companyId',
                views: {
                    "body@": {
                        templateUrl: url + 'create-portfolios/create-portfolios.html',
                        controller: 'LeaseApplicationCreatePortfoliosController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationCreateProperties', {
                url: '/lease-application/properties/create/:companyId',
                views: {
                    "body@": {
                        templateUrl: url + 'create-properties/create-properties.html',
                        controller: 'LeaseApplicationCreatePropertiesController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationCreate', {
                url: '/lease-application/:propertyId/create',
                views: {
                    "body@": {
                        templateUrl: url + 'create/create.html',
                        controller: 'LeaseApplicationCreateController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationInvite', {
                url: '/lease-application/:inviteId/invite/:token',
                views: {
                    "body@": {
                        templateUrl: url + 'invite/invite.html',
                        controller: 'LeaseApplicationInviteController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationContinue', {
                url: '/lease-application/continue/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'continue/continue.html',
                        controller: 'LeaseApplicationContinueController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationSign', {
                url: '/lease-application/sign/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'sign/sign.html',
                        controller: 'LeaseApplicationSignController',
                        controllerAs: 'vm'
                    }
                }
            })
            // manager pages
            .state('main.leaseApplicationManagerComplete', {
                url: '/lease-application/manager-complete/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'manager-complete/manager-complete.html',
                        controller: 'LeaseApplicationManagerCompleteController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationManagerView', {
                url: '/lease-application/manager-view/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'manager-view/manager-view.html',
                        controller: 'LeaseApplicationManagerViewController',
                        controllerAs: 'vm',
                        resolve: {
                            resolvedApplication: function ($q, $stateParams, leaseApplicationService) {
                                return $q.when(leaseApplicationService.get($stateParams.id));
                            }
                        }
                    }
                }
            })
            .state('main.leaseApplicationManagerSign', {
                url: '/lease-application/manager-sign/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'manager-sign/manager-sign.html',
                        controller: 'LeaseApplicationManagerSignController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationConvert', {
                url: '/lease-application/convert/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'convert/convert.html',
                        controller: 'LeaseApplicationConvertController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationList', {
                url: '/lease-application/list',
                views: {
                    "body@": {
                        templateUrl: url + 'list/index.html',
                        controller: 'LeaseApplicationListController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationEdit', {
                url: '/lease-application/edit/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'edit/edit.html',
                        controller: 'LeaseApplicationEditController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.leaseApplicationMessage', {
                url: '/lease-application/messages/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'message/message.html',
                        controller: 'LeaseApplicationMessageController',
                        controllerAs: 'vm'
                    }
                }
            })
			;
	}
}());
