(function () {

    'use strict';

    angular
        .module('app')
        .controller('ParcelIndexController', function ($scope, $state, resolvedParcels) {

            $scope.currentPage = 1;
            $scope.itemsPerPage = 5;
            $scope.parcels = resolvedParcels;
            $scope.totalItems = resolvedParcels.length;

            $scope.edit = id => {
                $state.go('main.parcels.edit', {id});
            };

            $scope.create = () => {
                $state.go('main.parcels.create');
            };
        });

}());
