(function () {

    'use strict';

    angular
        .module('app')
        .controller('MessageIndexController', MessageIndexController)
        .run(['$rootScope', '$state', '$stateParams',
            function ($rootScope, $state, $stateParams) {
                $rootScope.$stateParams = $stateParams;
            }]);

    function MessageIndexController($rootScope, messageService, $log, $http, $scope, langService, $timeout, $location, $stateParams) {
        $scope.t = langService.t('messages');
        if ($rootScope.authUser.permissions !== 'tenant') {
            $rootScope.translations.messages.messages = $rootScope.translations.messages.communication;
        }

        $scope.isNative = false;

        $timeout(function(){
            $scope.isNative = window.Capacitor.isNativePlatform()
        }, 1000);

        var vm = this;
        vm.subString = subString;
        vm.isProspect = 0;
        vm.sentMessages = [];
        vm.inboxMessages = [];
        let allSentMessages = [];
        let allInboxMessages = [];
        let sentTimeoutPromise = null;
        let inboxTimeoutPromise = null;

        $scope.archiveView = $stateParams.filter === 'archived';
        $scope.current_inbox =  $stateParams.filter === 'archived' ? 'archived' : 'inbox';
        $scope.recipient = null;
        $scope.searchText = '';
        $scope.selectedMessages = [];
        $scope.messageSelected = false;
        $scope.selectedMassAction = '---';

        $scope.messagesTags = [];

        $scope.previewMobile = async function (attachment) {
            toastr.info('Downloading file...');
            const {Filesystem, CapacitorHttp, FileOpener} = window.Capacitor.Plugins;
            const pdfUrl = `${pqDomain}/message-attachment/${attachment.id}?token=${pqCachedToken}`;

            try {
                const response = await CapacitorHttp.get({
                    url: pdfUrl,
                    responseType: 'blob'
                });

                const contentType = response.headers['content-type'] || response.headers['Content-Type'] || ''
                console.log(contentType)

                const file = await Filesystem.writeFile({
                    path: `Payquad/maintenance/message_legacy_${attachment.id}_${attachment.name}`,
                    data: response.data,
                    directory: 'DOCUMENTS',
                    recursive: true
                });

                await FileOpener.open({
                    filePath: file.uri,
                    contentType: contentType
                });

            } catch (error) {
                console.error('Unable to download or preview PDF:', error);
            }
        }

        $http.get('/api/v1/messages/tags').success(function (data) {
            if (!data) {
                toastr.error('Error retrieving Tags');
                return;
            }
            $scope.messagesTags = data.data;
        });

        $scope.loadTags = function(query) {
            return $scope.messagesTags.filter(obj => {
                 return obj.name.toLowerCase().includes(query.toLowerCase())
            });
        };

        $scope.loadStatuses = () => ['open', 'read', 'in progress', 'closed'];

        $scope.filterStatus = [];
        $scope.applyFilterStatus = function (status) {
            let stats = $scope.filterStatus.map(el => el.text)
            vm.inboxMessages = allInboxMessages.filter(item => {
                return stats.length === 0 || stats.includes(item[2].ticket?.status);
            });
        };

        $scope.filterTags = [];

        $scope.applyFilterTags = function(tag) {
            let filterTagIds = [];
            $scope.filterTags.forEach(element => filterTagIds.push(element.id))

            $timeout.cancel(inboxTimeoutPromise);
            inboxTimeoutPromise = $timeout(() => {
                vm.inboxMessages = allInboxMessages.filter(item => {
                    const ticket = item[2].ticket;
                    let ticketTagIds = [];

                    if (ticket && Boolean(ticket.tags) && ticket.tags.length > 0) {
                        ticket.tags.forEach(element => {
                            ticketTagIds.push(element.id)
                        })
                    }

                    return filterTagIds.some(element => ticketTagIds.indexOf(element) > -1);
                });
            }, 0);
        };

        $scope.filterSent = () => {
            $timeout.cancel(sentTimeoutPromise);
            sentTimeoutPromise = $timeout(() => {
                const t = $scope.searchText.toLowerCase();
                vm.sentMessages = allSentMessages.filter(item => {
                    return ~item.body.toLowerCase().indexOf(t)
                        || ~item.subject.toLowerCase().indexOf(t)
                        || ~item.created_at.toLowerCase().indexOf(t)
                        || ~(item.to_first_name.toLowerCase() + ' ' + item.to_last_name.toLowerCase()).indexOf(t);
                });
            }, 400);
        };

        $scope.filterInbox = () => {
            $timeout.cancel(inboxTimeoutPromise);
            inboxTimeoutPromise = $timeout(() => {
                const t = $scope.searchText.toLowerCase();
                vm.inboxMessages = allInboxMessages.filter(item => {
                    const msg = item[0];
                    return ~msg.body.toLowerCase().indexOf(t)
                        || ~msg.subject.toLowerCase().indexOf(t)
                        || ~msg.created_at.toLowerCase().indexOf(t)
                        || ~(msg.from_first_name.toLowerCase() + ' ' + msg.from_last_name.toLowerCase()).indexOf(t);
                });
            }, 400);
        };

        /**
         * Display small part of message body
         * @param message - {string}
         * @return string
         */
        function subString(message) {
            let text = (new DOMParser).parseFromString(message, "text/html").documentElement.textContent;
            return sliceByWord(text, 150);
        }

        const sliceByWord = (phrase, length, skipEllipses) => {
            if (phrase.length < length) return phrase
            else {
                let trimmed = phrase.slice(0, length)
                trimmed = trimmed.slice(0, Math.min(trimmed.length, trimmed.lastIndexOf(' ')))
                return skipEllipses ? trimmed : trimmed + '…'
            }
        }

        $scope.isLoading = false;

        $scope.loadMessages = () => {
            $scope.isLoading = true;
            $scope.searchText = '';
            $scope.selectedMessages = [];

            var recipientId = 0;

            if (parseInt($stateParams.recipient)){
                recipientId = parseInt($stateParams.recipient);
            }

            messageService.getAllInboxMessages(vm.isProspect, recipientId, $stateParams.filter)
                .success(function (data) {
                    var tmp = '';
                    var messages = [];
                    vm.inboxMessages = data.data.messages;
                    vm.recipientData = data.data.recipient;

                    //to get last received message
                    vm.inboxMessages.forEach(function (el) {
                        tmp = el.reverse();
                        messages.push([tmp[0], el.length, tmp[tmp.length - 1]]);  // [last message, count, first message]
                    });
                    vm.inboxMessages = messages;
                    allInboxMessages = messages;

                    // console.log(vm.inboxMessages); //you're going to need this to understand whats going on

                    $scope.isLoading = false;
                })
                .error(function (data, status, headers, config) {
                    $scope.isLoading = false;
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $log.log(data.error + ' ' + status);
                });

            messageService.getAllSentMessages(vm.isProspect, recipientId)
                .success(function (data) {
                    vm.sentMessages = data.data.messages;
                    vm.recipientData = data.data.recipient;
                    allSentMessages = data.data.messages;
                })
                .error(function (data, status, headers, config) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $log.log(data.error + ' ' + status);
                });
        };

        $scope.loadMessages();

        vm.isRead = function (val) {
            return val != 0;
        };

        vm.getSender = function (val) {
            if (val == 'system')
                return 'system-msg';
            return 'landlorg-msg';
        };
        $rootScope.$broadcast('check-new-messages');

        $scope.toggleSelectMessage = function(message) {
            if (this.messageSelected){
                this.selectedMessages.push(message.id)
            }else{
                let index = this.selectedMessages.indexOf(message.id);
                this.selectedMessages.splice(index, 1);
            }
        }




        $scope.proceedMassAction = function () {
            if (this.selectedMassAction === 'archive'){
                messageService.setMessagesAsArchived({"ids": this.selectedMessages}).success(() => {
                    $scope.loadMessages();
                });
            } else if (this.selectedMassAction === 'setMessageAsRead') {
                messageService.setMessagesAsRead({"ids": this.selectedMessages}).success(function(){
                    $scope.loadMessages();
                });
            } else if(this.selectedMassAction === 'unarchive') {
                messageService.setMessagesAsUnarchived({"ids": this.selectedMessages}).success(() => {
                    $scope.loadMessages();
                });
            }
        }

        $scope.markAllRead = function() {
            messageService.setAllAsRead().finally(function(){
                $scope.loadMessages();
            });
        }

        $scope.count_data = {
            delivered: [],
            missing: []
        };
        $scope.showCountData = function(count_id) {
            $http.post('/api/v1/messages/get-count-data/' + count_id).success(function (data) {
                if (!data.success) {
                    toastr.error('Could not load recipient data');
                    return;
                }

                $scope.count_data.delivered = data.data.delivered;
                $scope.count_data.missing = data.data.missing;

                $('#count-data-modal').appendTo('body').modal('show');
            });
        };
    }

}());
