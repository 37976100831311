(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        var url = 'scripts/modules/message/';

        $stateProvider
            .state('main.messagesSent', {
                url: '/messages/sent',
                views: {
                    "body@": {
                        templateUrl: url + 'index/message.sent.html',
                        controller: 'MessageIndexController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.messagesTemplatesInfo', {
                url: '/messages/templates',
                views: {
                    "body@": {
                        templateUrl: url + 'index/message.templates.html',
                        controller: 'MessageTemplateController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.messagesScheduled', {
                url: '/n/messages/scheduled',
            })
            .state('main.messageCreate', {
                url: '/messages/create',
                views: {
                    "body@": {
                        templateUrl: url + 'create/message.create.html',
                        controller: 'MessageCreateController'
                    }
                },
            })
            .state('main.messageCreateReply', {
                url: '/messages/create/:reply_message_id',
                views: {
                    "body@": {
                        templateUrl: url + 'create/message.create.html',
                        controller: 'MessageCreateController'
                    }
                },
            })
            .state('main.messagesView', {
                url: '/messages/view/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'view/message.view.html',
                        controller: 'MessageViewController',
                        controllerAs: 'mv'
                    }
                }
            })
            .state('main.messagesDashboardList', {
                url: '/messages/dashboard/list',
                views: {
                    "body@": {
                        templateUrl: url + 'dashboard_list/message.dashboard_list.html',
                        controller: 'MessageDashboardListController',
                        controllerAs: 'mv'
                    }
                }
            })
            .state('main.messagesDashboardCreate', {
                url: '/messages/dashboard/create',
                views: {
                    "body@": {
                        templateUrl: url + 'dashboard_create/message.dashboard_create.html',
                        controller: 'MessageDashboardCreateController',
                        controllerAs: 'mv'
                    }
                }
            })
            .state('main.messagesDashboardEdit', {
                url: '/messages/dashboard/edit/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'dashboard_edit/message.dashboard_edit.html',
                        controller: 'MessageDashboardEditController',
                        controllerAs: 'mv'
                    }
                }
            })
            .state('main.messagesTemplateList', {
                url: '/messages/template/list',
                views: {
                    "body@": {
                        templateUrl: url + 'template_list/message.template_list.html',
                        controller: 'MessageTemplateListController',
                        controllerAs: 'mv'
                    }
                }
            })
            .state('main.messagesTemplateCreate', {
                url: '/messages/template/create',
                views: {
                    "body@": {
                        templateUrl: url + 'template_create/message.template_create.html',
                        controller: 'MessageTemplateCreateController',
                        controllerAs: 'mv'
                    }
                }
            })
            .state('main.messagesTemplateEdit', {
                url: '/messages/template/edit/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'template_edit/message.template_edit.html',
                        controller: 'MessageTemplateEditController',
                        controllerAs: 'mv'
                    }
                }
            })
            .state('main.messagesGroupingList', {
                url: '/messages/grouping/list',
                views: {
                    "body@": {
                        templateUrl: url + 'grouping_list/message.grouping_list.html',
                        controller: 'MessageGroupingListController',
                        controllerAs: 'mv'
                    }
                }
            })
            .state('main.messagesGroupingCreate', {
                url: '/messages/grouping/create',
                views: {
                    "body@": {
                        templateUrl: url + 'grouping_create/message.grouping_create.html',
                        controller: 'MessageGroupingCreateController',
                        controllerAs: 'mv'
                    }
                }
            })
            .state('main.messagesGroupingEdit', {
                url: '/messages/grouping/edit/:id',
                views: {
                    "body@": {
                        templateUrl: url + 'grouping_edit/message.grouping_edit.html',
                        controller: 'MessageGroupingEditController',
                        controllerAs: 'mv'
                    }
                }
            })
            .state('main.messagesUnsentList', {
                url: '/messages/unsent/list',
                views: {
                    "body@": {
                        templateUrl: url + 'unsent_list/message.unsent_list.html',
                        controller: 'MessageUnsentListController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main.messagesInbox', {
                url: '/messages/{filter:archived}',
                views: {
                    "body@": {
                        controller: 'MessageIndexController',
                        controllerAs: 'vm',
                        templateProvider: function ($http, $templateCache) {
                            return $http
                                .get('/api/v1/messages/ticketing-enabled')
                                .then(function (response) {
                                    return response.data.data['ticketing-enabled']
                                        ? url + 'index/message.inbox_advanced.html'
                                        : url + 'index/message.inbox.html';
                                }).then((templateUrl) => {
                                    const tplFromCache = $templateCache.get(templateUrl);

                                    if(tplFromCache){
                                        return tplFromCache;
                                    }

                                    return $http
                                        .get(templateUrl)
                                        .then((response) => {
                                            const tpl = response.data
                                            $templateCache.put(templateUrl, tpl);
                                            return tpl;
                                        });
                                });
                        },
                    }
                },
                params: {
                    filter: {
                        value: null,
                        squash: true
                    },
                },
            })
            .state('main.messagesInboxRecipient', {
                url: '/messages/:recipient',
                views: {
                    "body@": {
                        // templateUrl: url + 'index/message.inbox.html',
                        controller: 'MessageIndexController',
                        controllerAs: 'vm',
                        templateProvider: function ($http, $templateCache) {
                            return $http
                                .get('/api/v1/messages/ticketing-enabled')
                                .then(function (response) {
                                    return response.data.data['ticketing-enabled']
                                        ? url + 'index/message.inbox_advanced.html'
                                        : url + 'index/message.inbox.html';
                                }).then((templateUrl) => {
                                    const tplFromCache = $templateCache.get(templateUrl);

                                    if(tplFromCache){
                                        return tplFromCache;
                                    }

                                    return $http
                                        .get(templateUrl)
                                        .then((response) => {
                                            const tpl = response.data
                                            $templateCache.put(templateUrl, tpl);
                                            return tpl;
                                        });
                                });
                        },
                    }
                }
            })
            .state('main.messagesSentRecipient', {
                url: '/messages/:recipient/sent',
                views: {
                    "body@": {
                        templateUrl: url + 'index/message.sent.html',
                        controller: 'MessageIndexController',
                        controllerAs: 'vm'
                    }
                }
            })
        ;

    }

}());
