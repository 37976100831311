    (function() {

    'use strict';

    angular
    .module('app')
    .controller('MaintenanceRequestEditController', maintenanceRequestEditController)
    .filter('tel', function () {
        return function (tel) {
            if (!tel) { return ''; }

            var value = tel.toString().trim().replace(/^\+/, '');

            if (value.match(/[^0-9]/)) {
                return tel;
            }

            var country, city, number;

            switch (value.length) {
                case 10: // +1PPP####### -> C (PPP) ###-####
                    country = 1;
                    city = value.slice(0, 3);
                    number = value.slice(3);
                    break;

                case 11: // +CPPP####### -> CCC (PP) ###-####
                    country = value[0];
                    city = value.slice(1, 4);
                    number = value.slice(4);
                    break;

                case 12: // +CCCPP####### -> CCC (PP) ###-####
                    country = value.slice(0, 3);
                    city = value.slice(3, 5);
                    number = value.slice(5);
                    break;

                default:
                    return tel;
            }

            if (country == 1) {
                country = "";
            }

            number = number.slice(0, 3) + '-' + number.slice(3);

            return (country + " (" + city + ") " + number).trim();
        };
    });
    ;

    function maintenanceRequestEditController($scope,$http,$state,$stateParams,$rootScope,$interval,$timeout, langService, jwtService, $location, propertyService) {
        $scope.t = langService.t('maint_req');
        $scope.assigned_start_time = '';
        $scope.token = jwtService.getToken();
        $scope.calendar_start_time = '';
        $scope.is_tab_details = true;

        $scope.isNative = false;

        $timeout(function(){
            $scope.isNative = window.Capacitor.isNativePlatform()
        }, 1000);

        const tabId = parseInt(localStorage.getItem('mr-tab-id'), 10);
        if (tabId) {
            $scope.is_tab_details = false;
        }

        $scope.$on('$locationChangeStart', function (event, next, current) {
            if ($scope.is_tab_details && current.match(/\/maintenance-requests\/[0-9]+/)) {
                var answer = confirm($scope.t('unsaved_changes_will_be_lost'));
                if (!answer) {
                    event.preventDefault();
                }
            }
        });

        var imgSrcSanitizationWhitelist = /^\s*(https?|ftp|file):|data:image\//;

        $scope.time_values = [
            {text: '15 ' + $scope.t('minutes'), value: 15},
            {text: '30 ' + $scope.t('minutes'), value: 30},
            {text: '45 ' + $scope.t('minutes'), value: 45},
            {text: '1 ' + $scope.t('hour'), value: 60},
            {text: '1 ' + $scope.t('hour') + ' 15' + $scope.t('minutes'), value: 75},
            {text: '1 ' + $scope.t('hour') + ' 30' + $scope.t('minutes'), value: 90},
            {text: '1 ' + $scope.t('hour') + ' 45' + $scope.t('minutes'), value: 105},
            {text: '2 ' + $scope.t('hours'), value: 120},
            {text: '2 ' + $scope.t('hours') + ' 15' + $scope.t('minutes'), value: 135},
            {text: '2 ' + $scope.t('hours') + ' 30' + $scope.t('minutes'), value: 150},
            {text: '2 ' + $scope.t('hours') + ' 45' + $scope.t('minutes'), value: 165},
            {text: '3 ' + $scope.t('hours'), value: 180},
            {text: '3 ' + $scope.t('hours') + ' 15' + $scope.t('minutes'), value: 195},
            {text: '3 ' + $scope.t('hours') + ' 30' + $scope.t('minutes'), value: 210},
            {text: '3 ' + $scope.t('hours') + ' 45' + $scope.t('minutes'), value: 225},
            {text: '4 ' + $scope.t('hours'), value: 240},
            {text: '4 ' + $scope.t('hours') + ' 15' + $scope.t('minutes'), value: 255},
            {text: '4 ' + $scope.t('hours') + ' 30' + $scope.t('minutes'), value: 270},
            {text: '4 ' + $scope.t('hours') + ' 45' + $scope.t('minutes'), value: 285},
            {text: '5 ' + $scope.t('hours'), value: 300},
            {text: '5 ' + $scope.t('hours') + ' 15' + $scope.t('minutes'), value: 315},
            {text: '5 ' + $scope.t('hours') + ' 30' + $scope.t('minutes'), value: 330},
            {text: '5 ' + $scope.t('hours') + ' 45' + $scope.t('minutes'), value: 345},
            {text: '6 ' + $scope.t('hours'), value: 360},
            {text: '6 ' + $scope.t('hours') + ' 15' + $scope.t('minutes'), value: 375},
            {text: '6 ' + $scope.t('hours') + ' 30' + $scope.t('minutes'), value: 390},
            {text: '6 ' + $scope.t('hours') + ' 45' + $scope.t('minutes'), value: 405},
            {text: '7 ' + $scope.t('hours'), value: 420},
            {text: '7 ' + $scope.t('hours') + ' 15' + $scope.t('minutes'), value: 435},
            {text: '7 ' + $scope.t('hours') + ' 30' + $scope.t('minutes'), value: 450},
            {text: '7 ' + $scope.t('hours') + ' 45' + $scope.t('minutes'), value: 465},
            {text: '8 ' + $scope.t('hours'), value: 480},
        ];

        $scope.mailTo = '';
        $scope.isLoading = true;

        $http.get('/api/v1/maintenance-requests/edit/'+$stateParams.id).success(function(data) {
            $scope.mr = data.data;

            $scope.mr.tenant_responsible = $scope.mr.tenant_responsible === 1 ? 'Yes' : 'No';

            //convert date from sql to MM/DD/YYYY
            if($scope.mr.problem_made_aware_at !== null) {
                var parts = $scope.mr.problem_made_aware_at.split("-");
                $scope.mr.problem_made_aware_at = parts[1] + '/' + parts[2] + '/' + parts[0];
            }
            // calculate time
            $scope.mr.completion_hours = 0;
            if ($scope.mr.completion_minutes > 0) {
                if ($scope.mr.completion_minutes > 60) {
                    $scope.mr.completion_hours = Math.floor($scope.mr.completion_minutes/60);
                    $scope.mr.completion_minutes = $scope.mr.completion_minutes % 60;
                }
            }

            if ($scope.mr.completion_minutes == 0) {
                $scope.mr.completion_minutes = '';
            }
            if ($scope.mr.completion_hours == 0) {
                $scope.mr.completion_hours = '';
            }

            $scope.pos = [];
            $scope.doPo();

            $scope.mr.doneTasksAmount =  $scope.mr.tasks.reduce((sum, current) => sum + (current.checked ? 1 : 0), 0);

            if (typeof $scope.mr.vendors != 'undefined') {
                var vendors = $scope.mr.vendors;
                $scope.mr.vendors = [];

                angular.forEach(vendors, function(vendor, key){
                    $scope.mr.vendors.push(vendor.id);
                });

                $timeout(function(){
                    $('select#vendors').multipleSelect({filter: true, width: 350 });

                    if($scope.mr.assigned_start_time) {
                        $scope.calendar_start_time = moment($scope.mr.assigned_start_time).format('YYYY-MM-DD hh:mm A');
                        $('#start_datetimepicker1').val($scope.calendar_start_time );
                    } else {
                        $scope.calendar_start_time = Date.now();
                    }

                    $('#start_datetimepicker1').daterangepicker({
                        "startDate" : $scope.calendar_start_time,
                        "singleDatePicker": true,
                        "autoUpdateInput": false,
                        "timePicker": true,
                        "autoApply" : true,
                        "timePickerIncrement": 15,
                        locale: {
                            format: 'YYYY-MM-DD hh:mm A'
                        }
                        },
                        function(start, end, label) {
                            if( $scope.calendar_start_time != start.format('YYYY-MM-DD hh:mm A')){
                                $scope.mr.assigned_start_time = start.format('YYYY-MM-DD HH:mm');
                                $scope.calendar_start_time = start.format('YYYY-MM-DD hh:mm A');
                                $('#start_datetimepicker1').val(start.format('YYYY-MM-DD hh:mm A'));
                            }
                        }
                    );
                    if($scope.mr.assigned_start_time) {
                        $scope.calendar_start_time = moment($scope.mr.assigned_start_time).format('YYYY-MM-DD hh:mm A');
                        $('#start_datetimepicker1').val($scope.calendar_start_time );
                    }
                    if ($stateParams.tab){
                        if ($stateParams.tab === 'edit'){
                            $scope.setTab(1)
                        }
                        if ($stateParams.tab === 'messaging'){
                            $scope.setTab(2)
                        }
                        if ($stateParams.tab === 'history'){
                            $scope.setTab(3)
                        }
                        if ($stateParams.tab === 'po'){
                            $scope.setTab(4)
                        }
                    }
                    const tabId = parseInt(localStorage.getItem('mr-tab-id'), 10);
                    if (tabId){
                        $scope.setTab(tabId)
                        localStorage.removeItem('mr-tab-id');
                    }
                    $scope.isLoading = false;
                }, 500);
            } else {
                $scope.isLoading = false;
            }

            if($scope.mr.property.maintenance_request_additional_details){
                $scope.mr.details = $scope.mr.details.replace("Where is the problem?", $scope.t('where_problem'));
                $scope.mr.details = $scope.mr.details.replace('How long has this been going on?', $scope.t('how_long_problem'));
                $scope.mr.details = $scope.mr.details.replace('Has this been a problem in the past?', $scope.t('past_problem'));
            }

            $scope.getDisplayStrings();
            $scope.getMRQuestionResponses();
        });

        $scope.getMRQuestionResponses = () => {
            $http.get('/api/v1/maintenance-requests/questions-with-responses/'+$scope.mr.id).then(function onSuccess(response){
                $scope.mr.additional_questions = response.data.data;
            });
        }

        $scope.getDisplayStrings = () => {
            $http.get('/api/v1/translations/get-display-string/'+ $scope.mr.property.company_id, {
                params: {
                    category: "maintenance_request_subject",
                    en: $scope.mr.subject
                }
            }).then(function onSuccess(data){
                if(data.data){
                    $scope.subject_string = data.data;
                }else{
                    $scope.subject_string = $scope.t($scope.mr.subject) ? $scope.t($scope.mr.subject) : $scope.mr.subject;
                }
            }).catch(function onError(data){
                $scope.subject_string = $scope.t($scope.mr.subject) ? $scope.t($scope.mr.subject) : $scope.mr.subject;
            });

            $http.get('/api/v1/translations/get-display-string/'+ $scope.mr.property.company_id, {
                params: {
                    category: "maintenance_request_priority",
                    en: $scope.mr.display_priority
                }
            }).then(function onSuccess(data){
                if(data.data){
                    $scope.priority_string = data.data;
                }else{
                    $scope.priority_string = $scope.t($scope.mr.display_priority) ? $scope.t($scope.mr.display_priority) : $scope.mr.display_priority;
                }
            }).catch(function onError(data){
                $scope.priority_string = $scope.t($scope.mr.display_priority) ? $scope.t($scope.mr.display_priority) : $scope.mr.display_priority;
            })
        }

        $scope.newPhoto = null;


        $scope.photoTypeSelection = function(type) {
            var newPhoto = {
                "type": type,
                "value": null,
                "url": null,
                "urlConverted": null
            }

            $scope.newPhoto = newPhoto;

            if(type === 'photo') {
                $scope.addCameraPhoto('CAMERA');
            } else if(type === 'file') {
                $scope.addCameraPhoto('PHOTOS');
            }
        }

        $scope.getImageBlobFromUrl = function(url) {
            var url = url.replace('http:', 'https:'); //temp fix for CORS issue?
            console.log('url',url);
            $http.get(url, {responseType: 'blob'})
                .then(function(data) {
                    console.log('data',data);
                    console.log('data.data', data.data);
                    $scope.newPhoto.value = data.data;
                })
                .catch(function(error) {
                    console.log('error',error);
                });
        }

        $scope.setOptions = function(srcType) {
            var options = {
                // Some common settings are 20, 50, and 100
                quality: 75,
                destinationType: Camera.DestinationType.FILE_URI,
                // In this app, dynamically set the picture source, Camera or photo gallery
                sourceType: srcType,
                encodingType: Camera.EncodingType.JPEG,
                mediaType: Camera.MediaType.PICTURE,
                allowEdit: false,
                correctOrientation: true,
                targetWidth: 1000,
                targetHeight: 1000
            }
            return options;
        }


        $scope.addCameraPhoto = async function(source) {
            try {
                const photo = await window.Capacitor.Plugins.Camera.getPhoto({
                    source: source,
                    resultType: 'base64',
                    quality: 90
                });
                console.log(photo)
                // Set the photo URL for display
                $scope.newPhoto.url = `data:image/jpeg;base64,${photo.base64String}`; // Assuming the image is JPEG
                $scope.newPhoto.urlConverted = `data:image/jpeg;base64,${photo.base64String}`; // Assuming the image is JPEG

                // Convert the photo URI to be compatible with Ionic WebView
                $scope.newPhoto.value = `data:image/jpeg;base64,${photo.base64String}`; //

            } catch (error) {
                console.debug("Unable to obtain picture: " + error, "app");
                $scope.newPhoto = null;
            }
        };

        $scope.resetImageSelection = function() {
            $scope.newPhoto = null;
        }

        $scope.isUploading = false;

        $scope.upload = function(){
            $scope.isUploading = true;

            if($rootScope.platform === 'web') {
                $scope.uploadWeb();
            }else if($rootScope.platform === 'ios' || $rootScope.platform === 'android' || $rootScope.platform === 'browser') {
                $scope.uploadMobile();
            }
        };

        $scope.validateAttachments = function () {
            const allowedTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/bmp',
                'image/svg+xml',
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ];
            let f1 = document.getElementById('file').files[0];

            console.log('f1', f1)
            let hasError = false;

            if (f1 && f1.size > 20 * 1024 * 1024) {
                toastr.error(f1.name + ' can\'t be submitted', 'File size exceeds the 20MB limit')
                hasError = true;
            }
            if (f1 && !allowedTypes.includes(f1.type)) {
                toastr.error(f1.name + ' can\'t be submitted', 'Please only upload Images and Documents')
                hasError = true;
            }
            return hasError;
        }
        $scope.uploadWeb = function() {
            if ($scope.validateAttachments()) {
                return;
            }
            var f = $rootScope.platform === 'web' ? document.getElementById('file').files[0] : document.getElementById('file1').files[0],
            r = new FileReader();
            try {
                r.readAsDataURL(f);
                r.onloadend = function(e) {
                    var data = e.target.result;
                    var filename = f.name;
                    $http.post($rootScope.pqDomain + '/api/v1/maintenance-requests/upload/' + $stateParams.id, {
                        image: data,
                        filename: filename
                    })
                        .success(function (data) {
                            if (data.success) {
                                toastr.success($scope.t('Document Added'));
                                $scope.isUploading = false;
                                $scope.newPhoto = null;
                                //$scope.mr.images.push(data.data[0]);
                                $scope.mr.just_images = data.data.just_images;
                                $scope.mr.just_files = data.data.just_files;

                                if ($rootScope.platform === 'web') {
                                    document.getElementById('file').value = "";
                                } else {
                                    document.getElementById('file1').value = "";
                                }

                            } else
                                toastr.error(data.errors);
                        })
                        .error(function (data) {
                            if (typeof data.stopaction != 'undefined') {
                                return;
                            }

                            toastr.error($scope.t('Error uploading document'));
                        });
                }
            } catch (e) {
                console.log('e', e)
                toastr.error('No file selected');
            }
        }

        $scope.uploadMobile = function() {
            // Check if new photo is present and the value (base64Data) is not null
            if ($scope.newPhoto && $scope.newPhoto.value !== null) {
                console.log("upload photo",  $scope.newPhoto);

                // Assuming newPhoto.value holds base64Data
                var dataImage = $scope.newPhoto.value;

                // Upload the image using HTTP POST
                $http.post($rootScope.pqDomain + '/api/v1/maintenance-requests/upload/' + $stateParams.id, {
                    filename: "test",
                    image: dataImage  // send the base64 image data
                })
                    .then(function(response) {
                        if (response.data.success) {
                            toastr.success('Image Added');
                            $scope.newPhoto = null;
                            $scope.mr.just_images = response.data.data.just_images;
                        } else {
                            toastr.error(response.data.errors);
                        }
                        $scope.isUploading = false;
                    })
                    .catch(function(error) {
                        $scope.isUploading = false;
                        if (error.data && typeof error.data.stopaction !== 'undefined') {
                            return;
                        }
                        toastr.error($scope.t('Error uploading image'));
                        console.log(error)
                    });
            }
        };


        $scope.emailAssignedVendorsNote = '';
        $scope.prepareEmailAssignedVendors = function () {
            $('#vendor-email-note').appendTo('body').modal('show');
        }

        $scope.emailAssignedVendors = function(){
            if (!confirm($scope.t('email_vendors_confirm'))) {
                return;
            }

            var data = {
                mr_id: $stateParams.id,
                vendors: $scope.mr.vendors,
                note: $scope.emailAssignedVendorsNote,
            };

            $http.post('/api/v1/vendor/email-mr-vendors', data).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,$scope.t('email_vendors_error'));
                    return;
                }
                $('#vendor-email-note').appendTo('body').modal('hide');

                toastr.success($scope.t('Vendor(s) emailed'));
            }).error(function(error) {
                toastr.error(data.errors,$scope.t('email_vendors_error'));
            });
        }


        $scope.saveMR = function(){
            if($scope.mr.property.maintenance_request_additional_details){
                $scope.mr.details = $scope.mr.details.replace($scope.t('where_problem'), "Where is the problem?");
                $scope.mr.details = $scope.mr.details.replace($scope.t('how_long_problem'), 'How long has this been going on?');
                $scope.mr.details = $scope.mr.details.replace($scope.t('past_problem'), 'Has this been a problem in the past?');
            }
            //normalize data as number
            if($scope.mr.investigated == '1') {
                $scope.mr.investigated = 1;
            }else if($scope.mr.investigated == '0'){
                $scope.mr.investigated = 0;
            }

            if($scope.mr.pm_advised == '1') {
                $scope.mr.pm_advised = 1;
            }else if($scope.mr.pm_advised == '0'){
                $scope.mr.pm_advised = 0;
            }

            if ($scope.mr.property.maintenance_request_additional_questions_enabled == 1 && $scope.authUser.permissions != 'tenant') {
                var errors = 1;
                if($scope.mr.investigated === null || $scope.mr.investigated === '') {
                    toastr.error($scope.t("submit_investigated"));
                }else if( ($scope.mr.investigated == '0' || $scope.mr.investigated === 0)  && ($scope.mr.why_uninvestigated === '' || $scope.mr.why_uninvestigated === null)) {
                    toastr.error($scope.t("submit_why_uninvestigated"));
                }else if($scope.mr.pm_advised === null || $scope.mr.pm_advised === '') {
                    toastr.error($scope.t("submit_project_manager_advised"));
                }else if( ($scope.mr.pm_advised == '0' || $scope.mr.pm_advised === 0) && ($scope.mr.why_pm_not_advised === '' || $scope.mr.why_pm_not_advised === null)) {
                    toastr.error($scope.t("submit_why_pm_not_advised"));
                }else if($scope.mr.problem_made_aware_at ===  "00/00/0000" || $scope.mr.problem_made_aware_at === null){
                    toastr.error($scope.t("submit_date_problem_made_aware_at"));
                }else{
                    errors = 0;
                }
                if (errors == 1) {
                    return;
                }
            }

            $http.post('/api/v1/maintenance-requests/update/'+$stateParams.id,$scope.mr).success(function(data) {
                //$state.go('main.maintenanceRequests');
                toastr.success($scope.t('Maintenance request updated'));
                $state.go('main.maintenanceRequests');
            });

        };

        $scope.cancelMR = function(){
            if(confirm($scope.t('confirm_mr_cancelation')))
                $http.post('/api/v1/maintenance-requests/cancel/'+$stateParams.id).success(function(data) {
                    $state.go('main.maintenanceRequests');
                    toastr.success($scope.t('mr_cancelled'));
                });

        }

        $scope.unCancelMR = function(){
            if(confirm($scope.t('confirm_reopen')))
                $http.post('/api/v1/maintenance-requests/un-cancel/'+$stateParams.id).success(function(data) {
                    toastr.success($scope.t('reopened'));
                    $scope.mr.status = 'open';
                });

        }

        $scope.deleteDocument = function(id){

            if(confirm("Are you sure you want to remove this image?")){
                $http.post('/api/v1/maintenance-requests/delete-image',{'id':id}).success(function(data) {
                    toastr.success($scope.t('Document Removed'));
                    $scope.mr.just_files = data.data.just_files;
                    $scope.mr.just_images = data.data.just_images;
                });
            }
        }
        $scope.print = function() {
            window.print();
        }

        $scope.sendEmail = function(){
            if($scope.mailTo.indexOf('@') < 1)
                toastr.error($scope.t('Please enter a valid email address to send the maintenance request to'));
            else{
                $http.post('/api/v1/maintenance-requests/email',{'mailTo':$scope.mailTo,'mr':$scope.mr}).success(function(data) {
                    toastr.success($scope.t('Maintenance request sent to')+" "+$scope.mailTo);
                    $scope.mailTo = '';
                });
            }
        };

        $scope.chat = {message:''};
        $scope.sendMessage = function(){
            if($scope.chat.message.length < 1)
                toastr.error($scope.t('Please enter a message'));
            else{
                $http.post('/api/v1/maintenance-requests/message/'+$stateParams.id,{'message':$scope.chat.message}).success(function(data) {
                    $scope.mr.messages.push(data.data.message);
                    $scope.chat.message = '';
                });
            }
        };


        $scope.checkForNewMessages = function() {
            var maxID = 0;
            for(var i in $scope.mr.messages)
                if($scope.mr.messages[i].id > maxID)
                    maxID = $scope.mr.messages[i].id;
            $http.get('/api/v1/maintenance-requests/messages/'+$stateParams.id+'/after/'+maxID).success(function(data) {
                for(var i in data.data.messages){
                    $scope.mr.messages.push(data.data.messages[i]);
                }
                $scope.mr.unread_message_count = data.data.unread_message_count;
            });
        }
        var promise = $interval($scope.checkForNewMessages, 10000);
        $scope.$on('$destroy',function(){
            if(promise)
                $interval.cancel(promise);
        });


        $scope.setTab = function(i) {
            if (i !== 1 && $scope.is_tab_details) {
                const answer = confirm($scope.t('unsaved_changes_will_be_lost'));
                if (!answer) {
                    event.preventDefault();
                }
            }
            $scope.is_tab_details = i === 1;

            if (i === 6) {
                $rootScope.goToAngular2('maintenance-requests/' + $scope.mr.id + '/vendor-messages')
            }

            if (i === 5) {
                $rootScope.goToAngular2('maintenance-requests/' + $scope.mr.id + '/todo')
            }

            if (i === 3) {
                setTimeout(function () {
                    $('#dataTableHistory').dataTable({
                        retrieve: true,
                        responsive: true,
                    });
                });
            }

            $('.scrollmenu .active').removeClass('active');
            $('#li'+i).addClass('active');

            $('#content1, #content2, #content3, #content4').hide();
            $('#content'+i).show();

            if(i == 2) {
                $scope.setMessagesToRead();
            }
        }

        $scope.setMessagesToRead = function(){
            for(var i in $scope.mr.messages){
                //set message as read
                if($scope.mr.messages[i].is_read == 0){
                    $http.post('/api/v1/maintenance-requests/message/read/'+$scope.mr.messages[i].id).success(function(datamsg) {
                        return datamsg;
                    });
                }
            }
            $scope.mr.unread_message_count = 0;
        }
        $scope.doPo = function(){
            if ($scope.mr.property.maintenance_request_pro_enabled) {
                $http.post('/api/v1/maintenance-request-po/' + $scope.mr.id + '/get-list').success(function(data) {
                    if (!data.success) {
                        //toastr.error("Error retrieving Pos");
                        return;
                    }

                    // lets populate stuff
                    $scope.pos = data.data.pos;
            });
            }
        }

        $scope.doFocus = function (field_id) {
            $('#' + field_id).focus();
        };

        $scope.viewPO = function (id) {
            window.open('/po-document-download/' + id, '_blank');
        }
        $scope.viewPOMobile = async function (id) {
            toastr.info('Downloading file...');
            const {Filesystem, CapacitorHttp, FileOpener} = window.Capacitor.Plugins;
            const pdfUrl = `${pqDomain}/po-document-download/${id}?token=${pqCachedToken}`;

            try {
                // Download the PDF file as a blob
                const response = await CapacitorHttp.get({
                    url: pdfUrl,
                    responseType: 'blob'
                });

                console.log(response.headers)
                const contentType = response.headers['Content-Type'];
                console.log(contentType)
                console.log(contentType.split('/'))

                const fileExtension = contentType.split('/').pop(); // Determine file extension from content type

                const file = await Filesystem.writeFile({
                    path: `Payquad/maintenance/po_invoice_${id}.${fileExtension}`,
                    data: response.data,
                    directory: 'DOCUMENTS',
                    recursive: true
                });

                await FileOpener.open({
                    filePath: file.uri,
                    contentType: contentType
                });

            } catch (error) {
                console.error('Unable to download or preview PDF:', error);
            }
        }
    }

}());
