(function () {

    'use strict';

    angular
        .module('app')
        .controller('MaintenanceRequestPoCreateController', maintenanceRequestPoCreateController);

    function maintenanceRequestPoCreateController($scope, $http, $state, $rootScope, $stateParams, $timeout) {
        $scope.po = {
            number: '',
            memo: '',
            vendor_id: '',
            maintenance_request_id: $stateParams.maintenance_request_id,
            po_at: moment().format('MM/DD/YYYY'),
            invoices: []
        };


        $scope.loadingVendors = false;
        $scope.loadingVendors = true;
        $http.get('/api/v1/maintenance-requests/edit/' + $stateParams.maintenance_request_id).success(function (mrResponse) {
            $scope.available_vendors = mrResponse.data.available_vendors;
            $scope.po.vendor_id = mrResponse.data.available_vendors.length > 0 ? $scope.available_vendors[0].id : null;
            $scope.loadingVendors = false;
        });
        $scope.isNative = false;

        $timeout(function(){
            $scope.isNative = window.Capacitor.isNativePlatform()
        }, 1000);

        $scope.selectedMobileFile = null;
        $scope.selectedMobileFileName = null;

        $scope.createPo = function () {
            var data = new FormData();
            if (!$scope.isNative && document.getElementById('file1').files.length > 0) {
                data.append('document', document.getElementById('file1').files[0]);
            }
            if ($scope.isNative && $scope.selectedMobileFile) {
                data.append('document', $scope.selectedMobileFile);
                data.append('document_name', $scope.selectedMobileFileName);
            }

            $.each($scope.po, function (index, value) {
                if (index === 'invoices') {
                    $.each(value, (i, v) => {
                        $.each(v.line_items, (i, v) => {
                            v.order = i+1;
                        })
                    })
                    data.append('invoices', angular.toJson(value));
                    return;
                }
                data.append(index, value);
            });

            const headers = window.Capacitor.isNativePlatform()
                ? {
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA',
                }
                : {
                    'Content-Type': undefined,
                };

            $http.post('/api/v1/maintenance-request-po/create', data, {transformRequest: angular.identity, headers: headers}).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, "Error creating PO");
                    return;
                }

                $scope.creatingPo = false;
                $state.go('main.maintenanceRequestEdit', {id: $scope.po.maintenance_request_id});
                toastr.success('PO created');
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingPo = false;
                toastr.error(data.error.message);
            });
        }

        $scope.initDatePickers = function () {
            setTimeout(function () {
                $('#po-at').datepicker();
            }, 0);
        }
        $scope.initDatePickers();

        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.maintenanceRequestEdit', {id: $scope.po.maintenance_request_id});
            return 1;
        }

        $scope.addLineItem = function () {
            $scope.newInvoice.line_items.push({
                label: '',
                sub_label: '',
                order: 1,
                type: 'line',
                tax_type: 'percentage',
                amount: ''
            });
        };

        $scope.addTaxItem = function () {
            $scope.newInvoice.tax_items.push({
                label: '',
                sub_label: '',
                order: 1,
                type: 'tax',
                tax_type: 'percentage',
                amount: ''
            });
        };

        $scope.removeLineItem = function (key) {
            $scope.newInvoice.line_items.splice(key, 1);
        };

        $scope.removeTaxItem = function (key) {
            $scope.newInvoice.tax_items.splice(key, 1);
        };

        $scope.moveLineItemDown = function (key) {
            var newkey = key + 1;

            if (typeof $scope.newInvoice.line_items[newkey] == 'undefined') {
                return;
            }

            var temp = $scope.newInvoice.line_items[newkey];
            $scope.newInvoice.line_items[newkey] = $scope.newInvoice.line_items[key];
            $scope.newInvoice.line_items[key] = temp;
        };

        $scope.moveLineItemUp = function (key) {
            if (key == 0) {
                return;
            }

            var newkey = key - 1;

            var temp = $scope.newInvoice.line_items[newkey];
            $scope.newInvoice.line_items[newkey] = $scope.newInvoice.line_items[key];
            $scope.newInvoice.line_items[key] = temp;
        };

        $scope.moveTaxItemDown = function (key) {
            var newkey = key + 1;

            if (typeof $scope.newInvoice.tax_items[newkey] == 'undefined') {
                return;
            }

            var temp = $scope.newInvoice.tax_items[newkey];
            $scope.newInvoice.tax_items[newkey] = $scope.newInvoice.tax_items[key];
            $scope.newInvoice.tax_items[key] = temp;
        };

        $scope.moveTaxItemUp = function (key) {
            if (key == 0) {
                return;
            }

            var newkey = key - 1;

            var temp = $scope.newInvoice.tax_items[newkey];
            $scope.newInvoice.tax_items[newkey] = $scope.newInvoice.tax_items[key];
            $scope.newInvoice.tax_items[key] = temp;
        }

        $scope.editInvoice = invoice => {
            $('#createInvoice').appendTo('body').modal({backdrop: 'static', keyboard: false});
            $scope.newInvoice = invoice;
        }

        $scope.deleteInvoice = invoice => $scope.po.invoices = $scope.po.invoices.filter(item => item.uniqId !== invoice.uniqId)

        $scope.newInvoice = {
            uniqId: '',
            name: '',
            number: '',
            line_items: [
                {
                    label: '',
                    sub_label: '',
                    order: 1,
                    type: 'line',
                    tax_type: 'percentage',
                    amount: ''
                }
            ],
            tax_items: [
                {
                    label: '',
                    sub_label: '',
                    order: 1,
                    type: 'tax',
                    tax_type: 'percentage',
                    amount: ''
                }
            ]
        };

        $scope.showCreateInvoicePopUp = () => {
            $scope.newInvoice = {
                uniqId: '',
                name: '',
                number: '',
                line_items: [],
                tax_items: []
            }
            $('#createInvoice').appendTo('body').modal({backdrop: 'static', keyboard: false});
        }

        $scope.saveNewInvoice = () => {
            if ($scope.newInvoice.uniqId.length === 0) {
                $scope.newInvoice.uniqId = '_' + Math.random().toString(36).substr(2, 9);
                $scope.po.invoices.push($scope.newInvoice);
            }
            $scope.newInvoice = {
                uniqId: '',
                name: '',
                number: '',
                line_items: [],
                tax_items: []
            }
            $('#createInvoice').modal('hide');
        }

        $scope.chooseFileMobile = async () => {
            try {
                // Pick a file
                $scope.mobileFile = await window.Capacitor.Plugins.FilePicker.pickFiles({
                    limit: 1,
                    readData: true,
                });

                if ($scope.mobileFile && $scope.mobileFile.files.length > 0) {
                    $scope.selectedMobileFile = $scope.mobileFile.files[0].data;
                    $scope.selectedMobileFileName = $scope.mobileFile.files[0].name;
                } else {
                    console.error('No file selected.');
                }
            } catch (err) {
                console.error('Error while selecting or reading file:', err);
            }
        };


    }

}());
