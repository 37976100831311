(function () {

    'use strict';

    angular
        .module('app')
        .config(routes);

    function routes($stateProvider) {

        const url = 'scripts/modules/parcel/';

        $stateProvider
            .state('main.parcels', {
                url: '/parcels',
                views: {
                    'body@': {
                        templateUrl: url + 'index/parcel.index.html',
                        controller: 'ParcelIndexController',
                        resolve: {
                            resolvedParcels: function ($rootScope, parcelService, $q) {
                                    return $q.when(parcelService.forTenant());
                            }
                        }
                    },
                    'manager@main.parcels': {
                        templateUrl: url + 'index/manager.html',
                    },
                    'tenant@main.parcels': {
                        templateUrl: url + 'index/tenant.html',
                    }
                }
            })
            .state('main.parcels.create', {
                url: '/create',
                views: {
                    'sidebar@': {
                        controller: 'ParcelCreateController',
                        templateUrl: url + 'create/parcel.create.html',
                        resolve: {
                            resolvedParcel: function (parcelService) {
                                return parcelService.getBlank();
                            },
                            resolvedProperties: function ($q, parcelService) {
                                return $q.when(parcelService.getProperties());
                            }
                        }
                    }
                }
            })
            .state('main.parcels.edit', {
                url: '/edit/:id',
                views: {
                    'sidebar@': {
                        controller: 'ParcelEditController',
                        templateUrl: url + 'edit/parcel.edit.html',
                        resolve: {
                            resolvedParcel: function ($q, $stateParams, parcelService) {
                                return $q.when(parcelService.get($stateParams.id));
                            }
                        }
                    }
                }
            });
    }

}());
