(function() {

    'use strict';

    angular
        .module('app')
        .controller('PerksController', PerksController);

    function PerksController($scope, $state, $stateParams, $http, $rootScope,$timeout) {

        if ($rootScope.authUser.property_perks == false && $rootScope.authUser.permissions != 'payquad_admin') {
            $state.go('main.dashboard');
        }

		$scope.isNative = false;

		$timeout(function(){
			$scope.isNative = window.Capacitor.isNativePlatform()
		}, 1000);

		$scope.perks = [];
		$scope.newPerk = { properties:[]};
		$scope.properties = [];
		$http.get('api/v1/perks-manager')
			.then(function (obj) {
				$scope.perks = obj.data;
		});
		$http.post('/api/v1/document/get-properties', {company_id: $rootScope.authUser.company_id}).success(function(data) {
                $scope.properties = data.data.properties;
                $timeout(function(){
                    $('select.properties').multipleSelect({filter: true, width: '100%' });
                }, 500);
            });
		
		$scope.selectImageMobileForUpdate = function(perk){
			if($scope.isNative){
				window.Capacitor.Plugins.Camera.getPhoto({
					quality: 100,
					allowEditing: false,
					resultType: 'dataUrl',
					saveToGallery: true
				}).then(function (result) {
					perk.newImage = result.dataUrl;
				}).catch(function (err) {
					console.log(err);
				});
			}
		}

		$scope.previewMobile = function(domain, perk){
			window.Capacitor.Plugins.Browser.open({ url: domain + perk});
		}

		$scope.selectImageMobile = function(){
			if($scope.isNative){
				window.Capacitor.Plugins.Camera.getPhoto({
					quality: 100,
					allowEditing: false,
					resultType: 'dataUrl',
					saveToGallery: true
				}).then(function (result) {
					console.log(result.dataUrl)
					$scope.newPerk.newImage = result.dataUrl;
					// $scope.savePerk(perk);
				}).catch(function (err) {
					console.log(err);
				});
			}
		}

		$scope.createPerk = function(){
			var perk = $scope.newPerk;

			if ($scope.isNative) {
				$scope.createPerkRequest(perk);
			} else {
				if(document.getElementById('newPerkFile').files.length == 0) {
					toastr.error("Please upload an image","Error Saving changes");
				}
				var f1 = document.getElementById('newPerkFile').files[0],
					r1 = new FileReader();
				r1.readAsDataURL(f1);
				r1.onloadend = function (e) {
					perk.newImage = e.target.result;
					$scope.createPerkRequest(perk);
				};
			}
		}

		$scope.createPerkRequest = function(perk){
			$http.post('api/v1/perks-manager/create', perk)
				.success(function (data) {
					if (!data.success) {
						toastr.error(data.errors,"Error Saving changes");
						return;
					}
					else{
						toastr.success('Perk Created');
						perk.id = data.id;
						perk.company_id = data.company_id;
						$scope.perks.push(perk);
						$timeout(function(){
							$('select.properties').multipleSelect({filter: true, width: '100%' });
						});
						$scope.newPerk = { properties:[]}
						f1.value = f1.defaultValue;
						document.getElementById('newPerkFile').value =   document.getElementById('newPerkFile').defaultValue

					}
				}).error(function(data){
				if (typeof data.stopaction != 'undefined') {
					return;
				}

				toastr.error(data.error.message);
			});
		}

		$scope.savePerkRequest = function(perk){
			$http.post('api/v1/perks-manager/update',perk)
				.success(function (data) {
					if (!data.success) {
						toastr.error(data.errors,"Error Saving changes");
						return;
					}
					else	toastr.success('Changes Saved');
				}).error(function(data){
				if (typeof data.stopaction != 'undefined') {
					return;
				}

				toastr.error(data.error.message);
			});
		}

		$scope.savePerk = function(perk){
			if ($scope.isNative) {
				$scope.savePerkRequest(perk);
			} else {
				if(document.getElementById('file'+perk.id).files.length != 0){
					var f1 = document.getElementById('file'+perk.id).files[0],
						r1 = new FileReader();
					r1.readAsDataURL(f1);
					r1.onloadend = e => {
						perk.newImage = e.target.result;
						$scope.savePerkRequest(perk);
					};
				} else {
					$http.post('api/v1/perks-manager/update',perk)
						.success(function (data) {
							if (!data.success) {
								toastr.error(data.errors,"Error Saving changes");
								return;
							}
							else{
								toastr.success('Changes Saved');
								perk.image_url = data.image_url;
							}
						}).error(function(data){
						if (typeof data.stopaction != 'undefined') {
							return;
						}

						toastr.error(data.error.message);
					});
				}
			}
		};
		
		$scope.deletePerk = function(perk){
			if(!confirm('Are you sure you want to delete this perk?'))
				return;
			
			$http.post('api/v1/perks-manager/delete',perk)
				.success(function (data) {
					if (!data.success) {
						toastr.error(data.errors,"Error deleting perk");
						return;
					}
					else{
						toastr.success('Perk Deleted');
						perk.deleted = true;
					}
				}).error(function(data){
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

					toastr.error(data.error.message);
				});
		}
		
		$scope.uploadPerkFile = function(file,perk){
			perk.newImage = file;
		}

    }

}());
