(function () {

    'use strict';

    angular
        .module('app')
        .controller('ParcelCreateController', function ($scope, $state, resolvedParcel, resolvedProperties, propertyService, tenantService, parcelService, $timeout) {
            $scope.parcel = resolvedParcel;
            $scope.properties = resolvedProperties;
            $scope.units = [];
            $scope.tenants = [];
            $scope.isLoading = false;
            $scope.carriers = [
                {value: 'unknown', label: 'Unknown'},
                {value: 'amazon', label: 'Amazon'},
                {value: 'canadapost', label: 'Canada Post'},
                {value: 'purolator', label: 'Populator'},
                {value: 'ups', label: 'UPS'},
                {value: 'fedex', label: 'Fedex'},
                {value: 'dhl', label: 'DHL'},
                {value: 'doordash', label: 'DoorDash'},
                {value: 'grubhub', label: 'Grubhub'},
                {value: 'instacart', label: 'Instacart'},
                {value: 'postmates', label: 'Postmates'},
            ];
            $scope.types = [
                {value: 'unknown', label: 'Unknown'},
                {value: 'box', label: 'Box'},
                {value: 'bag', label: 'Bag'},
                {value: 'envelope', label: 'Envelope'},
            ];

            const vm = this;
            $scope.attachments = [];

            $scope.propertyChanged = () => {
                $scope.parcel.property_unit_id = null;
                $scope.tenant_id = null;
                $scope.units = [];
                $scope.tenants = [];
                propertyService.getUnits($scope.parcel.property_id).then(data => $scope.units = data);
            };

            $scope.unitChanged = () => {
                $scope.parcel.tenant_id = null;
                $scope.tenants = [];
                //tenantService.getTenantsByUnitId($scope.parcel.property_unit_id).then(data => {
                    //if(data){
                        //$scope.tenants = [data.tenant];
                    //}
                //});
                tenantService.getTenantsByUnit($scope.parcel.property_id, $scope.parcel.property_unit_unit).then(data => {
                    if(data){
                        if (typeof data.tenants != 'undefined') {
                            data.tenants.forEach((tenant, key) => {
                                data.tenants[key].name = tenant.first_name + ' ' + tenant.last_name;
                            });
                            $scope.tenants = data.tenants;
                        } else {
                            $scope.tenants = [data.tenant];
                        }
                    }
                });
            };

            $scope.create = () => {
                $scope.isLoading = true;
                parcelService.create({
                    ...$scope.parcel,
                    attachments: $scope.attachments.map(a => ({name: a.upload.filename, data: a.dataURL}))
                }).then(() => {
                   $state.go('main.parcels', {}, {reload: true}).then(() => toastr.success('Parcel was created successfully.'));
                });
            }

            $scope.removeFile = function (file) {
                $scope.myDropzone.removeFile(file);
            };

            $scope.processDropzoneFiles = function () {
                $scope.total_file_size = 0;
                angular.forEach($scope.attachments, function (file, key) {
                    var fileReader = new FileReader();
                    var base64;
                    fileReader.onload = function (fileLoadedEvent) {
                        base64 = fileLoadedEvent.target.result;
                        // base64 is the dataURL
                        file.dataUrl = base64;
                    };
                    fileReader.readAsDataURL(file);

                    $scope.total_file_size += (file.size / 1000000);
                });
            };

            $scope.show_dropzone = 0;
            $scope.total_file_size = 0;
            $scope.initDropzone = function () {
                $scope.myDropzone = new Dropzone('#real-dropzone', {
                    url: '/api/v1/noreturn/',
                    autoProcessQueue: false,
                    uploadMultiple: true,
                    parallelUploads: 100,
                    maxFiles: 20,
                    maxFilesize: 8,
                    addRemoveLinks: false,
                    dictRemoveFile: 'Remove',
                    dictFileTooBig: 'File is bigger than 8MB',

                    // The setting up of the dropzone
                    error: function (file, response) {
                        toastr.error(response);
                    },
                    addedfile: function (file, response) {
                        $timeout(function () {
                            $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                            $scope.processDropzoneFiles();
                        }.bind(vm));
                    },
                    removedfile: function (file, response) {
                        $timeout(function () {
                            $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                            $scope.processDropzoneFiles();
                        }.bind(vm));
                    },
                });
            };

            $timeout(function () {
                $scope.initDropzone();
            });
        });

}());
