(function () {

    'use strict';

    angular
        .module('app')
        .directive('pqUploadSignature', pqUploadSignature);

    angular
        .module('app')
        .controller('pqUploadSignatureController', pqUploadSignatureController);

    function pqUploadSignature() {
        return {
            restrict: 'E',
            templateUrl: 'scripts/components/sign-pad-popup/upload-signature/upload-signature.html',
            controller: 'pqUploadSignatureController',
            scope: {
                signId: '=',
                ref: '=',
                signModel: '=',
                nameModel: '='
            }
        };
    }


    function pqUploadSignatureController($scope, $timeout) {

        $scope.isApp = false;

        $timeout(() => {
            $scope.isApp = window.Capacitor.isNativePlatform();
        }, 500);

        $scope.signatureImageSrc = null;
        $scope.signatureImageUpload = () => {
            var reader = new FileReader(); 
            let file = document.getElementById(`${$scope.signId}-signature-upload`).files[0];
            reader.onloadend = function(e){
                $scope.signModel = e.target.result;
                $scope.signatureImageSrc = e.target.result;
                $(`#${$scope.signId}-uploaded-image`).show();
                $scope.$apply();
            }
            reader.readAsDataURL(file);
        }

        $scope.signatureImageUploadMobile = async () => {
            $scope.mobileFile = await window.Capacitor.Plugins.FilePicker.pickFiles({
                limit: 1,
                readData: true,
                types: ['image/*'],

            });
            const mimeType = $scope.mobileFile.files[0].mimeType || "application/octet-stream"; // Default if MIME type is missing

            $scope.$apply(function () {
                $scope.signModel = `data:${mimeType};base64,${$scope.mobileFile.files[0].data}`;
                $scope.signatureImageSrc = `data:${mimeType};base64,${$scope.mobileFile.files[0].data}`;
                $(`#${$scope.signId}-uploaded-image`).show();
            });
        }

        $scope.ref.clearUploadedSignature = () => {
            $scope.model = null;
            $scope.signatureImageSrc =null;
            $(`#${$scope.signId}-uploaded-image`).hide();
        };
    }

})();
