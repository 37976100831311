(function() {
    'use strict';

    angular
    .module('app')
    .controller('DocumentEditController', documentEditController);

    function documentEditController($scope,$http,$state,$stateParams,$rootScope,$timeout) {

        $scope.loading_spinner=0;
        $scope.realtors_enabled = 0;

        $scope.document = {
            id: $stateParams.id,
            name: '',
            description: '',
            categories: [],
            company_id: 0,
            payment_required: 'no',
            type: 'regular',
            payment_amount: '',
            send_notifications: 0,
            permissions: {
                by_properties: 0,
                all_properties: 1,
                properties: [],
                by_managers: 0,
                all_managers: 1,
                managers: [],
                by_tenants: 0,
                all_tenants: 1,
                tenants: [],
                by_realtors: 0,
                all_realtors: 1,
                realtors: []
            }
        };

        $scope.dropdowns = {
            companies: {},
            categories: [],
            properties: [],
            managers: [],
            tenants: [],
            realtors: []
        };

        $scope.getDocument = function(){
            $http.post('/api/v1/document/get/' + $scope.document.id).success(function(data) {
                if (!data.success) {
                    $rootScope.goToAngular2('documents/list');
                }

                // lets populate stuff
                var doc = data.data.document;
                var permissions = data.data.permissions;

                $scope.document.name = doc.name;
                $scope.document.description = doc.description;
                $scope.document.company_id = doc.company_id;
                $scope.document.payment_required = doc.payment_required;
                $scope.document.type = doc.type;
                $scope.document.payment_amount = doc.payment_amount;
                angular.forEach(doc.categories, function(category, key){
                    $scope.document.categories.push(category.id);
                });

                // now we populate the permissions
                $scope.document.permissions.by_properties = permissions.by_properties ? true : false;
                $scope.document.permissions.all_properties = permissions.all_properties;
                angular.forEach(permissions.properties, function(property_id, key){
                    $scope.document.permissions.properties.push(property_id);
                });
                $scope.document.permissions.by_managers = permissions.by_managers ? true : false;
                $scope.document.permissions.all_managers = permissions.all_managers;
                angular.forEach(permissions.managers, function(manager_id, key){
                    $scope.document.permissions.managers.push(manager_id);
                });
                $scope.document.permissions.by_tenants = permissions.by_tenants ? true : false;
                $scope.document.permissions.all_tenants = permissions.all_tenants;
                angular.forEach(permissions.tenants, function(tenant_id, key){
                    $scope.document.permissions.tenants.push(tenant_id);
                });
                $scope.document.permissions.by_realtors = permissions.by_realtors ? true : false;
                $scope.document.permissions.all_realtors = permissions.all_realtors;
                angular.forEach(permissions.realtors, function(realtor_id, key){
                    $scope.document.permissions.realtors.push(realtor_id);
                });

                // ok, initiate everything else
                if ($rootScope.authUser.permissions == 'payquad_admin') {
                    $http.post('/api/v1/document/get-companies').success(function(data) {
                        $scope.dropdowns.companies = data.data.companies;
                        $scope.getCategories();
                        $scope.getProperties();
                        $scope.getManagers();
                        $scope.getTenants();
                        $scope.getRealtors();
                    });
                } else {
                    $scope.getCategories();
                    $scope.getProperties();
                    $scope.getManagers();
                    $scope.getTenants();
                    $scope.getRealtors();
                }
            });
        };

        $scope.getCategories = function() {
            $http.post('/api/v1/document/get-categories', {company_id: $scope.document.company_id}).success(function(data) {
                $scope.dropdowns.categories = data.data.categories;
                $timeout(function(){
                    $('select#categories').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        $scope.getProperties = function() {
            $http.post('/api/v1/document/get-properties', {company_id: $scope.document.company_id}).success(function(data) {
                $scope.dropdowns.properties = data.data.properties;
                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        $scope.getManagers = function() {
            $http.post('/api/v1/document/get-managers', {company_id: $scope.document.company_id}).success(function(data) {
                $scope.dropdowns.managers = data.data.managers;
                $timeout(function(){
                    $('select#managers').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        $scope.getTenants = function() {
            $http.post('/api/v1/document/get-tenants', {company_id: $scope.document.company_id, permissions: $scope.document.permissions}).success(function(data) {
                $scope.dropdowns.tenants = data.data.tenants;
                $timeout(function(){
                    $('select#tenants').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        $scope.getRealtors = function() {
            $http.post('/api/v1/document/get-realtors', {company_id: $scope.document.company_id}).success(function(data) {
                if (!data.success) {
                    $scope.realtors_enabled = 0;
                    return;
                }
                $scope.realtors_enabled = 1;
                $scope.dropdowns.realtors = data.data.realtors;
                $timeout(function(){
                    $('select#realtors').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        $scope.getDocument();

        $scope.companyChanged = function(){
            $scope.getCategories();
            $scope.getProperties();
            $scope.getManagers();
            $scope.getTenants();
            $scope.getRealtors();
        };

        $scope.deleteDocument = function() {
            if (!confirm('Are you sure you want to delete this document?')) {
                return; // they did not confirm
            }

            $http.post('/api/v1/document/delete/' + $scope.document.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error creating document");
                    return;
                }

                $rootScope.goToAngular2('documents/list');
                toastr.success('Document deleted');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingDocument = false;
                toastr.error(data.error.message);
            });
        };

        $scope.updateDocument = function() {
            //if (document.getElementById('file1').files.length == 0) {
                //toastr.error("Please choose a file to upload");
                //return;
            //}

            if ($scope.document.permissions.by_realtors == 1 && $scope.document.permissions.by_properties == 0) {
                toastr.error("When choosing Realtor/Agent Permissions, you must set property based permisssions as well");
                return;
            }

            $scope.document.document = document.getElementById('file1').files[0];

            $scope.creatingDocument = true;

            var data = new FormData();
            if (document.getElementById('file1').files.length > 0) {
                data.append('document', document.getElementById('file1').files[0]);
            }
            data.append('name', $scope.document.name);
            data.append('description', $scope.document.description);
            data.append('send_notifications', $scope.document.send_notifications);
            data.append('categories', $scope.document.categories);
            data.append('by_properties', $scope.document.permissions.by_properties);
            data.append('all_properties', $scope.document.permissions.all_properties);
            data.append('properties', $scope.document.permissions.properties);
            data.append('by_managers', $scope.document.permissions.by_managers);
            data.append('all_managers', $scope.document.permissions.all_managers);
            data.append('managers', $scope.document.permissions.managers);
            data.append('by_tenants', $scope.document.permissions.by_tenants);
            data.append('all_tenants', $scope.document.permissions.all_tenants);
            data.append('tenants', $scope.document.permissions.tenants);
            data.append('by_realtors', $scope.document.permissions.by_realtors);
            data.append('all_realtors', $scope.document.permissions.all_realtors);
            data.append('realtors', $scope.document.permissions.realtors);
            data.append('company_id', $scope.document.company_id);

            if ($scope.document.payment_required == 'no') {
                $scope.document.payment_amount = '';
            }
            data.append('type', $scope.document.type);
            data.append('payment_required', $scope.document.payment_required);
            data.append('payment_amount', $scope.document.payment_amount);

            $scope.loading_spinner=1;

            const headers = window.Capacitor.isNativePlatform()
                ? {
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA',
                }
                : {
                    'Content-Type': undefined,
                };

            $http.post('/api/v1/document/update/' + $scope.document.id,data, {transformRequest: angular.identity, headers: headers}).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error creating document");
                    $scope.loading_spinner=0;
                    return;
                }

                $scope.creatingDocument = false;
                $scope.loading_spinner=0;
                $rootScope.goToAngular2('documents/list');
                toastr.success('Document updated');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingDocument = false;
                $scope.loading_spinner=0;

                if (typeof data == 'string' && data.includes('413') == true) {
                    toastr.error('Error uploading document, to large.');
                } else {
                    toastr.error(data.error.message);
                }
            });
        }

        $scope.goBack = () => {
            $rootScope.goToAngular2('documents/list');
        }
    }
}());
