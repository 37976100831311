(function() {
    'use strict';

    angular
        .module('app')
        .controller('LeaseApplicationCreatePortfoliosController', leaseApplicationCreatePortfoliosController);

    function leaseApplicationCreatePortfoliosController($scope,$http,$state,$rootScope,$timeout,$stateParams) {
        let debounceTimeout = null;

        if (window.location.host.indexOf('leasing') === -1) {
            window.location.href = window.location.href.replace('.payquad', '.leasing.payquad');
        }

        $scope.portfolios = [];
        $scope.ref = { portfolio_id: null };
        $scope.show_portfolios = 0;

        $scope.properties = [];
        $scope.ref = { property_id: null };
        $scope.show_properties = 0;

        $scope.getPortfolios = function(){
            $http.post('/api/v1/lease-application/get-portfolios/' + $stateParams.companyId).success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving portfolios");
                }

                // lets populate stuff
                $scope.portfolios = data.data.portfolios;

                $timeout(function () {
                    $('#portfolios-select').multipleSelect({
                        placeholder: 'Portfolios',
                        single: true,
                        filter: true,
                        width: 400
                    });
                    $scope.show_portfolios = 1;
                }, 500);
            });
        }

        $scope.getProperties = function(portfolioId){
            $http.post('/api/v1/lease-application/get-portfolio-properties/' + portfolioId).success(function(data) {
                if (!data.success) {
                    toastr.error("Error retrieving Properties");
                }

                // lets populate stuff
                $scope.properties = data.data.properties;

                $timeout(function () {
                    $('#properties-select').multipleSelect({
                        placeholder: 'Properties',
                        single: true,
                        filter: true,
                        width: 400
                    });
                    $scope.show_properties = 1;
                }, 500);
            });
        }

        $scope.portfolioChanged = function(){
            if ($scope.ref.portfolio_id.length === 0) {
                return;
            }

            if (debounceTimeout) {
                $timeout.cancel(debounceTimeout);
            }

            debounceTimeout = $timeout(function() {
                $scope.getProperties($scope.ref.portfolio_id[0]);
            }, 500);
        }

        $scope.propertyChanged = function(){
            if ($scope.ref.property_id.length === 0) {
                return;
            }
            $state.go('main.leaseApplicationCreate', {'propertyId': $scope.ref.property_id[0]});
        }

        $scope.getPortfolios();
    }
}());
