(function () {
    'use strict';

    angular
        .module('app')
        .controller('ProspectEditController', prospectEditController);

    function prospectEditController($scope, $http, $state, $stateParams, prospectService, $timeout, messageService, $rootScope, helperService, accountService, moment, langService) {
        const vm = this;
        $scope.t = langService.t('lease_app');
        $scope.properties = [];
        $scope.prospect = prospectService.getBlank();
        $scope.prospect.id = $stateParams.id;
        $scope.inviteControl = {};
        $scope.isNative = false;

        $timeout(function(){
            $scope.isNative = window.Capacitor.isNativePlatform()
        }, 1000);

        $scope.isMobile = helperService.isMobileOrTablet();

        accountService.getAccountInformation()
            .success(function (data) {
                $scope.accountInformation = data.data;
            });


        $scope.getProperties = function () {
            $http.post('/api/v1/prospect/get-properties/' + $scope.prospect.company_id).success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.properties = data.data.properties;
            });
        };

        $scope.getStageColorScheme = () => {
            if($scope.prospect){
                $scope.background_color = $scope.prospect.stage_color_scheme[$scope.prospect.stage].background_color;
                $scope.color = $scope.prospect.stage_color_scheme[$scope.prospect.stage].color;
            }else{
                $scope.background_color = $scope.prospect.stage_color_scheme['new'].background_color;
                $scope.color = $scope.prospect.stage_color_scheme['new'].color;
            }
        }

        $scope.getProspect = function () {
            $http.post('/api/v1/prospect/get/' + $scope.prospect.id).success(function (data) {
                if (!data.success) {
                    $state.go('main.prospects');
                }

                $scope.prospect = data.data.prospect;
                $scope.current_state = $scope.prospect.stage;
                $scope.getStageColorScheme();
                $scope.showings = $scope.prospect.showings ? $scope.prospect.showings : null;

                if ($scope.prospect.move_in_at != null) {
                    $scope.prospect.move_in_at = moment($scope.prospect.move_in_at).format('MM/DD/YYYY');
                }
                if ($scope.prospect.contact_at != null) {
                    $scope.prospect.contact_at = moment($scope.prospect.contact_at).format('MM/DD/YYYY');
                }
                $scope.getProperties();
                $timeout(function () {
                    $scope.getPropertyUnits();
                }, 1000);

            });
        };

        $scope.deleteProspect = function () {
            if (!confirm($scope.t('Are you sure you want to delete this prospect?'))) {
                return; // they did not confirm
            }
            if ($scope.prospect.yardi_prospect && $scope.prospect.yardi_prospect.application_id) {
                toastr.error('It appears that the prospect you are attempting to delete currently has an active application in progress. To proceed with the deletion, please cancel the existing application associated with this prospect.', 'Cannot Delete Prospect with Ongoing Application', {timeOut: 10000});
                return;
            }
            $http.post('/api/v1/prospect/delete/' + $scope.prospect.id).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error deleting prospect'));
                    return;
                }

                $state.go('main.prospects');
                toastr.success($scope.t('Prospect deleted'));
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    create
                    return;
                }

                $scope.savingProspect = false;
                toastr.error(data.error.message);
            });
        };

        $scope.checkForRentAmount = function () {
            $http.post('/api/v1/prospect/get-unit-rent-amount/' + $scope.prospect.property_id + '/' + $scope.prospect.unit.unit, {start_date: $scope.prospect.move_in_at}).success(function (data) {
                if (!data.success) {
                    return;
                }

                if (data.data.available == 0 && data.data.rent_amount != 0) {
                    toastr.error($scope.t('unit_unavailable'));
                    return;
                }
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        $scope.$watch('prospect.unit.unit', function (newValue, oldValue) {
            if ($scope.prospect.property_id !== 0) {
                $scope.checkForRentAmount();
            }
        });

        $scope.saveProspect = function () {
            if ($scope.savingProspect == true) {
                return;
            }

            $scope.prospect.ignor_autoresponder = true;

            if (
                ($scope.prospect.agent_first_name == 'Select Agent' || $scope.prospect.agent_first_name == 'Sélectionner agent' || $scope.prospect.agent_first_name == $scope.t('Select Agent'))
                && !$scope.prospect.is_yardi_agent_hidden
            ) {
                toastr.error($scope.t('Please select an agent'));
                return;
            }

            if($scope.prospect.no_email == 1){
                $scope.prospect.email = $scope.prospect.registration_code + '@payquad.com';
            }

            if(!$scope.prospect.email){
                toastr.error($scope.t('email_empty'));
                return;
            }

            if(!$scope.prospect.email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)){
                toastr.error($scope.t('email_invalid'));
                return;
            }

            $scope.savingProspect = true;

            $http.post('/api/v1/prospect/save', $scope.prospect).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error updating prospect'));
                    $scope.savingProspect = false;
                    return;
                }

                toastr.success($scope.t('Prospect updated'));
                if (data.data.prospect.is_applied_before) {
                    toastr.warning($scope.t('Please note, this prospect has previously applied to a property in your company'));
                }
                $state.go('main.prospects', {}, {reload: true});
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.savingProspect = false;
                if (typeof data.errors != 'undefined') {
                    angular.forEach(data.errors, function (messages, field) {
                        angular.forEach(messages, function (message, key) {
                            toastr.error(message);
                        });
                    });
                }
            });
        };

        $scope.getPropertyUnits = function () {
            $http.post('/api/v1/properties/get-property-units-for-manager-payments/' + $scope.prospect.property_id).success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.property_units = [];
                angular.forEach(data.data, function (unit, key) {
                    if (!$scope.property_units.includes(unit.unit)) {
                        $scope.property_units.push(unit.unit);
                    }
                });
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }
            });
        };

        if (+$stateParams.id !== 0 && $stateParams.id !== '') {
            $scope.getProspect();
        }

        //messages stuff

        $scope.dialogueOpened = false;
        $scope.dialogueloading = false;
        $scope.dialogueSending = false;
        $scope.dialogueMessages = [];

        $scope.loadTemplates = function () {
            if ($rootScope.authUser.pro_messaging && $rootScope.authUser.permissions != 'tenant') {
                $http.post('/api/v1/message-template/get-message-templates').success(function (data) {
                    if (!data.success) {
                        toastr.error('Error retrieving Message Templates');
                        return;
                    }

                    // lets populate stuff
                    $scope.templates = data.data.templates;
                    $scope.templates_enabled = 1;
                });
            }
        };

        $scope.templateSelected = function (template) {
            if (template == null) {
                $scope.messageData.subject = '';
                $scope.messageData.body = '';
                return;
            }

            template.content = template.content.replace("{{firstname}}", $scope.prospect.first_name);
            template.content = template.content.replace("{{lastname}}", $scope.prospect.last_name);

            $scope.messageData.body = template.content; //replace removes html chars
            $scope.messageData.subject = template.subject;
        };

        $scope.templates_enabled = 0;
        $scope.templates = [];
        $scope.template = {};
        $scope.loadTemplates();

        $scope.removeFile = function (file) {
            $scope.myDropzone.removeFile(file);
        };

        $scope.processDropzoneFiles = function () {
            $scope.total_file_size = 0;
            angular.forEach($scope.attachments, function (file, key) {
                var fileReader = new FileReader();
                var base64;
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // base64 is the dataURL
                    file.dataURL = base64;
                };
                fileReader.readAsDataURL(file);
                $scope.total_file_size+= (file.size / 1000000);
            });
        };

        $scope.initDropzone = () => {
            $scope.myDropzone = new Dropzone('#real-dropzone', {
                url: '/api/v1/noreturn/',
                autoProcessQueue: false,
                uploadMultiple: true,
                parallelUploads: 100,
                maxFiles: 2,
                maxFilesize: 8,
                addRemoveLinks: false,
                dictRemoveFile: $scope.t('remove'),
                dictFileTooBig: $scope.t('File is bigger than 8MB'),

                // The setting up of the dropzone
                error: function (file, response) {
                    toastr.error(response);
                },
                addedfile: function (file, response) {
                    $timeout(function () {
                        $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                        $scope.processDropzoneFiles();
                    }.bind(vm));
                },
                removedfile: function (file, response) {
                    $timeout(function () {
                        $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                        $scope.processDropzoneFiles();
                    }.bind(vm));
                },
            });
        }
        if($scope.prospect.id>0){
            $timeout(function () {
                $scope.initDropzone();
            });
        }

        $scope.messageData = {
            subject: '',
            body: '',
        }

        $scope.getReplyId = () => {
            let lastId = 0;
            $scope.dialogueMessages.forEach(message => {
                if (message.from_id === 0) {
                    lastId = message.id;
                }
            });

            return lastId;
        }

        $scope.sendEmailMessage = () => {
            $scope.dialogueSending = true;
            let dataAttachments = [];

            angular.forEach($scope.attachments, function (file, key) {
                dataAttachments.push({
                    name: file.name,
                    data: file.dataURL
                });
            });

            let data = {
                data: [{
                    email: $scope.prospect.email,
                    first_name: $scope.prospect.first_name,
                    last_name: $scope.prospect.last_name,
                    property_name: $scope.prospect.property.name,
                    prospect_id: $scope.prospect.id,
                    reply_to: `prospect-${$rootScope.authUser.user_id}-${$scope.prospect.id}@{{domain}}`,
                    reply_id: $scope.getReplyId,
                    unit: null,
                    unit_id: 0,
                    unit_name: '',
                    user: {
                        email: $scope.prospect.email,
                        id: "pr_1"
                    },
                    user_id: "pr_1"
                }],

                subject: $scope.messageData.subject,
                body: $scope.messageData.body + '\n' + ($scope.accountInformation && $scope.accountInformation.manager ? $scope.accountInformation.manager.signature : ''),

                send_to: 1,
                recipients: '2',

                attachments: dataAttachments,
                scheduled_date: '',
                is_scheduled: 0,
                categoryId: 0,
            };

            messageService.sendAMessage(data)
                .success(function (data2) {
                    $scope.dialogueSending = false;
                    if (data2.success) {
                        toastr.info('Emails sent');
                        $scope.messageData.subject = '';
                        $scope.messageData.body = '';
                    } else {
                        toastr.error($scope.t('There was an error sending your message'));
                    }
                    $scope.loadDialog();
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $scope.dialogueSending = false;
                    toastr.error($scope.t('There was an error sending your message'));
                });
        }

        $scope.setTab = function(i) {
            $('.active').removeClass('active');
            $('#li'+i).addClass('active');

            $('#content1').hide();
            $('#content'+i).show();
        }
        $timeout(function () {
            if($stateParams.id == 0){
                $scope.setTab(2);
            }
        }, 1000);

        $scope.loadDialog = () => {
            $scope.dialogueloading = true;
            $http.get('/api/v1/messages/prospect/dialog/' + $scope.prospect.id).success(resp => {
                $scope.dialogueMessages = resp.data;
                $scope.dialogueloading = false;
            })
        }
        if($stateParams.id !== 0){
            $scope.loadDialog();
        }


        $scope.scrollToElement = (id) => {
            var hiddenElement = document.getElementById(id);
            hiddenElement.scrollIntoView();
        }

        $scope.$watch('dialogueloading', function() {
            $timeout(function () {
                var objDiv = document.getElementById("inbox");
                if(objDiv){
                    objDiv.scrollTop = objDiv.scrollHeight;
                }
            }, 500);
        });

        $scope.openAttachmentMobile = async (id) => {
            const {Filesystem, CapacitorHttp, FileOpener} = window.Capacitor.Plugins;
            toastr.info('Downloading attachment...');
            try {
                const response = await CapacitorHttp.get({
                    url: pqDomain + '/message-attachment/' + id + '?token=' + pqCachedToken,
                    responseType: 'blob'
                });

                console.log(response.headers)
                const contentType = response.headers['content-type'] || response.headers['Content-Type'] || '';
                console.log(contentType)
                console.log(contentType.split('/'))

                const fileExtension = contentType.split('/').pop(); // Determine file extension from content type

                const file = await Filesystem.writeFile({
                    path: `Payquad/prospects/message_attachment_${id}.${fileExtension}`,
                    data: response.data,
                    directory: 'DOCUMENTS',
                    recursive: true
                });

                await FileOpener.open({
                    filePath: file.uri,
                    contentType: contentType
                });
            } catch (error) {
                console.error('Error opening file', error);
            }
        }

        $scope.attachMobile = async (file) => {
            $scope.mobileFile = await window.Capacitor.Plugins.FilePicker.pickFiles({
                limit: 1,
                readData: true,
            });

            if (!$scope.attachments) {
                $scope.attachments = [];
            }
            $scope.attachments.push({
                name: $scope.mobileFile.files[0].name,
                dataURL: $scope.mobileFile.files[0].data
            })
        }

    }
}());
