(function() {
    'use strict';

    angular
        .module('app')
        .controller('DocumentCreateController', documentCreateController);

    function documentCreateController($scope,$http,$state,$rootScope,$timeout) {
        //if ($rootScope.authUser.permissions == 'tenant' || $rootScope.authUser.permissions == 'junior_manager') {
        if ($rootScope.authUser.permissions == 'tenant') {
            $state.go('main.documents');
        }

        $scope.loading_spinner=0;
        $scope.realtors_enabled = 0;
        $scope.isNative = false;

        $timeout(function(){
            $scope.isNative = window.Capacitor.isNativePlatform()
        }, 1000);

        $scope.document = {
            name: '',
            description: '',
            categories: [],
            company_id: 0,
            payment_required: 'no',
            type: 'regular',
            payment_amount: '',
            send_notifications: 0,
            permissions: {
                by_properties: 0,
                all_properties: 1,
                properties: [],
                by_managers: 0,
                all_managers: 1,
                managers: [],
                by_tenants: 0,
                all_tenants: 1,
                tenants: [],
                by_realtors: 0,
                all_realtors: 1,
                realtors: []
            }
        };

        $scope.dropdowns = {
            companies: {},
            categories: [],
            properties: [],
            managers: [],
            tenants: [],
            realtors: []
        };

        $scope.getCategories = function() {
            $http.post('/api/v1/document/get-categories', {company_id: $scope.document.company_id}).success(function(data) {
                $scope.dropdowns.categories = data.data.categories;
                $scope.document.categories = [];
                $timeout(function(){
                    if($('select#categories + .ms-parent')){
                        $('select#categories').multipleSelect("refresh");
                    }else{
                        $('select#categories').multipleSelect({filter: true, width: 350 });
                    }

                });
            });
        };

        $scope.getProperties = function() {
            $http.post('/api/v1/document/get-properties', {company_id: $scope.document.company_id}).success(function(data) {
                $scope.dropdowns.properties = data.data.properties;
                $timeout(function(){
                    $('select#properties').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        $scope.getManagers = function() {
            $http.post('/api/v1/document/get-managers', {company_id: $scope.document.company_id}).success(function(data) {
                $scope.dropdowns.managers = data.data.managers;
                $timeout(function(){
                    $('select#managers').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        $scope.getTenants = function() {
            $http.post('/api/v1/document/get-tenants', {company_id: $scope.document.company_id, permissions: $scope.document.permissions}).success(function(data) {
                $scope.dropdowns.tenants = data.data.tenants;
                $timeout(function(){
                    if($('div.tenants-select').length){
                        $('select#tenants').multipleSelect("refresh");
                    }else{
                        $('select#tenants').multipleSelect({filter: true, width: 350 });
                    }
                }, 0);
            });
        };

        $scope.getRealtors = function() {
            $http.post('/api/v1/document/get-realtors', {company_id: $scope.document.company_id}).success(function(data) {
                if (!data.success) {
                    $scope.realtors_enabled = 0;
                    return;
                }
                $scope.realtors_enabled = 1;
                $scope.dropdowns.realtors = data.data.realtors;
                $timeout(function(){
                    $('select#realtors').multipleSelect({filter: true, width: 350 });
                });
            });
        };

        if ($rootScope.authUser.permissions == 'payquad_admin') {
            $http.post('/api/v1/document/get-companies').success(function(data) {
                $scope.dropdowns.companies = data.data.companies;
                $scope.document.company_id = $scope.dropdowns.companies[0].id;
                $scope.getCategories();
                $scope.getProperties();
                $scope.getManagers();
                $scope.getTenants();
                $scope.getRealtors();
            });
        } else {
            $scope.getCategories();
            $scope.getProperties();
            $scope.getManagers();
            $scope.getTenants();
            $scope.getRealtors();
        }

        $scope.companyChanged = function(){
            $scope.getCategories();
            $scope.getProperties();
            $scope.getManagers();
            $scope.getTenants();
            $scope.getRealtors();
        };

        $scope.mobileFile = null;
        $scope.chooseFileMobile = async function () {
            $scope.mobileFile = await window.Capacitor.Plugins.FilePicker.pickFiles({
                limit: 1,
                readData: true,
            });
            console.log($scope.mobileFile);
        }

        $scope.createDocument = function() {
            if ($scope.isNative == false && $scope.document.type == 'regular' && document.getElementById('file1').files.length == 0) {
                toastr.error("Please choose a file to upload");
                return;
            }

            if ($scope.isNative && $scope.document.type == 'regular' && !$scope.mobileFile) {
                toastr.error("Please choose a file to upload");
                return;
            }

            if ($scope.document.permissions.by_realtors == 1 && $scope.document.permissions.by_properties == 0) {
                toastr.error("When choosing Realtor/Agent Permissions, you must set property based permisssions as well");
                return;
            }

            $scope.document.document = !$scope.isNative
                ? document.getElementById('file1').files[0]
                : $scope.mobileFile.files[0].data;

            $scope.creatingDocument = true;

            let data = new FormData();
            // data.append('document', !$scope.isNative
            //     ? document.getElementById('file1').files[0]
            //     : $scope.mobileFile.files[0].data);
            data.append('name', $scope.document.name);
            data.append('description', $scope.document.description);
            data.append('send_notifications', $scope.document.send_notifications);
            data.append('categories', $scope.document.categories);
            data.append('by_properties', $scope.document.permissions.by_properties);
            data.append('all_properties', $scope.document.permissions.all_properties);
            data.append('properties', $scope.document.permissions.properties);
            data.append('by_managers', $scope.document.permissions.by_managers);
            data.append('all_managers', $scope.document.permissions.all_managers);
            data.append('managers', $scope.document.permissions.managers);
            data.append('by_tenants', $scope.document.permissions.by_tenants);
            data.append('all_tenants', $scope.document.permissions.all_tenants);
            data.append('tenants', $scope.document.permissions.tenants);
            data.append('by_realtors', $scope.document.permissions.by_realtors);
            data.append('all_realtors', $scope.document.permissions.all_realtors);
            data.append('realtors', $scope.document.permissions.realtors);
            data.append('company_id', $scope.document.company_id);

            if ($scope.document.payment_required == 'no') {
                $scope.document.payment_amount = '';
            }
            data.append('type', $scope.document.type);
            data.append('payment_required', $scope.document.payment_required);
            data.append('payment_amount', $scope.document.payment_amount);

            $scope.loading_spinner=1;

            console.log(data, $scope.document)

            $http
                .post('/api/v1/document/create',data, {transformRequest: angular.identity, headers: {'Content-Type': undefined}})
                .success(function(data) {
                    if (!data.success) {
                        toastr.error(data.errors,"Error creating document");
                        $scope.loading_spinner=0;
                        return;
                    }

                    $scope.loading_spinner=0;
                    $scope.creatingDocument = false;
                    $rootScope.goToAngular2('documents/list');
                    toastr.success('Document created');
                }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingDocument = false;
                $scope.loading_spinner=0;

                if (typeof data == 'string' && data.includes('413') == true) {
                    toastr.error('Error uploading document, too large.');
                } else {
                    toastr.error(data.error?.message, 'Error encountered creating document');
                }
            });
        };
    }
}());
