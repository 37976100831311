(function () {
    'use strict';

    angular
        .module('app')
        .controller('CreditCheckEditController', creditCheckEditController);

    function creditCheckEditController($scope, $http, $state, $stateParams, $rootScope, $timeout, langService) {
        $scope.t = langService.t('credit_check');

        $scope.credit_check = {
            id: parseInt($stateParams.id),
            name: '',
            description: '',
        };

        $scope.properties = [];
        $scope.getProperties = function(){
            $http.post('/api/v1/credit-check/get-properties-for-edit').success(function(data) {
                if (!data.success) {
                    if (typeof data.message !== 'undefined') {
                        // if data.message contains 'junior manager'
                        if (data.message.indexOf('junior manager') > -1) {
                            toastr.error('You\'re account does not have access to credit checks');
                            $state.go('main.dashboard', { redirect : true });
                            return 1;
                        } else {
                            toastr.error(data.message);
                        }
                    } else {
                        toastr.error('Error getting properties');
                    }
                    return 1;
                }

                $scope.properties = data.data.properties;
                $scope.getCreditCheck();
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };
        $scope.getProperties();

        $scope.getCreditCheck = function () {
            if ($scope.credit_check.id == 0) {
                $scope.initDropdown();
                return;
            }
            $http.post('/api/v1/credit-check/get/' + $scope.credit_check.id).success(function (data) {
                if (!data.success) {
                    $state.go('main.creditChecks');
                }
                
                const credit_check = data.data.credit_check;
                $scope.credit_check = credit_check;
                $scope.credit_check.born_at = moment($scope.credit_check.born_at).format('MM/DD/YYYY');

                $scope.initDropdown();
                $scope.getProvinces();
            });
        };

        $scope.initDropdown = function () {
            setTimeout(function () {
                $('#dob-date').datepicker({
                    changeMonth: true,
                    changeYear: true,
                    yearRange: '-100:+0',
                });
                $('select#properties').multipleSelect({
                    single: true,
                    filter: true,
                    width: '401px',
                    dropWidth: '330px',
                });
            });
        };

        $scope.deleteCreditCheck = function () {
            if (!confirm('Are you sure you want to delete this credit check?')) {
                return; // they did not confirm
            }

            $scope.creatingCreditCheck = true;

            $http.post('/api/v1/credit-check/delete/' + $scope.credit_check.id).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors, 'Error deleting credit check');
                    return;
                }

                $scope.creatingCreditCheck = false;
                $state.go('main.creditChecks');
                toastr.success('Credit check deleted');
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.creatingCreditCheck = false;
                toastr.error(data.error.message);
            });
        };

        $scope.updateCreditCheck = function (generate = 0) {
            $scope.creatingCreditCheck = true;
            const url = $scope.credit_check.id ? '/api/v1/credit-check/update/' + $scope.credit_check.id : '/api/v1/credit-check/create';

            $http.post(url, $scope.credit_check)
                .success(function (data) {
                    if (!data.success) {
                        toastr.error(data.errors, 'Error saving credit check');
                        $scope.creatingCreditCheck = false;
                        return;
                    }


                    if (generate) {
                        $scope.credit_check = data.data.credit_check;
                        $scope.doGenerateCreditCheck();
                    } else {
                        $scope.creatingCreditCheck = false;
                        toastr.success('Credit check saved');
                        $state.go('main.creditChecks');
                    }
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $scope.creatingCreditCheck = false;
                    toastr.error(data.error.message);
                });
        };

        $scope.provinces = {};
        $scope.getProvinces = function () {
             $http.post('/api/v1/countrystate/get-provinces/' + $scope.credit_check.country).success(function(data) {
                 $scope.provinces = data.data.provinces;
             });
        };

        $scope.generateCreditCheck = function () {
            $scope.updateCreditCheck(1);
        };

        $scope.doGenerateCreditCheck = function () {
            $scope.creatingCreditCheck = true;
            const url = '/api/v1/credit-check/generate/' + $scope.credit_check.id;

            $http.post(url, $scope.credit_check)
                .success(function (data) {
                    if (!data.success) {
                        if (typeof data.information != 'undefined') {
                            toastr.error(Object.values(data.information)[0][0], 'Error generating credit check');
                        } else {
                            toastr.error(data.errors, 'Error generating credit check');
                        }
                        $scope.creatingCreditCheck = false;
                        return;
                    }

                    $scope.credit_check = data.data.credit_check;
                    $scope.credit_check.born_at = moment($scope.credit_check.born_at).format('MM/DD/YYYY');
                    // 3 second pause before proceeding
                    $timeout(function () {
                        $scope.creatingCreditCheck = false;
                        toastr.success('Credit check generated');
                    }, 3000);
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $scope.creatingCreditCheck = false;
                    toastr.error(data.error.message);
                });
        };

        $scope.downloadCreditCheck = function (){
            toastr.info('Your report is downloading. This may take a few moments.');

            const url = '/credit-check-download/'+ $scope.credit_check.id;

            $http.get(url)
                .success(function (data) {
                    if (data.code==404) {
                        toastr.error(data.message || 'Error downloading credit check');
                        return;
                    }
                    window.open(url, '_blank');
                    toastr.success('Credit check downloaded');
                })
                .error(function (data) {
                    toastr.error(data.errors.message);
                });
        }
    }
}());
