(function () {

    'use strict';

    angular
        .module('app')
        .controller('SurveyEditController', function (
            $scope, $rootScope, $timeout, resolvedProperties, surveyService, resolvedEvents,
            resolvedSurvey, $state, helperService, langService
        ) {
            $scope.t = langService.t('surveys');
            $scope.selectedEvent = null;
            $scope.events = resolvedEvents;
            const areYouSureMessage = 'Are you sure that you want to send this survey out to all tenants?';
            if (resolvedSurvey) {
                $scope.survey = resolvedSurvey;
                $scope.survey.properties = $scope.survey.properties.map(item => item.pivot.property_id);
                if ($scope.survey.expiry_date) {
                    $scope.survey.expiry_date = helperService.mysqlDateToDate($scope.survey.expiry_date);
                }
                $scope.survey.events = $scope.survey.events.map(item => {
                    item['title'] = resolvedEvents[item.event];
                    return item;
                });
            } else {
                $scope.survey = {
                    isPublish: false,
                    name: '',
                    description: '',
                    expiry_date: '',
                    is_public: false,
                    properties: [],
                    questions: [],
                    events: [],
                };
            }

            $scope.types = [
                {id: 'Free text', value: $scope.t('free_text')},
                {id: 'Multiple choice', value: $scope.t('multiple_choice')},
                {id: 'Yes/No', value: $scope.t('yes_no')},
                {id: 'Rating', value: $scope.t('rating')},
                {id: 'Cost Adder', value: $scope.t('cost_adder')},
                // {id: 'Cost Adder', value: 'Cost Adder'},
            ];
            $scope.properties = resolvedProperties.data.data;
            $timeout(function () {
                $('select#properties').multipleSelect({filter: true, width: 350, placeholder: $scope.t('properties')});
            });


            $scope.addQuestion = () => {
                $scope.survey.questions.push({
                    type: 'Free text',
                    question: '',
                    variants: [
                        {
                            variant: ''
                        }
                    ],
                    order: $scope.survey.questions.length ? Math.max(...$scope.survey.questions.map(q => q.order)) + 1 : 1,
                });
            };

            $scope.addOption = index => {
                $scope.survey.questions[index].variants.push({variant: ''});
            };

            $scope.removeQuestion = index => {
                $scope.survey.questions.splice(index, 1);
            };

            $scope.removeOption = (indexQuestion, index) => {
                $scope.survey.questions[indexQuestion].variants.splice(index, 1);
            };

            $scope.save = (form, isPublish, isEmail) => {
                form['survey.name'].$setTouched();
                form['survey.description'].$setTouched();
                form['survey.properties'].$setTouched();
                if (!$scope.survey.properties.length) {
                    form['survey.properties'].$setValidity('validatedFromController', false);
                    form['survey.properties'].$setTouched();
                } else {
                    form['survey.properties'].$setValidity('validatedFromController', true);
                }
                if (!$scope.survey.questions.length) {
                    toastr.error('You must add at least one question.');
                    return;
                } else {
                    let errorMessage = '';
                    $scope.survey.questions.forEach(item => {
                        if (!item.question) {
                            errorMessage = 'All question should be filled.';
                        } else if (item.type === 'Multiple choice') {
                            if (!item.variants.length) {
                                errorMessage = 'Questions with multiple choice should have at least one variant of the answer.';
                            } else {
                                item.variants.forEach(variant => {
                                    if (!variant.variant) {
                                        errorMessage = 'All variants of the question\'s answers should be filled.';
                                    }
                                });
                            }
                        }
                    });
                    if (errorMessage) {
                        toastr.error(errorMessage);
                        return;
                    }
                }

                if (form.$invalid) {
                    return;
                }
                if (isEmail) {
                    if (!confirm(areYouSureMessage)) {
                        return;
                    }
                }
                $scope.survey.isPublish = isPublish;
                $scope.survey.isEmail = isEmail;
                if ($scope.survey.id) {
                    surveyService.edit($scope.survey).then(data => {
                        toastr.success('Survey saved');
                        $rootScope.goToAngular2('surveys/list');
                    },
                    error => {
                        toastr.error('Error saving survey');
                    });
                } else {
                    surveyService.create($scope.survey).then(data => {
                        if(isPublish && isEmail){
                            toastr.success('Survey has been published and emailed to all selected tenants');
                        }else if(isPublish){
                            toastr.success('Survey has been published');
                        }else{
                            toastr.success('Survey saved');
                        }
                        $rootScope.goToAngular2('surveys/list');
                    },
                    error => {
                        toastr.error('Error saving survey');
                    });
                }
            };

            $scope.togglePublish = () => {
                const isPublished = $scope.survey.status === 'published';
                if (isPublished) {
                    toastr.success('Survey has been unpublished');
                    surveyService.unpublish($scope.survey.id).then($state.reload());
                } else {
                    toastr.success('Survey has been published');
                    surveyService.publish($scope.survey.id).then(() => {
                        $rootScope.goToAngular2('surveys/list');
                    });
                }
            };

            $scope.publishAndSend = () => {
                if (confirm('Are you sure that you want to send this survey out to all tenants?')) {
                    toastr.success('Survey has been published and emailed to all selected tenants');
                    surveyService.publishAndSend($scope.survey.id).then(() => {
                        $rootScope.goToAngular2('surveys/list');
                    });
                }
            };

            $scope.delete = () => {
                if (confirm('Are you sure that you want to delete this survey?')) {
                    surveyService.remove($scope.survey.id).then($rootScope.goToAngular2('surveys/list'));
                    toastr.success('Survey has been deleted');
                }
            };

            $scope.hasError = obj => {
                return obj && obj.$touched && obj.$error.constructor === Object && Object.keys(obj.$error).length !== 0;
            };

            $scope.addEvent = () => {
                if ($scope.selectedEvent) {
                    if (!$scope.survey.events.find(item => item.event === $scope.selectedEvent)) {
                        $scope.survey.events.push({
                            event: $scope.selectedEvent,
                            title: $scope.events[$scope.selectedEvent]
                        });
                    } else {
                        toastr.error('You already added this event.');
                    }
                }
            };

            $scope.deleteEvent = index => {
                $scope.survey.events.splice(index, 1);
            };

            $scope.moveUpQuestion = function (index) {
                $scope.moveQuestion(index, 'up');
            };

            $scope.moveDownQuestion = function (index) {
                $scope.moveQuestion(index, 'down');
            };

            $scope.moveQuestion = function (index, direction) {
                if (direction === 'up' && index > 0) {
                    // Swap the current question with the previous one
                    [$scope.survey.questions[index - 1], $scope.survey.questions[index]] = [$scope.survey.questions[index], $scope.survey.questions[index - 1]];
                } else if (direction === 'down' && index < $scope.survey.questions.length - 1) {
                    // Swap the current question with the next one
                    [$scope.survey.questions[index], $scope.survey.questions[index + 1]] = [$scope.survey.questions[index + 1], $scope.survey.questions[index]];
                }
                // Update the `order` property for all questions
                $scope.survey.questions.forEach((q, i) => {
                    q.order = i + 1;
                });
            };

            $scope.goBack = () => {
                $rootScope.goToAngular2('surveys/list');
            }
        });
}());
