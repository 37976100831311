(function () {

    'use strict';

    angular
        .module('app')
        .factory('brandingService', brandingService);

    function brandingService($http, $rootScope) {
        return {
            get: (applicationId = null, propertyId = null, domain = null) => {
                const params = {cordova: window.Capacitor.isNativePlatform()? 1 : 0};
                if (applicationId) {
                    params['application_id'] = applicationId;
                }
                if (propertyId) {
                    params['property_id'] = propertyId;
                }
                if (!domain) {
                    domain = '';
                }
                const paramsStr = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
                const cacheKey = `cachedBranding-${applicationId}-${propertyId}`;

                const promise = new Promise((resolve, reject) => {
                    window.Capacitor.Plugins.Network.getStatus().then(status => {
                        if (status.connected) {
                            $http.get(`${domain}/api/v1/branding?asd&${paramsStr}`).then(r => {
                                r = r.data;
                                window.Capacitor.Plugins.Preferences.set({
                                    key: cacheKey,
                                    value: JSON.stringify(r.data)
                                });
                                setFavicon(r.data.favicon_url);
                                setTitle(r.data.name);
                                $rootScope.branding = r.data;
                                resolve(r);
                            }).catch(() => reject('Branding error!'));
                        } else {
                            window.Capacitor.Plugins.Preferences.get({ key: cacheKey }).then(cachedData => {
                                if (cachedData.value) {
                                    const cachedBranding = JSON.parse(cachedData.value);
                                    setFavicon(cachedBranding.favicon_url);
                                    setTitle(cachedBranding.name);
                                    $rootScope.branding = cachedBranding;
                                    resolve({ data: cachedBranding });
                                } else {
                                    reject('Offline and no cached data available!');
                                }
                            });
                        }
                    });
                });

                return promise;
            }
        };
    }

    function setFavicon(url) {
        // console.log(url);
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = url;
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    function setTitle(title) {
        const titleEl = document.querySelector("title");
        titleEl.innerText = title;
        document.getElementsByTagName('head')[0].appendChild(titleEl);
    }

}());
