(function () {

    'use strict';

    angular
        .module('app')
        .controller('ReportController', ReportController);

    function ReportController($scope, $state, resolvedFilters, resolvedTerminals, resolvedAmenities, resolvedStatuses, $http, $timeout, $rootScope, $location, $stateParams, $filter) {
        $.fn.dataTable.ext.errMode = 'throw';
        $scope.filters = resolvedFilters;
        $scope.amenities = resolvedAmenities;
        $scope.statuses = resolvedStatuses;
        $scope.terminals = resolvedTerminals;
        $scope.filter = null;
        $scope.report = null;
        $scope.terminal = null;
        $scope.dateFrom = '';
        $scope.dateTo = '';
        $scope.searchableInitialized = 0;
        $scope.reportData = [];
        $scope.isPayquad = 0;
        $scope.emailsActive = '';
        $scope.insuranceEnabled = '1';
        $scope.insuranceType = 'tenant';
        $scope.isCordova = Boolean(window.Capacitor.isNativePlatform());

        // Load a blank report template to avoid issues PD-1377 and PD-1374
        $state.go('main.reports.initial-blank');

        $scope.lmrSettings = {
            period: 'next_month_only',
            deleted: 'deleted_and_will_be_deleted',
        }

        if ($stateParams.reportID) {
            $scope.report = $stateParams.reportID;
            if ($scope.report === 'rent-roll') {
                $timeout(function () {
                    $('#fromDate').val(moment().format('YYYY-MM-DD'));
                    $scope.dateFrom = moment().format('YYYY-MM-DD');
                }, 1000);
            }
        }


        $scope.reportDescriptions = {
            'amenity-report': 'Provides a summary of amenity bookings',
            'user-report': 'Provides a summary of total users with their current status',
            'paymentMethods-report': 'Provides a summary of users by payment method type',
            'nsf-report': 'Provides a summary of declined payments and amounts',
            'master-report': 'Provides a summary of payments by method, status, and property',
            'client-billing-report': 'Internal billing report for number of client transactions',
            'recurring-report': 'Provides a summary of active recurring payments',
            'arrears-report': 'Provides a summary of users with no payments in a date range',
            'financial-report': 'Provides a summary of total fees and transactions by payment method',
            'deposit-report': 'Provides a summary of daily bank deposits',
            'merchant-report': 'Provides a summary of merchant account payments by terminal',
            'insurance-report': 'Provides a summary of tenants / units by insurance status',
            'yardi_genesis-report': 'Provides a Yardi Genesis report for export',
            'yardi_genesis_2-report': 'Provides a Yardi Genesis II report for export',
            'application-report': 'Provides a summary of lease applications',
            'prospect-report': 'Provides a summary of lease prospects',
            'rent-roll-report': 'Provides a summary of rent charges by property and unit',
            'emails-report': 'Provides a summary of tenants contact info',
            'marketing-report': 'Provides a summary of contacts who are opt-in for marketing',
            'credit_checks-report': 'Provides a summary of completed credit checks',
            'lmr-report': 'Provides a summary of tenants with move out dates in next month and payment schedules are ending',
            'parking-report': 'Provides a summary of rented parking spots',
            'splitpay-report': 'Provides a summary of users with rent splits',
            'prompt-payment-report': 'Provides a summary of payment bounces that lapse the prompt payment discount',
            'new-tenants-without-pad-report': 'Provides a list of tenants who moved in within the last 60 days who have not been set up on PAD',
        }

        $scope.url = '';

        $timeout(function () {
            $('#report_filter').multipleSelect({filter: true});
            $('#amenity_filter').multipleSelect({filter: true});
            $('#status_filter').multipleSelect({filter: true});
        });


        const TIMEOUT_BEFORE_SEND_TO_QUEUE = 15000; // in milliseconds
        const TIMEOUT_EXPORT_BEFORE_SEND_TO_QUEUE = 120000; // in milliseconds

        const postUrlObjAsUrlEncoded = (url, config) =>
            $http.post(
                url.protocol + '//' + url.host + url.pathname,
                Object.fromEntries(url.searchParams.entries()),
                {
                    headers: {
                        'Content-Type':
                            'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    transformRequest: params =>
                        Object.entries(params).map(([k,v]) =>
                            encodeURIComponent(k) + "="
                                + encodeURIComponent(v)
                        ).join('&'),
                    ...config
                }
            );

        const handleReportReqFail = (url) => {
            $scope.hideWaiting();
            if ($scope.report == 'splitpay') {
                toastr.info('We will email you with requested report. It may take up to 10 mins', 'Data Is Too Large');

                url.pathname = '/api/v1/reports/splitpay-queue';
                postUrlObjAsUrlEncoded(url).then(rows => {
                    toastr.success('Email scheduled',
                        'Data Is Too Large');
                }).catch(r => {
                    toastr.error('Report failed. Please try again');
                });
            } else {
                toastr.error('Report failed. Please try again');
            }
        };

        const downloadCSVFrom = (url, name = 'csv') =>
            postUrlObjAsUrlEncoded(
                url,
                {
                    timeout: TIMEOUT_EXPORT_BEFORE_SEND_TO_QUEUE,
                    responseType: 'text'
                }
            ).then(r => {
                $(`#${$scope.report}-report-table table`)
                    .DataTable().clear().destroy();
                $(`#${$scope.report}-report-table table`).DataTable();

                const blob = new Blob([r.data], { type: 'text/csv' });
                const url  = window.URL.createObjectURL(blob);
                const when = $filter('date')(new Date, 'yyyy-MM-dd');
                const link = document.createElement('a');
                link.href = url;
                link.download = `${name}-${when}.csv`;
                link.click();

                $timeout(() => $scope.hideWaiting(), 1500);
            }).catch(() => handleReportReqFail(url));

        $scope.generateReport = isExport => {
            if (!$scope.report) {
                toastr.error('Please select report type');
                return;
            }
            if ($scope.report === 'merchant') {
                if (!$scope.terminal.length) {
                    toastr.error('Please select terminal');
                    return;
                }
                $scope.terminal = [...new Set($scope.terminal)];
                if($scope.terminal.length > 1){
                    toastr.error('Only 1 terminal can be selected at one time');
                    return;
                }
                window.location = pqDomain + '#/merchant-account-summary/' + $scope.terminal;
                return;
            }
            if (($scope.report === 'yardi_genesis' || $scope.report === 'yardi_genesis_2') && $scope.report === 'a0' && isExport) {
                toastr.error('Please select a property');
                return false;
            }

            const url = new URL(
                !!window.Capacitor.isNativePlatform() ?  pqDomain : (window.location.protocol + '//' + window.location.host)
            );
            const params = url.searchParams;

            if ($scope.report === 'deposit') {
                url.pathname = `/api/v1/reports/new-deposit/${$scope.terminal}`;
                params.set('a', 'b');
            }
            else if ($scope.report === 'lmr') {
                url.pathname = '/api/v1/reports/lmr';
                Object.entries($scope.filter||{})
                    .forEach(([k, v]) => params.set(`filter[${k}]`, v));
                params.set('period',  $scope.lmrSettings.period);
                params.set('deleted', $scope.lmrSettings.deleted);
            }
            else {
                url.pathname = `/api/v1/reports/${$scope.report}`;

                Object.entries($scope.filter||{})
                    .forEach(([k, v]) => params.set(`filter[${k}]`, v));

                if ($scope.report === 'amenity') {
                    Object.entries($scope.amenity_filter)
                        .forEach(([k, v]) => params.set(`amenity[${k}]`, v));
                }

                if ($scope.report === 'application') {
                    Object.entries($scope.status_filter)
                        .forEach(([k, v]) => params.set(`status[${k}]`, v));
                }

                params.set('fromDate',  $scope.dateFrom);
                params.set('toDate',    $scope.dateTo);
                params.set('isPayquad', $scope.isPayquad);
                params.set('active',    $scope.emailsActive);

                if ($scope.report === 'insurance') {
                    params.set('enabled', $scope.insuranceEnabled);
                    params.set('type', $scope.insuranceType);
                }

                if ($scope.report === 'rent-roll') {
                    params.set('rentRollDateFrom', $scope.rentRollDateFrom);
                    params.set('rentRollDateTo',   $scope.rentRollDateTo);
                }
            }

            $scope.url = url;
            $scope.showWaiting();

            if ($scope.report === 'insurance' && $scope.insuranceType === 'unit') {
                $state.go(`main.reports.insurance-unit`).then(() => {
                    if (isExport) {
                        params.set('export', 'true');
                        return downloadCSVFrom(url, $scope.report);
                    } else {
                        $scope.processFrontendPaginated(url)
                    }
                });
            } else {
                $state.go(`main.reports.${$scope.report}`).then(() => {
                    if (isExport) {
                        params.set('export', 'true');
                        return downloadCSVFrom(url, $scope.report);
                    }
                    else if ($scope.report === 'recurring'
                        || $scope.report === 'paymentMethods'
                        || $scope.report === 'master'
                    ) {
                        $scope.processBackendPaginated(url);
                    } else {
                        $scope.processFrontendPaginated(url)
                    }
                });
            }
        };

        $scope.dataTableConfigs = {
            recurring: {
                columnDefs: [],
                order: [[ 2, "asc" ]]
            },
            paymentMethods: {
                columnDefs: [
                    {
                        orderable: false,
                        targets: [-1, -2, -3, -4, -5]
                    }
                ],
                order: [[ 0, "desc" ]]
            },
            master: {
                columnDefs: [],
                order: [[ 4, "desc" ]]
            },
            deposit: {
                order: [[ 1, "desc" ]]
            },
        };

        $scope.processBackendPaginated = (url) => {
            $scope.hideWaiting();
            $('#' + $scope.report + '-report').css('visibility', 'visible').fadeIn();
            toggleReportView($scope.report + '-report-table');
            $(`#${$scope.report}-report-table table`).DataTable().clear().destroy();
            $timeout(() => {
                $(`#${$scope.report}-report-table table`).DataTable({
                    "processing": true,
                    "serverSide": true,
                    ...$scope.dataTableConfigs[$scope.report],
                    "ajax": {
                        "url": pqDomain + url.pathname + '-paginated'  + '?cordova='+(!!window.Capacitor.isNativePlatform() ? '1':'0') + '&token=' + pqCachedToken,
                        "type": "POST",
                        "data": Object.fromEntries(url.searchParams.entries())
                    }
                });
            }, 200);
            let evalLocalSum = $scope.$eval('process' + $scope.report);

            if (evalLocalSum) {
                evalLocalSum();
            }
        }

        $scope.processFrontendPaginated = (url) => {
            postUrlObjAsUrlEncoded(
                url, { timeout: $scope.report === 'splitpay'
                        ? TIMEOUT_BEFORE_SEND_TO_QUEUE : 30000 }
            ).then(r => r.data)
             .then(rows => {
                //defined in view
                latestReportData = rows;

                const initFnName = `${$scope.report}ReportInit`;
                $scope.dt = null;
                if (window[initFnName]) {
                    //old code with jQuery
                    window[initFnName](rows);
                } else {
                    $(`#${$scope.report}-report-table table`).DataTable().clear().destroy();
                    $scope.reportData = rows;
                    $timeout(() => {
                        if ($scope.report === 'rent-roll'){
                            $scope.dt = $(`#${$scope.report}-report-table table`).DataTable({
                                columnDefs: [
                                    {
                                        targets: 0,
                                        defaultContent: '',
                                        orderable: false,
                                        className: 'select-checkbox'
                                    }
                                ],
                                select: {
                                    selector: 'td:first-child',
                                    style: 'multi'
                                },
                            });
                        } else {
                            $(`#${$scope.report}-report-table table`).DataTable();
                        }
                    }, 200);
                }
                $scope.hideWaiting();
                setTimeout(() => {
                    $('#' + $scope.report + '-report').css('visibility', 'visible').fadeIn();
                }, 500);
            }).catch(() => handleReportReqFail(url));
        }

        $scope.showWaiting = () => {
            $('.tab-pane').hide();
            $('button').attr('disabled', 'disabled');
            $('#loader').fadeIn('slow');
        };

        $scope.hideWaiting = () => {
            $('button').removeAttr('disabled');
            $('#loader').fadeOut('fast');
        };

        $scope.reportChanged = () => {
            if ($scope.searchableInitialized == 1) {
                return;
            }

            if ($scope.report === 'rent-roll') {
                $state.go(`main.reports`, {
                    reportID: 'rent-roll'
                },
                    {notify: true}
                )
            }

            $timeout(function () {
                $('select#terminal_id').multipleSelect({filter: true, width: '98%'});
                $scope.searchableInitialized = 1;
        });
        };

        $(document).ajaxError(function () {
            if (!$scope.isCordova) {
                $scope.hideWaiting();
                alert('error generating report');
            }
        });

        $scope.messageArrears = () => {
            const tenant_ids = $('.arrears-mass-message-ids:checkbox:checked').map(function() {
                return this.value; // eslint-disable-line angular/controller-as-vm
            }).get();

            console.log(tenant_ids);
            // check if empty
            if (tenant_ids === undefined || tenant_ids.length === 0) {
                toastr.error('You must select at least one tenant for messaging');
                return;
            }

            $('#arrears-message-modal').appendTo("body").modal('show');
        }

        $scope.all_arrears_selected = false;
        $scope.selectAllArrears = () => {
            if ($scope.all_arrears_selected) {
                $('.arrears-mass-message-ids:checkbox').prop('checked', false);
                $scope.all_arrears_selected = false;
            } else {
                $('.arrears-mass-message-ids:checkbox').prop('checked', true);
                $scope.all_arrears_selected = true;
            }
        }

        $scope.all_deposit_selected = false;
        $scope.selectAllDeposits = () => {
            if ($scope.all_deposit_selected) {
                $('.deposit-mass-export-ids:checkbox').prop('checked', false);
                $scope.all_deposit_selected = false;
            } else {
                $('.deposit-mass-export-ids:checkbox').prop('checked', true);
                $scope.all_deposit_selected = true;
            }
        }

        $scope.arrears_message = '';
        $scope.arrears_subject = '';
        $scope.arrears_message_sending = 0;
        $scope.sendArrearsMessage = () => {
            const tenant_ids = $('.arrears-mass-message-ids:checkbox:checked').map(function() {
                return this.value; // eslint-disable-line angular/controller-as-vm
            }).get();

            const subject = $scope.arrears_subject;
            if (subject === '') {
                toastr.error('Please enter a subject');
                return;
            }
            const message = $scope.arrears_message;
            if (message === '') {
                toastr.error('Please enter a message');
                return;
            }

            // confirm first
            if (!confirm('Are you sure you want to send this message to ' + tenant_ids.length + ' tenants?')) {
                return;
            }

            $scope.arrears_message_sending = 1;
            $http.post('/api/v1/reports/arrears-mass-message', {
                tenant_ids: tenant_ids,
                subject: subject,
                message: message
            }).success(r => {
                $('#arrears-message-modal').modal('hide');
                $('.arrears-mass-message-ids:checkbox:checked').prop('checked', false);
                toastr.success('Messages sent');
                $scope.arrears_message_sending = 0;
            }).error(r => {
                toastr.error('Error sending messages');
                $scope.arrears_message_sending = 0;
            });
        }

		$scope.bulkExport = type => {
            const terminal_id = $scope.terminal;
            const deposit_ids = $('.deposit-mass-export-ids:checkbox:checked').map(function() {
                return this.value; // eslint-disable-line angular/controller-as-vm
            }).get();

            //console.log(deposit_ids);
            //return;

			if (deposit_ids === undefined || deposit_ids.length === 0) {
                toastr.error('You must select at least one deposit from below for exporting');
                return;
            }

			location.href = '/api/v1/reports/deposit-bulk-export?terminal_id=' + terminal_id + '&deposit_ids=' + deposit_ids + '&type=' + type;
        }

        $scope.processrecurring = () => {
            postUrlObjAsUrlEncoded($scope.url).success(r => {
                $scope.latestReportData = r;
                latestReportData = r;


                const initFnName = `${$scope.report}ReportInit`;
                if (window[initFnName]) {
                    //old code with jQuery
                    window[initFnName](r);
                }
            })
        }

        $scope.processpaymentMethods = $scope.processrecurring;
        $scope.processmaster = $scope.processrecurring;


        // start rent-roll
        $scope.rentRollDateFrom = '';
        $scope.rentRollDateTo = '';

        $scope.sendNotice = rentRollRecord => {
            alert('coming soon');
        }

        $scope.isAllSelected = false;
        $scope.selectAll = () => {
            if ($scope.isAllSelected) {
                $scope.dt.rows().deselect();
                $scope.isAllSelected = false;
            } else {
                $scope.dt.rows().select();
                $scope.isAllSelected = true;
            }
        }

        $scope.sendNoticeN1 = (e) => {
            e.preventDefault();

            let IDs = [];
            const dat = $scope.dt.rows({selected: true}).data().toArray();

            dat.map((report) => {
                if (report[1] && report[4]) {
                    IDs.push(Number(report[1]))
                }
            })

            console.log('dat', dat);

            if (IDs.length === 0) {
                toastr.error('Please select valid units to update');
                return;
            }


            $http.post('/api/v1/lease', {
                units: IDs
            }).success(r => {
                $rootScope.goToAngular2(`leases/bulk-notices/${r.data.id}`)
            });
        }

        $scope.n1bathes = [];
        $scope.openN1BatchList = () => {
            $('#n1-batch-list').appendTo("body").modal('show');

            $scope.table = $('#n1-batch-list-table').DataTable({
                "serverSide": true,
                "processing": true,
                // buttons: [],
                'columnDefs': [],
                // autoWidth: true,
                // responsive: true,
                "ordering": false,
                pageLength: 50,
                // "bLengthChange": false,
                'columns' : [
                    {'visible' : false },
                    {'visible' : false },
                    null,
                    null,
                    null,
                    null,
                ],
                // "order": [ 1, 'desc' ],
                ajax: {
                    'url': pqDomain + "/api/v1/lease-data-table" + '?cordova='+(!!window.Capacitor.isNativePlatform() ? '1':'0') + '&token=' + pqCachedToken,
                    'type': 'post',
                    error: function(jqXHR, textStatus, errorThrown) {
                        if (jqXHR.status != 0) {
                            alert('There was an error retrieving your data.');
                        }
                    },
                },
            });

        };

        $('#n1-batch-list-table').on('click', 'tbody tr', function() {
            var table = $('.dataTable').DataTable();
            let data = table.row(this).data();

            console.log(data);
            if (data[0] !== $rootScope.authUser.user_id && (data[1] !== 'canceled' && data[1] || 'confirmed')) {
                toastr.warning('You can only browse batches created by yourself');
            } else {
                $('#n1-batch-list').modal('hide');
                $rootScope.goToAngular2(`leases/bulk-notices/${data[2]}`);
            }
            console.log('API row values : ', data[0], $rootScope.authUser.user_id, $rootScope.authUser);
        })
        // end rent-roll

    }

}());
