(function() {

	'use strict';

	angular
		.module('app')
		.config(routes);

	function routes($stateProvider) {

		var url = 'scripts/modules/manager/';

		$stateProvider
			.state('main.managers', {
				url: '/managers',
				onEnter: function($window) {
					$window.location.href = '/#/n/managers/list';
				}
			})
			.state('main.managerCreate', {
				url: '/managers/create',
				views: {
					"body@": {
						templateUrl: url + 'create/manager.create.html',
						controller: 'ManagerCreateController',
                        controllerAs: 'vm'
					}
				}
			})
			.state('main.managerPresetCreate', {
				url: '/company/:companyId/managers/permission-presets/new',
				views: {
					"body@": {
						templateUrl: url + 'editPreset/manager.edit.html',
						controller: 'PresetEditController',
                        controllerAs: 'vm',
						resolve: {
							resolvedData: getNewPresetData
						}
					}
				}
			})
			.state('main.managerPresetEdit', {
				url: '/managers/permission-presets/:id/edit',
				views: {
					"body@": {
						templateUrl: url + 'editPreset/manager.edit.html',
						controller: 'PresetEditController',
                        controllerAs: 'vm',
						resolve: {
							resolvedData: getPresetData
						}
					}
				}
			})
			.state('main.managerEdit', {
				url: '/managers/:managerId/edit',
				views: {
					"body@": {
						templateUrl: url + 'edit/manager.edit.html',
						controller: 'ManagerEditController',
                        controllerAs: 'vm',
                        resolve: {
                            resolvedManagerData: getManagerData
                        }
					}
				}
			});

        /* @ngInject */
        function getManagerData(managerData, $stateParams)
        {
            return managerData.load($stateParams.managerId);
        }

        /* @ngInject */
        function getPresetData(managerData, $stateParams)
        {
            return managerData.load2($stateParams.id);
        }
        function getNewPresetData(managerData, $stateParams)
        {
            return managerData.getNewPreset($stateParams.companyId);
        }

	}

}());
