(function () {
    'use strict';

    angular
        .module('app')
        .controller('ProspectsListController', prospectsListController)
        .config(function ($animateProvider) {
            $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
        });

    function prospectsListController($scope, $http, $state, helperService, propertyService, messageService, $timeout, $rootScope, accountService, langService, $sce)
    {
        $scope.t = langService.t('lease_app');
        $scope.deliberatelyTrustDangerousSnippet = function(text) {
            return $sce.trustAsHtml(text);
        };
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {
            let ls = JSON.parse(localStorage.getItem('filter_tagsprospects-list'));
            if (ls && fromState.name === 'main.prospects.edit' && toState.name === 'main.prospects' && ls.tags && ls.tags.some(el => el === 'unread:message')) {
                $scope.filterUnread(); // force update the list when closing edit popup
            }
        });

        $scope.isMobile = helperService.isMobileOrTablet() || Boolean(window.Capacitor.isNativePlatform());
        $scope.prospects = [];
        $scope.directiveFilterItems = [];

        angular.element($('body')).scope().state = $state;

        $scope.dt = null;

        $scope.showingDateError = (status, date) => {
            const dateObj = moment(date, 'MMM. D, YYYY');
            if (status === 'Booked' && moment().add(1, 'days').isAfter(dateObj)) {
                return true;
            }
            return false;
        }
        var columns =[
            null,
            {title:$scope.t('email')},
            {title:$scope.t('name')},
            {title:$scope.t('Property')},
            {title:$scope.t('Contact')},
            {title:$scope.t('phone')},
            {title:$scope.t('Booking date')},
            {title:$scope.t('Desired Move In')},
            {title:$scope.t('Status')},
            {title:$scope.t('Manager')},
            {title:$scope.t('Unit Type')},
            {title:$scope.t('Unread')},
            null
        ]

        $scope.loadDataTable = () => {
            $scope.dt = $('#dataTable').DataTable({
                responsive: $(window).width() < 1200,
                "processing": true,
                "order": [[0, 'desc']],
                "serverSide": true,
                columns: columns,
                columnDefs: [
                    {
                        targets: 0,
                        defaultContent: '',
                        orderable: false,
                        className: 'select-checkbox'
                    }
                ],
                select: {
                    selector: 'td:first-child',
                    style: 'multi'
                },
                ajax: {
                    'url': pqDomain + "/api/v1/prospect/get-list-data" + '?cordova=' + (!!window.Capacitor.isNativePlatform() ? '1' : '0') + '&token=' + pqCachedToken,
                    'type': 'POST',
                    error: function(jqXHR, textStatus, errorThrown) {
                        if (jqXHR.status != 0) {
                            alert('There was an error retrieving your data.');
                        }
                    },
                },
                buttons: ['copy', 'selectAll','selectNone']
            });

            $scope.dt.column(1).visible(false);

            $('#dataTable tbody').on('click', 'tr', function (e) {
                if ($(e.target).parent().children().index(e.target)) {
                    var prospect_id = $('#prospect-id', this).data('prospect-id');
                    angular.element($('body')).scope().state.go('main.prospects.edit', {id: prospect_id});
                }
            });
        }

        $scope.isAllSelected = false;
        $scope.selectAll = () => {
            if ($scope.isAllSelected) {
                $scope.dt.rows().deselect();
                $scope.isAllSelected = false;
            } else {
                $scope.dt.rows().select();
                $scope.isAllSelected = true;
            }
        }

        $scope.$on('$includeContentLoaded', () => $scope.loadDataTable());

        $scope.filterUnread = () => {
            $scope.directiveFilterItems = ['unread:message'];
            localStorage.setItem('filter_tagsprospects-list', '{"header":"prospects-list","tags":["unread:message"]}')
            $scope.dt.search('unread:message').draw()
        }

        $scope.bulkMessage = {
            emails: '',
            subject: '',
            body: ''
        }
        $scope.bulkSending = false;

        $scope.testCheckboxf = () => {
            let emails = [];
            emails = $scope.dt.rows({selected: true}).data().toArray().reduce((results, item) => {
                let userObj = JSON.parse(item[1]);
                if (userObj.email) {
                    let r = results;
                    r.push(userObj.email)
                    return r;
                }
                return results;
            }, [])

            $scope.bulkMessage.emails = emails

            if (emails.length === 0 || emails === undefined) {
                toastr.error($scope.t('Please select at least one row with valid email'));
            } else {
                $('#bulk-message-modal').appendTo('body').modal('show');
                if (!$scope.dropZoneInited) {
                    $timeout($scope.initDropzone, 300);
                }
            }
        }

        $scope.unreadTotal = '...';

        $scope.timeoutId = setInterval(function () {
            $scope.getUnreadCount();
        }, 30000);

        $scope.$on('$destroy', function () {
            clearTimeout($scope.timeoutId);
        });

        $scope.getUnreadCount = () => {
            // $scope.unreadTotal = '...';
            $http.get('/api/v1/messages/prospect/dialog/unread-count').success(function (response) {
                $scope.unreadTotal = response.data;
            })
        }

        $scope.getUnreadCount();

        $scope.filterInbox = () => {
            if ($scope.managerMobile.filter.inbox) {
                const t = $scope.managerMobile.filter.inbox = false;
                const filteredResults = $scope.allRequests;
                $scope.filteredRequests = filteredResults;
                $scope.managerMobile.filter.inbox = false;
            } else {
                const t = $scope.managerMobile.filter.inbox = false;
                const filteredResults = $scope.allRequests.filter(item => {
                    return item.inbox_sum > 0;
                });
                $scope.filteredRequests = filteredResults;
                $scope.managerMobile.filter.inbox = true;
            }

        }

        $scope.loadTemplates = function () {
            if ($rootScope.authUser.pro_messaging && $rootScope.authUser.permissions != 'tenant') {
                $http.post('/api/v1/message-template/get-message-templates').success(function (data) {
                    if (!data.success) {
                        toastr.error($scope.t('Error retrieving Message Templates'));
                        return;
                    }

                    // lets populate stuff
                    $scope.templates = data.data.templates;
                    $scope.templates_enabled = 1;
                });
            }
        };

        $scope.templateSelected = function (template) {
            if (template == null) {
                $scope.bulkMessage.subject = '';
                $scope.bulkMessage.body = '';
                return;
            }

            $scope.bulkMessage.body = template.content; //replace removes html chars
            $scope.bulkMessage.subject = template.subject;
        };

        $scope.templates_enabled = 0;
        $scope.templates = [];
        $scope.template = {};
        $scope.loadTemplates();

        $scope.sendBulkMessage = () => {
            if ($scope.bulkMessage.subject == '' || $scope.bulkMessage.body == '') {
                toastr.error($scope.t('Please fill message subject and body'));
                return;
            }
            $scope.bulkSending = true;
            let emails = [];
            const dat = $scope.dt.rows({selected: true}).data().toArray();

            let dataAttachments = [];

            angular.forEach($scope.attachments, function (file, key) {
                dataAttachments.push({
                    name: file.name,
                    data: file.dataURL
                });
            });

            emails = dat.reduce((res, item, index) => {
                const userObj = JSON.parse(item[1]);

                if (userObj.email) {
                    let r = res;
                    r.push({
                        ...userObj,
                        reply_to: userObj.reply_to,
                        unit: null,
                        unit_id: 0,
                        unit_name: '',
                        user: {
                            email: userObj.email,
                            id: "pr_" + index
                        },
                        user_id: "pr_" + index
                    });
                    return r;
                }
                return res;
            }, [])

            let data = {
                data: emails,

                subject: $scope.bulkMessage.subject,
                body: $scope.bulkMessage.body,

                send_to: 2,
                recipients: '2',

                attachments: dataAttachments,
                scheduled_date: '',
                is_scheduled: 0,
                categoryId: 0,
            };

            if (data.data.length > 0) {
                messageService.sendAMessage(data)
                    .success(function (data2) {
                        $scope.dialogueSending = false;
                        if (data2.success) {
                            toastr.info($scope.t('Emails sent'));
                            $scope.dt.rows({selected: true}).deselect()
                            $scope.bulkMessage.subject = '';
                            $scope.bulkMessage.body = '';
                            $('#bulk-message-modal').modal('hide');
                        } else {
                            toastr.error($scope.t('There was an error sending your message'));
                        }
                        $scope.bulkSending = false;
                    })
                    .error(function (data) {
                        $scope.bulkSending = false;
                        toastr.error($scope.t('There was an error sending your message'));
                    });
            } else {
                toastr.error($scope.t('Please select prospects with defined emails'));
            }

            $scope.dt.rows({selected: true}).deselect()
        }

        // filter
        $scope.managerMobile = {
            filter: {
                text: '',
                timeline: {
                    today: false,
                    '1dayago': false,
                    '1weekago': false,
                    '2weeksago': false,
                    '1monthago': false,
                    '2monthago': false,
                    '4monthago': false,
                    '6monthago': false
                },
                inbox: false
            },
            loading: {
                tickets: true,
                properties: false
            }
        };

        $scope.requests = [];
        $scope.getManagerRequests = function () {
            $http.get('/api/v1/prospect/get-list-data-for-mobile').success(function (response) {
                $scope.allRequests = response.data
                $scope.filteredRequests = response.data

                $scope.viewbyCan = 5;
                $scope.totalItemsCan = $scope.filteredRequests.length;
                $scope.currentPageCan = 1;
                $scope.itemsPerPageCan = $scope.viewbyCan;
                $scope.maxSizeCan = 5; //Number of pager buttons to show

                $scope.setPageCan = function (pageNo) {
                    $scope.currentPageCan = pageNo;
                };

                $scope.setItemsPerPageCan = function (num) {
                    $scope.itemsPerPageCan = num;
                    $scope.currentPageCan = 1; //reset to first page
                };
                $scope.managerMobile.loading.tickets = false;
            });
        };

        $scope.getManagerRequests();

        angular.element($('body')).scope().state = $state;

        $scope.managerMobileFilterBy = () => {
            const t = $scope.managerMobile.filter.text.toLowerCase();
            const filteredResults = $scope.allRequests.filter(item => {
                const filterByTextResult = ~item.propertyName.toLowerCase().indexOf(t)
                    || (item.unit && ~item.unit.indexOf(t))
                    || ~item.id.toString().indexOf(t)
                    || ~item.prospectName.toLowerCase().indexOf(t)
                    || ~item.managerName.toLowerCase().indexOf(t)
                    || ~item.phone.toString().toLowerCase().indexOf(t);

                const filterByDate = ($scope.managerMobile.filter.timeline.today ? moment(item.date).startOf('day').isSame(moment().startOf('day')) : false)
                    || ($scope.managerMobile.filter.timeline["1dayago"] ? moment(item.date).isAfter(moment().startOf('day').add(-1, 'days')) : false)
                    || ($scope.managerMobile.filter.timeline["1weekago"] ? moment(item.date).isAfter(moment().startOf('day').add(-1, 'weeks')) : false)
                    || ($scope.managerMobile.filter.timeline["2weekago"] ? moment(item.date).isAfter(moment().startOf('day').add(-2, 'weeks')) : false)
                    || ($scope.managerMobile.filter.timeline["1monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-1, 'month')) : false)
                    || ($scope.managerMobile.filter.timeline["2monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-2, 'month')) : false)
                    || ($scope.managerMobile.filter.timeline["4monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-4, 'month')) : false)
                    || ($scope.managerMobile.filter.timeline["6monthago"] ? moment(item.date).isAfter(moment().startOf('day').add(-6, 'month')) : false);
                const filterByProperty = $scope.properties.some(e => {
                    return e.selected && e.name.toLowerCase() === item.propertyName.toLowerCase();
                });
                const filterByUnit = $scope.units.some(e => {
                    return e.selected && e.unit == item.unit;
                });

                return (Object.values($scope.managerMobile.filter.timeline).some(e => e) ? filterByDate : true)
                    && (Object.values($scope.properties).some(e => e.selected) ? filterByProperty : true)
                    && (Object.values($scope.units).some(e => e.selected) ? filterByUnit : true)
                    && (filterByTextResult !== -1 ? filterByTextResult : true);
            });

            $scope.filteredRequests = filteredResults;
        }

        $scope.showFilterModal = (type) => {
            if (type === 'timeline') {
                $('#mobileManagerFilterTimeline').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'unit') {
                $('#mobileManagerFilterUnit').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
            if (type === 'properties') {
                $scope.getProperties();
                $('#mobileManagerFilterProperties').appendTo('body').modal({backdrop: 'static', keyboard: false});
            }
        }

        $scope.properties = [];
        $scope.getProperties = function () {
            if ($scope.properties.length === 0) {
                $scope.managerMobile.loading.properties = true;
                $http.post('/api/v1/get-filter-properties').success(function (data) {
                    $scope.properties = data.data.properties;
                    $scope.managerMobile.loading.properties = false;
                });
            }
        };

        $scope.units = [];

        $scope.getUnits = propertyIds => {
            propertyService.getUnitsForProperties(propertyIds).then(data => {
                $scope.units = data;
            });
        };

        $scope.propertyChanged = () => {
            let propertyIds = [];
            $scope.properties.map(property => {
                if (property.selected) {
                    propertyIds.push(property.id);
                }
            });

            $scope.getUnits(propertyIds);
        }

        $scope.getUnitBtnClass = () => {
            return {"active": Object.values($scope.units).some(e => e.selected)};
        }

        $scope.getTimelineBtnClass = () => {
            return {"active": Object.values($scope.managerMobile.filter.timeline).some(e => e)};
        }

        $scope.getPropertyBtnClass = () => {
            return {"active": Object.values($scope.properties).some(e => e.selected)};
        }

        $scope.getInboxBtnClass = () => {
            return {"active": $scope.managerMobile.filter.inbox};
        }

        $scope.dropZoneInited = false;
        $scope.initDropzone = () => {
            $scope.dropZoneInited = true;

            $scope.myDropzone = new Dropzone('#real-dropzone-bulk', {
                url: '/api/v1/noreturn/',
                autoProcessQueue: false,
                uploadMultiple: true,
                parallelUploads: 100,
                maxFiles: 2,
                maxFilesize: 8,
                addRemoveLinks: false,
                dictRemoveFile: 'Remove',
                dictFileTooBig: 'File is bigger than 8MB',

                // The setting up of the dropzone
                error: function (file, response) {
                    toastr.error(response);
                },
                addedfile: function (file, response) {
                    $timeout(function () {
                        $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                        $scope.processDropzoneFiles();
                    });
                },
                removedfile: function (file, response) {
                    $timeout(function () {
                        $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                        $scope.processDropzoneFiles();
                    });
                },
            });
        }

        $scope.removeFile = function (file) {
            $scope.myDropzone.removeFile(file);
        };

        $scope.processDropzoneFiles = function () {
            angular.forEach($scope.attachments, function (file, key) {
                var fileReader = new FileReader();
                var base64;
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // base64 is the dataURL
                    file.dataURL = base64;
                };
                fileReader.readAsDataURL(file);
            });
        };

        $scope.filterProspectInboxString = '';
        $scope.filterProspectInbox = (e) => {
            $scope.filterProspectInboxString = e;
            const t = e.toLowerCase();

            $scope.prospectInboxlistFiltered = $scope.prospectInboxlist.filter(item => {
                const filterByTextResult = ~item.subject.toLowerCase().indexOf(t);

                if ($scope.isFilterProspectNoReply) {
                    if (item.children.length === 0) {
                        return filterByTextResult;
                    }
                } else {
                    return filterByTextResult;
                }
            });
        }

        $scope.isFilterProspectNoReply = false


        $scope.filterProspectNoReply = (e) => {

            // console.log(  $scope.isFilterProspectNoReply)

            // const filteredResults = $scope.prospectInboxlist.filter(item => {
            //
            //     return item.;
            // });
            // $scope.prospectInboxlistFiltered = filteredResults;
        }

        $scope.loadingMode = false;
        $scope.prospectInboxLading = false;
        $scope.prospectInboxlistOffset = 25;
        $scope.prospectInboxTotal = 0;

        $scope.loadMoreInbox = () => {
            $scope.loadingMode = true;
            $http.get('/api/v1/messages/prospect/inbox?offset=' + $scope.prospectInboxlistOffset).success(function (data) {
                $scope.prospectInboxlist = $scope.prospectInboxlist.concat(data.data.messages);
                $scope.prospectInboxlistOffset += 25;

                $scope.filterProspectInbox($scope.filterProspectInboxString);

                $scope.loadingMode = false;
            });
        }

        $scope.isFilterProspectNoReplySet = (v) => {
            $scope.isFilterProspectNoReply = v;
            $scope.filterProspectInbox($scope.filterProspectInboxString);
        }

        $scope.prospectInboxlist = [];
        $scope.prospectInboxlistFiltered = [];

        $scope.openProspectInboxModal = () => {
            $scope.prospectInboxLading = true;
            if (!($('.modal.in').length)) {
                $('.modal-dialog').css({
                    top: 0,
                    left: 0
                });
            }
            $('#prospect-inbox-modal').appendTo("body").modal('show', {
                show: true
            });

            $scope.prospectInboxlistOffset = 25;
            $http.get('/api/v1/messages/prospect/inbox').success(function (data) {
                $scope.prospectInboxlist = data.data.messages;
                $scope.prospectInboxlistFiltered = data.data.messages;
                $scope.prospectInboxTotal = data.data.messages_total;
                $scope.prospectInboxLading = false;
            });
        }

        $scope.prospectInboxListIndividual = [];
        $scope.prospectInboxIndividual = null;
        $scope.openProspectInboxModalIndividual = (prospect) => {
            $scope.dialogueloading = true;
            $scope.prospectInboxIndividual = prospect;
            $scope.initDropzoneProspect();
            $('#prospect-inbox-individual-modal').appendTo("body").modal('show', {
            });

            $http.get('/api/v1/messages/prospect/dialog/' + prospect.id).success(resp => {
                $scope.prospectInboxListIndividual = resp.data;
                $scope.dialogueloading = false;
            })
        }

        $scope.dzInit = false;
        $scope.initDropzoneProspect = () => {
            if (        $scope.dzInit){
                return;
            }
            $scope.dzInit = true;
            $scope.myDropzone = new Dropzone('#real-dropzone-prospect', {
                url: '/api/v1/noreturn/',
                autoProcessQueue: false,
                uploadMultiple: true,
                parallelUploads: 100,
                maxFiles: 2,
                maxFilesize: 8,
                addRemoveLinks: false,
                dictRemoveFile: 'Remove',
                dictFileTooBig: 'File is bigger than 8MB',

                // The setting up of the dropzone
                error: function (file, response) {
                    toastr.error(response);
                },
                addedfile: function (file, response) {
                    $timeout(function () {
                        $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                        $scope.processDropzoneFiles();
                    });
                },
                removedfile: function (file, response) {
                    $timeout(function () {
                        $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                        $scope.processDropzoneFiles();
                    });
                },
            });
        }

        $scope.removeFile = function (file) {
            $scope.myDropzone.removeFile(file);
        };

        $scope.processDropzoneFiles = function () {
            angular.forEach($scope.attachments, function (file, key) {
                var fileReader = new FileReader();
                var base64;
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // base64 is the dataURL
                    file.dataURL = base64;
                };
                fileReader.readAsDataURL(file);
            });
        };

        $scope.messageData = {
            subject: '',
            body: '',
        }

        accountService.getAccountInformation()
            .success(function (data) {
                $scope.accountInformation = data.data;
            });

        $scope.dialogueloading = false;
        $scope.sendEmailMessage = () => {
            $scope.dialogueloading = true;
            $scope.dialogueSending = true;
            let dataAttachments = [];

            angular.forEach($scope.attachments, function (file, key) {
                dataAttachments.push({
                    name: file.name,
                    data: file.dataURL
                });
            });

            let data = {
                data: [{
                    email: $scope.prospectInboxIndividual.email,
                    first_name: $scope.prospectInboxIndividual.first_name,
                    last_name: $scope.prospectInboxIndividual.last_name,
                    // property_name: $scope.prospectInboxIndividual.property.name,
                    prospect_id: $scope.prospectInboxIndividual.id,
                    reply_to: `prospect-${$rootScope.authUser.user_id}-${$scope.prospectInboxIndividual.id}@{{domain}}`,
                    unit: null,
                    unit_id: 0,
                    unit_name: '',
                    user: {
                        email: $scope.prospectInboxIndividual.email,
                        id: "pr_1"
                    },
                    user_id: "pr_1"
                }],

                subject: $scope.messageData.subject,
                body: $scope.messageData.body + '\n' + ($scope.accountInformation && $scope.accountInformation.manager ? $scope.accountInformation.manager.signature : null),

                send_to: 2,
                recipients: '2',

                attachments: dataAttachments,
                scheduled_date: '',
                is_scheduled: 0,
                categoryId: 0,
            };

            messageService.sendAMessage(data)
                .success(function (data2) {
                    $scope.dialogueSending = false;
                    if (data2.success) {
                        toastr.info($scope.t('Emails sent'));
                        $scope.messageData.subject = '';
                        $scope.messageData.body = '';
                        // $scope.loadDialog();
                    } else {
                        toastr.error($scope.t('There was an error sending your message'));
                    }
                    // $scope.loadDialog();
                    $http.get('/api/v1/messages/prospect/dialog/' + $scope.prospectInboxIndividual.id).success(resp => {
                        $scope.prospectInboxListIndividual = resp.data;
                        $scope.dialogueloading = false;
                    })
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    $scope.dialogueSending = false;
                    toastr.error($scope.t('There was an error sending your message'));
                });
        }

    }
}());
