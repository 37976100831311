(function () {
    'use strict';

    angular
        .module('app')
        .directive('pqPropertyEmails', pqPropertyEmails);

    function pqPropertyEmails() {
        return {
            restrict: 'E',
            controller: 'PqPropertyEmailsController',
            templateUrl: 'scripts/modules/property/property-edit/property-emails/property-emails.html',
            scope: {
                propertyId: '=',
                emails: '=',
                templates: '=',
                // activeTab: '=',
            }
        };
    }

    angular
        .module('app')
        .controller('PqPropertyEmailsController', function ($scope, $timeout, $http) {
            let debounceTimeout = null;
            $scope.previewSrc = null;
            $scope.previewContent = null;
            $scope.templates = $scope.emails.reduce((accumulator, currentValue) => {
                let r = accumulator;
                accumulator[currentValue.slug] = currentValue
                return r;
            }, {});

            $timeout(function () {
                $scope.active_tab = $scope.emails[0].slug;
                $scope.previewEmail($scope.active_tab, $scope.templates[$scope.active_tab]);
            }, 1);

            $scope.previewEmail = function (tab, text) {
                if (debounceTimeout) {
                    $timeout.cancel(debounceTimeout); // Cancel previous timeout if any
                }

                debounceTimeout = $timeout(function () {
                    $scope.previewSrc = `/email-preview/${tab}`;
                    $http.post($scope.previewSrc, {
                        propertyId: $scope.propertyId,
                        selectedPosition: text.selected_position,
                        text: text.template
                    }).then(function (response) {
                        $scope.renderPreview(response.data);
                    });
                }, 500); // Adjust delay (500ms) as needed
            }

            $scope.renderPreview = function (content) {
                let container = document.getElementById("preview-container");

                // Remove existing shadow root if any
                if (container.shadowRoot) {
                    container.shadowRoot.innerHTML = "";
                } else {
                    container.attachShadow({ mode: "open" });
                }

                let shadow = container.shadowRoot;

                // Add styles inside the shadow DOM
                let style = document.createElement("style");
                style.textContent = `
                    :host {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                    body {
                        font-family: Arial, sans-serif;
                        background: white;
                        color: black;
                        padding: 10px;
                    }
                    .preview-content {
                        height: 100%;
                        overflow: auto;
                        border: 1px solid #ccc;
                        padding: 10px;
                        background: white;
                    }
                `;

                // Add content inside the shadow DOM
                let contentDiv = document.createElement("div");
                contentDiv.classList.add("preview-content");
                contentDiv.innerHTML = content; // Ensure the response is safe HTML

                // Append style and content
                shadow.appendChild(style);
                shadow.appendChild(contentDiv);
            }
        });
})();
