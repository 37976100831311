(function () {

    'use strict';

    angular
        .module('app')
        .factory('parcelService', parcelService);

    function parcelService($http) {

        const base_url = '/api/v1/parcel';

        return {
            get: id => $http.get(`${base_url}/${id}`).then(r => r.data),
            create: parcel => $http.post(`${base_url}`, JSON.stringify(parcel)),
            save: parcel => $http.patch(`${base_url}/${parcel.id}`, JSON.stringify(parcel)),
            forTenant: () => $http.get(`${base_url}/tenant`).then(r => r.data),
            getProperties: () => $http.get(`${base_url}/properties`).then(r => r.data.data),
            getBlank: () => {
                return {
                    id: 0,
                    description: '',
                    property_id: null,
                    property_unit_id: null,
                    tenant_id: null,
                    carrier: 'unknown',
                    type: 'unknown'
                }
            }
        };

    }

}());
